import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../../context/context';
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, ArrowRight, ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react';
import { useDispatch, useSelector } from "react-redux";
import {
    getProfile,
} from "../../../store/slices/userSlice";

const MedicalBoardSidebar = () => {
    const profile = useSelector(getProfile);
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleButton, SetToggleButton } = useContext(context);
    const [toggle2, settoggle2] = useState([]);
    const [submenuOpen, setSubmenuOpen] = useState({});
    const [medicalBoardSidebarMenu, setMedicalBoardSidebarMenu] = useState([
        {
            id: 1,
            name: 'Dashboard',
            slug: ['/'],
            icon_color: 'triage-icon.svg',
        },
        {
            id: 2,
            name: 'Board User',
            slug: ['/medical-board-user', '/add-medical-board-user'],
            icon_color: 'triage-icon.svg',
        },
        {
            id: 3,
            name: 'Clinic/Surgery',
            slug: ['/clinic-surgery', '/add-clinic-surgery'],
            icon_color: 'screening-icon.svg',
            submenu: [
                {
                    id: 1,
                    name: 'Doctors',
                    slug: '/doctors',
                },
                {
                    id: 2,
                    name: 'Nurses',
                    slug: '/nurses',
                },
                {
                    id: 3,
                    name: 'Clinic Managers',
                    slug: '/clinic-managers',
                },
            ]
        },
        {
            id: 4,
            name: 'Referral Team',
            slug: ['/referral-teams'],
            icon_color: 'referals-icon.svg',
            submenu: [
                {
                    id: 1,
                    name: 'Referral Team User',
                    slug: '/referral-team-users',
                },
            ]
        },
        {
            id: 5,
            name: 'Call Center',
            slug: ['/call-centers'],
            icon_color: 'triage-icon.svg',
            submenu: [
                {
                    id: 1,
                    name: 'Call Center User',
                    slug: '/call-center-users',
                },
            ]
        },
        {
            id: 6,
            name: 'Patients',
            slug:['/patients','/add-patient','/patient-detail'],
            icon_color:'patient-icon.svg',
        },
        {
            id: 7,
            name: 'Referrals',
            slug:['/referrals','/referral-detail'],
            icon_color:'referals-icon.svg',
        },
        {
            id: 8,
            name: 'Screening',
            slug: ['/screening'],
            icon_color: 'screening-icon.svg',
            submenu: [
                {
                    id: 1,
                    name: 'Plans',
                    slug: '/screening-plans',
                },
                {
                    id: 2,
                    name: 'Slots',
                    slug: '/screening-slots',
                },
                {
                    id: 3,
                    name: 'Pods',
                    slug: '/screening-pods',
                },
                {
                    id: 4,
                    name: 'Locations',
                    slug: '/screening-locations',
                },
                {
                    id: 5,
                    name: 'Bookings',
                    slug: '/bookings',
                },
            ]
        },
    ]);


    
    
    const handleToggle = (slug, id) => {

        if (!toggle2.includes(id)) {
            settoggle2([...toggle2, id]);
        } else {
            const updatedEventIds = toggle2.filter((itemId) => itemId !== id);
            settoggle2(updatedEventIds);
        }

        setSubmenuOpen((prevState) => ({
            ...prevState,
            [slug]: !prevState[slug],
        }));
    };


    const removeItemById = (idToRemove) => {
        if (profile && profile.isManager === 0) {
            console.log('"yessssss"');
            setMedicalBoardSidebarMenu(prevMenu => prevMenu.filter(item => item.id !== idToRemove));
        }
    };

    useEffect(() => {


        removeItemById(2)

    }, [profile]);



    return (
        <>

            <div className={toggleButton ? "sidebar close" : "sidebar"}>
                <div onClick={() => SetToggleButton((prev) => !prev)} className="lh-sidebar-toggle-btn">
                    {toggleButton ? <ChevronRight /> : <ChevronLeft />}
                </div>
                <div class="logo-details">
                    <img onClick={() => navigate("/")} class="lh-sidebar-logo cursor-pointer" width="190" alt="logo" src="../assets/images/logo.svg" />
                    <img onClick={() => navigate("/")} class="lh-sidebar-logo small cursor-pointer" width="42" alt="fav-logo" src="../assets/images/fav-logo.svg" />
                </div>
                <ul class="nav-links">
                    {medicalBoardSidebarMenu?.map((data, i) => (
                        <li
                            key={i}
                            className={`${data.slug.includes(location?.pathname) ? 'active' : ''
                                } ${submenuOpen[data.slug[0]] ? 'showMenu' : ''
                                }`}
                        >
                            <div class="icon-link">
                                <a onClick={() => navigate(`${data.slug[0]}`)}>
                                    <img class="lh-sidebar-icon color" alt="notifications" src={`../assets/images/${data.icon_color}`} />
                                    <span class="link_name">{data.name}</span>
                                </a>
                                {data.submenu && data.submenu.length > 0 && (
                                    <span onClick={() => handleToggle(data.slug[0], i)} className="cursor-pointer">
                                        {toggle2.includes(i) ? <ChevronUp /> : <ChevronDown />}
                                    </span>
                                )}
                            </div>
                            {data.submenu && data.submenu.length > 0 && (<ul class="sub-menu">
                                <li><a class="link_name" onClick={() => navigate(`${data.slug[0]}`)}>{data.name}</a></li>
                                {data.submenu &&
                                    data.submenu.length > 0 &&
                                    data.submenu.map((sub, j) => (
                                        <li key={j}>
                                            <a className={`${location?.pathname === `${sub.slug}` ? "active" : ""}`} onClick={() => navigate(`${sub.slug}`)}>{sub.name}</a>
                                        </li>
                                    ))}
                            </ul>)}
                        </li>
                    ))}
                </ul>
                {/* <div className="lh-carebox-sidebar">
                    <h4 className="font-size-22 black-color fw-bold mb-2">Life Well<br/>Health Care</h4>
                    <p className="font-size-12 black-color mb-2">Publishing Industries for previewing layouts and visual mockups.</p>
                    <p className="font-size-16 black-color fw-bold mb-2">Health Today!</p>
                    <img alt="Life Well" src="../assets/images/medical-heart.svg" />
                </div> */}
            </div>
        </>
    )
}

export default MedicalBoardSidebar