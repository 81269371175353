
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CalendarDays } from 'lucide-react';
import { createScreeningSlot, getAllScreeningPods } from '../../../store/slices/userSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const RecurringDatesContent = ({ selectedType }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();


  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const Durations = [
    {
      name: "15 Minutes",
      value: 15
    },
    {
      name: "30 Minutes",
      value: 30
    },
    {
      name: "45 Minutes",
      value: 45
    },
    {
      name: "60 Minutes",
      value: 60
    },
  ];


  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDays, setselectedDays] = useState([]);
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [load, setload] = useState(false);
  const [pod, setpod] = useState(false);
  const [screeningPods, setscreeningPods] = useState([]);
  const [durations, setdurations] = useState(null);



  const handleCheckboxChange = (index) => {
    const updatedMonths = [...selectedMonths];
    if (updatedMonths.includes(index)) {
      // If the month is already selected, remove it
      updatedMonths.splice(updatedMonths.indexOf(index), 1);
    } else {
      // If the month is not selected, add it
      updatedMonths.push(index);
    }
    setSelectedMonths(updatedMonths);
  };

  const selectedMonthNames = selectedMonths.map(index => months[index]);


  const handleDayChange = (index) => {
    const updatedDays = [...selectedDays];
    if (updatedDays.includes(index)) {
      // If the month is already selected, remove it
      updatedDays.splice(updatedDays.indexOf(index), 1);
    } else {
      // If the month is not selected, add it
      updatedDays.push(index);
    }
    setselectedDays(updatedDays);
  };

  const selectedDaysName = selectedDays.map(index => daysOfWeek[index]);

  const listOfScreenings = async () => {
    try {
      setload(true);
      setscreeningPods(null);


      const response = await dispatch(
        getAllScreeningPods()
      ).unwrap();


      setscreeningPods(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const handlePodChange = async (data) => {
    setpod(data)
  };

  const handleDurationChange = async (data) => {
    setdurations(data)
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      listOfScreenings();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);
  console.log(pod)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let payload = {

        month: selectedMonthNames,
        days: selectedDaysName,
        startTime,
        selectedType,
        endTime,
        screeningPodId: pod?._id,
        screeningPlanId: pod?.screeningPlanId,
        duration: durations.value,
      }

      await dispatch(createScreeningSlot(payload)).unwrap();
 
      navigate("/screening-slots");
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div class="mb-3 lh-input2">
            <label class="form-label">Months</label>
            <div className="d-flex gap-2 flex-wrap">
              {months.map((month, index) => (
                <>
                  <input
                    type="checkbox"
                    name="month"
                    className="btn-check"
                    checked={selectedMonths.includes(index)}
                    onChange={() => handleCheckboxChange(index)}
                    id={`month-${index}`}
                  />
                  <label className="btn lh-button-select" htmlFor={`month-${index}`}>
                    {month}
                  </label>
                </>
              ))}
            </div>
          </div>
          <div class="mb-3 lh-input2">
            <label class="form-label">Days</label>
            <div className="d-flex gap-2 flex-wrap">
              {daysOfWeek.map((day, index) => (
                <>
                  <input
                    type="checkbox"
                    name="day"
                    className="btn-check"
                    checked={selectedDays.includes(index)}
                    onChange={() => handleDayChange(index)}
                    id={`day-${index}`}
                  />
                  <label className="btn lh-button-select" htmlFor={`day-${index}`}>
                    {day}
                  </label>
                </>
              ))}
            </div>
          </div>
          <div class="mb-4 lh-input2">

            <div className="row">
              <div className="col-md-8">
                <div class="lh-input2">
                  <label class="form-label">Availability Hours</label>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <input type="time" class="form-control" id="Availability-Hours" placeholder="A2434SFDF" value={startTime} onChange={(e) => setstartTime(e.target.value)} />
                  <label for="Availability-Hours-to" class="form-label mb-0">To</label>
                  <input type="time" class="form-control" id="Availability-Hours-to" placeholder="A2434SFDF" value={endTime} onChange={(e) => setendTime(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label class="form-label">Pods</label>
                </div>
                <div class="dropdown lh-select-dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {pod ? pod?.name : "Screening Pod"}
                  </a>
                  <ul class="dropdown-menu">
                    {screeningPods?.map((data, i) => (
                      <li key={i}><a class="dropdown-item" onClick={() => handlePodChange(data)}>{data?.name + " " + data.screeninglocations?.name}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label class="form-label">Duration</label>
                </div>
                <div class="dropdown lh-select-dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {durations ? durations.name : "Duration"}
                  </a>
                  <ul class="dropdown-menu">

                    {Durations?.map((data, i) => (
                      <li key={i}><a class="dropdown-item" onClick={() => handleDurationChange(data)}>{data?.name}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <button class="btn lh-btn lh-blue-btn px-4" onClick={handleSubmit}>Add Availability</button>

    </>
  );
};

const IndividualDatesContent = ({ selectedType }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [selectedDates, setSelectedDates] = useState([]);
  const [load, setload] = useState(false);
  const [endTime, setendTime] = useState("");
  const [startTime, setstartTime] = useState("");
  const [durations, setdurations] = useState(null);
  const [screeningPods, setscreeningPods] = useState([]);
  const [pod, setpod] = useState();

  const Durations = [
    {
      name: "15 Minutes",
      value: 15
    },
    {
      name: "30 Minutes",
      value: 30
    },
    {
      name: "45 Minutes",
      value: 45
    },
    {
      name: "60 Minutes",
      value: 60
    },
  ];

 
  const handleDateChange = (date) => {
    const formattedDate = {
      date: formatDate(date),
      day: getDayOfWeek(date.getDay()),
      month: getMonthName(date.getMonth()),
    };

    // Check if the selected date is already in the array
    const isSelected = selectedDates.some((selectedDate) =>
      isSameDay(selectedDate, formattedDate)
    );

    // If selected, remove it; otherwise, add it to the array
    if (isSelected) {
      setSelectedDates((prevDates) =>
        prevDates.filter((selectedDate) => !isSameDay(selectedDate, formattedDate))
      );
    } else {
      setSelectedDates((prevDates) => [...prevDates, formattedDate]);
    }
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.date === date2.date &&
      date1.month === date2.month &&
      date1.year === date2.year
    );
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}-${day}-${year}`;
  };

  const getDayOfWeek = (dayIndex) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[dayIndex];
  };

  const getMonthName = (monthIndex) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex];
  };

  const listOfScreenings = async () => {
    try {
      setload(true);
      setscreeningPods(null);


      const response = await dispatch(
        getAllScreeningPods()
      ).unwrap();


      setscreeningPods(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(selectedDates)


  useEffect(() => {
    let mount = true;
    if (mount) {
      listOfScreenings();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);


  const handlePodChange = async (data) => {
    setpod(data)
  };


  const handleDurationChange = async (data) => {
    setdurations(data)
  };


  const handleSubmit = async (e) => {

    e.preventDefault();


    try {
      let payload = {
        selectedType,
        startTime,
        endTime,
        screeningPodId: pod._id,
        duration: durations.value,
        selectedDates,
        screeningPlanId: pod.screeningPlanId
      }

      await dispatch(createScreeningSlot(payload)).unwrap();
      navigate("/screening-slots");
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div class="lh-input2">
            <label class="form-label">Select Dates</label>
          </div>
          <div className="light-blue-bg p-3 lh-radius-20 mb-3 ">
            <Calendar onChange={handleDateChange} value={selectedDates} />
          </div>

          <div class="mb-4 lh-input2">

            <div className="row">
              <div className="col-md-8">
                <div class="lh-input2">
                  <label class="form-label">Availability Hours</label>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <input type="time" class="form-control" id="Availability-Hours" placeholder="A2434SFDF" value={startTime} onChange={(e) => setstartTime(e.target.value)} />
                  <label for="Availability-Hours-to" class="form-label mb-0">To</label>
                  <input type="time" class="form-control" id="Availability-Hours-to" placeholder="A2434SFDF" value={endTime} onChange={(e) => setendTime(e.target.value)} />
                </div>
              </div>

              <div className="col-md-4">
                <div class="lh-input2">
                  <label class="form-label">Pods</label>
                </div>
                <div class="dropdown lh-select-dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {pod ? pod?.name : "Screening Pod"}
                  </a>
                  <ul class="dropdown-menu">
                    {screeningPods?.map((data, i) => (
                      <li key={i}><a class="dropdown-item" onClick={() => handlePodChange(data)}>{data?.name + " " + data.screeninglocations?.name}</a></li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div class="lh-input2">
                  <label class="form-label">Duration</label>
                </div>
                <div class="dropdown lh-select-dropdown">
                  <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {durations ? durations.name : "Duration"}
                  </a>
                  <ul class="dropdown-menu">

                    {Durations?.map((data, i) => (
                      <li key={i}><a class="dropdown-item" onClick={() => handleDurationChange(data)}>{data?.name}</a></li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div class="mb-3 lh-input2">
            <label class="form-label">Listed Date</label>
            <div className="d-flex flex-column gap-2">
              {selectedDates && selectedDates?.map((dates, index) => (
                <div key={index} className="d-flex align-items-center gap-2 lh-bg-border p-2 blue-color">
                  <CalendarDays strokeWidth={1} width={20} />
                  <p className="font-size-16 mb-0">{moment(dates?.date).format("MMMM Do YYYY")}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <button class="btn lh-btn lh-blue-btn px-4" onClick={handleSubmit}>Add Availability</button>
    </>
  );
};

const AddScreeningSlots = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [selectedType, setselectedType] = useState("");

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabContents = [
    { description: 'Recurring Dates', content: <RecurringDatesContent selectedType={selectedType} /> },
    { description: 'Individual Dates', content: <IndividualDatesContent selectedType={selectedType} /> },
  ];

  useEffect(() => {
    setselectedType(tabContents[activeTab].description)
  }, [activeTab])

  console.log(selectedType)
  return (
    <>
      <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/screening-slots")}>Screening Slots</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Screening Slots</li>
          </ol>
        </nav>
        <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Screening Slots</h2>
        <div className="lh-radius-20 white-bg lh-shadow p-4 mb-3">
          <div className="lh-tabs d-flex flex-wrap gap-2 mb-3">
            {tabContents.map((tab, index) => (
              <div
                key={index}
                className={`lh-tab-btn ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {tab.description}
              </div>
            ))}
          </div>
          <div className="lh-tab-content">
            {tabContents[activeTab].content}
          </div>

        </div>

      </div>
    </>
  )
}

export default AddScreeningSlots