import React, { useEffect, useState } from "react";
import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMedicalBoards,
} from "../../store/slices/userSlice";


const MedicalBoards = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setdata] = useState([]);
    const [filteredData, setfilteredData] = useState();
    const [load, setload] = useState(false);


    
  const AllMedicalBoards = async () => {
    try {
      setload(true);
      setdata(null);
      setfilteredData(null);

      const response = await dispatch(
        getAllMedicalBoards()
      ).unwrap();

      console.log("response.data", response.data);
      setdata(response?.data);
      setfilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      AllMedicalBoards();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);


  return (
    <>
    <div className="lh-patient-list-page px-5 pt-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Medical Boards</li>
        </ol>
    </nav>
    <div className="d-flex align-items-center justify-content-between mb-2">
    <h2 className="font-size-24 blue-color poppins-font fw-bold">Medical Boards</h2>
    <button class="btn lh-btn lh-blue-btn px-3" onClick={()=>navigate("/add-medical-board")}>Add Medical Board</button>
    </div>
    <div className="lh-radius-20 white-bg lh-shadow p-3">
    <p className="font-size-20 blue-color">Medical Boards</p>
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">No.</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">City/Country</th>
      <th scope="col">Board Jurisdiction</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {filteredData?.map((data,i)=> (
    <tr>
      <td>{i+1}</td>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{data.phone}</td>
      <td>{data.address.city}, {data.address.country}</td>
      <td>{data.boardJurisdiction}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div onClick={()=>navigate("/add-medical-board",{state: {data: data, type: "add"}})} className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div onClick={()=>navigate("/add-medical-board",{state: {data: data, type: "edit"}})} className={`lh-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {/* <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>
    </div>
    </div>
    </>
  )
}

export default MedicalBoards