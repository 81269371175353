import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  DayView,
  ViewSwitcher,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';


const theme = createTheme({
    palette: {
      primary: {
        main: '#033f5a', 
      },
    },
  });

const AppointmentContent = ({ data, formatDate, ...restProps }) => {
    return (
      <Appointments.AppointmentContent {...restProps} data={data}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#033f5a', 
        color: '#fff',
        borderRadius: '4px',
        boxSizing: 'border-box', 
        overflow: 'hidden', 
      }}>
        <div> 
          <div style={{fontWeight:'bold',fontSize: '12px',marginBottom: "-4px"}}>{data.title}</div>
          <div style={{fontSize: '9px'}}>{data.location.substring(0, 25)}{data.location.length > 25 ? "..." : ""}</div>
          <div style={{fontSize: '9px'}}>
            {formatDate(data.startDate, { hour: 'numeric', minute: 'numeric' })} -{' '}
            {formatDate(data.endDate, { hour: 'numeric', minute: 'numeric' })}
          </div>
        </div>
      </Appointments.AppointmentContent>
    );
  };
  

const BookingCalendarView = ({ data }) => {
  const [arr, setArr] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentViewName, setCurrentViewName] = useState('Week');

  const handleData = (data) => {
    const newArr = [];
    for (let i = 0; i < data.length; i++) {
      const newObj = {
        title: `${data[i]?.patientId?.firstName} ${data[i]?.patientId?.lastName}`,
        startDate: moment(data[i]?.slotId?.date + ' ' + data[i]?.slotId?.startTime, 'MM-DD-YYYY HH:mm').format(),
        endDate: moment(data[i]?.slotId?.date + ' ' + data[i]?.slotId?.endTime, 'MM-DD-YYYY HH:mm').format(),
        id: data[i]?._id,
        location: data[i]?.screeingLocation?.location?.address,
      };
      newArr.push(newObj);
    }
    setArr(newArr);
  };

  useEffect(() => {
    handleData(data);
  }, [data]);

  const handleCurrentDateChange = (date) => {
    setCurrentDate(date);
  };

  const handleCurrentViewNameChange = (viewName) => {
    setCurrentViewName(viewName);
  };

 
console.log('arr', arr)
  return (
    <ThemeProvider theme={theme}>
    <Paper>
      <Scheduler data={arr} height={660}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={handleCurrentDateChange}
          currentViewName={currentViewName}
          onCurrentViewNameChange={handleCurrentViewNameChange}
        />
        <DayView startDayHour={12} endDayHour={23} />
        <WeekView startDayHour={12} endDayHour={23} />
        <MonthView startDayHour={12} endDayHour={23} />
        <Appointments appointmentContentComponent={AppointmentContent}/>
        <Toolbar />
        <DateNavigator />
        <ViewSwitcher />
      </Scheduler>
    </Paper>
    </ThemeProvider>
  );
};

export default BookingCalendarView;
