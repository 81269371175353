import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { createScreeingPod, editScreeingPod, getAllScreeingPlans, getAllScreeningLocations } from '../../../store/slices/userSlice';
import { useDispatch } from 'react-redux';

const AddScreeningPod = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [detail, setdetail] = useState(location?.state);
    const [name, setname] = useState(detail ? detail.name : "");
    const [macAddress, setmacAddress] = useState(detail ? detail.macAddress : "");
    const [manufacturer, setmanufacturer] = useState(detail ? detail.manufacturer : "");
    const [modelNo, setmodelNo] = useState(detail ? detail.modelNo : "");
    const [screeingLocationId, setscreeingLocationId] = useState(detail ? detail.screeninglocations._id : "");
    const [screeninLocaionName, setScreeninLocaionName] = useState(detail ? detail.screeninglocations.name : "");

    const [locations, setlocations] = useState();
    const [screeningPlans, setscreeningPlans] = useState();
    const [screeningPlanId, setscreeningPlanId] = useState();
    const [screeningPlanName, setscreeningPlanName] = useState();

    const listOfScreenings = async () => {
        try {

            const response = await dispatch(getAllScreeningLocations()).unwrap();
            setlocations(response?.data);

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const listOfScreeningPlans = async () => {
        try {

            const response = await dispatch(getAllScreeingPlans()).unwrap();
            setscreeningPlans(response?.data);

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };




    useEffect(() => {
        let mount = true;
        if (mount) {
            listOfScreenings();
            listOfScreeningPlans()
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let payload = {
                name,
                screeingLocationId,
                screeningPlanId,
                macAddress,
                modelNo,
                manufacturer,

            }

            let editPayload = {
                name,
                screeingLocationId,
                screeningPlanId,
                macAddress,
                modelNo,
                manufacturer,
                id: detail?._id
            }

            if (detail) {
                await dispatch(editScreeingPod(editPayload)).unwrap();
            } else {
                await dispatch(createScreeingPod(payload)).unwrap();
            }
            setname("")
            setmacAddress("")
            setmanufacturer("")
            setmodelNo("")
            setscreeingLocationId("")

            navigate("/screening-pods");
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/screening-pods")}>Screening Pods</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Screening Pod</li>
                    </ol>
                </nav>
                <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Screening Pod</h2>
                <div className="lh-radius-20 white-bg lh-shadow p-4 mb-4">
                    <div className="row gy-3">
                        <div className="col-md-4 lh-input2">
                            <label for="Name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="Name" placeholder="John" defaultValue={name} onChange={(e) => setname(e.target.value)} />
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label class="form-label">Screening Plan</label>
                            </div>
                            <div class="dropdown lh-select-dropdown">
                                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {screeningPlanName ? screeningPlanName : "Plan"}
                                </a>
                                <ul class="dropdown-menu">
                                    {screeningPlans?.map((data, i) => (
                                        <li key={i}><a class="dropdown-item" onClick={() => { setscreeningPlanId(data._id); setscreeningPlanName(data?.name) }}>{data?.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label class="form-label">Locations</label>
                            </div>
                            <div class="dropdown lh-select-dropdown">
                                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {screeninLocaionName ? screeninLocaionName : "Location"}
                                </a>
                                <ul class="dropdown-menu">
                                    {locations?.map((data, i) => (
                                        <li key={i}><a class="dropdown-item" onClick={() => { setscreeingLocationId(data._id); setScreeninLocaionName(data?.name) }}>{data?.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 lh-input2">
                            <label for="Machine-MAC-Address" class="form-label">Machine MAC Address</label>
                            <input type="text" class="form-control" id="Machine-MAC-Address" placeholder="00:1A:C2:7B:00:47." defaultValue={macAddress} onChange={(e) => setmacAddress(e.target.value)} />
                        </div>
                        <div className="col-md-4 lh-input2">
                            <label for="Manufacturer" class="form-label">Manufacturer</label>
                            <input type="text" class="form-control" id="Manufacturer" placeholder="Samsung" defaultValue={manufacturer} onChange={(e) => setmanufacturer(e.target.value)} />
                        </div>
                        <div className="col-md-4 lh-input2">
                            <label for="Model-No" class="form-label">Model No.</label>
                            <input type="text" class="form-control" id="Model-No" placeholder="KDL-32BX330" defaultValue={modelNo} onChange={(e) => setmodelNo(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button class="btn lh-btn lh-blue-btn px-4" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </>
    )
}

export default AddScreeningPod