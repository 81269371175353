import React, { useEffect, useState } from "react";
import { Camera, FileUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addUser,
    editUser,
    getMedicalBoardClinics
} from "../../../store/slices/userSlice";

const AddDoctorUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();


    // Medical Board User (Manager) Form State
    const [clinics, setclinics] = useState([]);
    const [load, setload] = useState(false);
    const [id, setid] = useState(location.state ? location.state.data.clinicId._id : "");
    const [userid, setuserid] = useState(location.state ? location.state.data._id : "");
    const [selectedClinicName, setselectedClinicName] = useState(location.state ? location.state.data.clinicId.name : "");
    const [type, settype] = useState(location.state ? location.state.type : "add");
    const [abc, setabc] = useState(false);
    const [imageName, setimageName] = useState(location.state ? location.state.data.imageName : "");
    const [firstName, setFirstName] = useState(location.state ? location.state.data.firstName : "");
    const [lastName, setLastName] = useState(location.state ? location.state.data.lastName : "");
    const [emailUser, setEmailUser] = useState(location.state ? location.state.data.email : "");
    const [dateOfBirth, setDateOfBirth] = useState(location.state ? location.state.data.dateOfBirth : "");
    const [nationalIdNumber, setNationalIdNumber] = useState(location.state ? location.state.data.nationalIDNumber : "");
    const [phoneUser, setPhoneUser] = useState(location.state ? location.state.data.phone : "");
    const [gender, setGender] = useState(location.state ? location.state.data.gender : "");

    // Address State
    const [addressUser, setAddressUser] = useState(location?.state?.data?.address ? location.state.data.address.line1 : "");
    const [addressUser2, setAddressUser2] = useState(location?.state?.data?.address ? location.state.data.address.line2 : "");
    const [cityUser, setCityUser] = useState(location?.state?.data?.address ? location.state.data.address.city : "");
    const [countryUser, setCountryUser] = useState(location?.state?.data?.address ? location.state.data.address.country : "");
    const [postcodeUser, setPostcodeUser] = useState(location?.state?.data?.address ? location.state.data.address.postalCode : "");

    // Emergency Contact Information State
    const [emergencyContactName, setEmergencyContactName] = useState(location?.state?.data?.emergency ? location.state.data.emergency.name : "");
    const [emergencyContactRelation, setEmergencyContactRelation] = useState(location?.state?.data?.emergency ? location.state.data.emergency.relation : "");
    const [emergencyContactPhone, setEmergencyContactPhone] = useState(location?.state?.data?.emergency ? location.state.data.emergency.phone : "");

    // Additional Information State
    const [specialisation, setspecialisation] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.specialisation : "");
    const [qualificationName, setqualificationName] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.qualification.name : "");
    const [qualificationInstitute, setqualificationInstitute] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.qualification.institute : "");
    const [qualificationyearEnd, setqualificationyearEnd] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.qualification.yearEnd : "");
    const [licenseNumber, setlicenseNumber] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.licenseNumber : "");
    const [practicingStartDate, setpracticingStartDate] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.practicingStartDate : "");
    const [employmentStatus, setemploymentStatus] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.employmentStatus : "");
    const [profileDescription, setprofileDescription] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.profileDescription : "");
    const [digitalSignature, setdigitalSignature] = useState(location?.state?.data?.roleSpecificData ? location.state.data.roleSpecificData.digitalSignature : "");

    console.log('id', id)


    const medicalNursingSpecialties = [
        "Allergy and Immunology",
        "Anesthesiology",
        "Cardiology/Cardiac Nursing",
        "Dermatology",
        "Emergency Medicine/Emergency Nursing",
        "Endocrinology",
        "Family Medicine/Family Practice Nursing",
        "Gastroenterology",
        "Geriatrics/Geriatric Nursing",
        "Hematology",
        "Infectious Disease Nursing",
        "Internal Medicine",
        "Medical Genetics",
        "Nephrology/Nephrology Nursing",
        "Neurology/Neuroscience Nursing",
        "Obstetrics and Gynecology",
        "Oncology/Oncology Nursing",
        "Ophthalmology",
        "Orthopedic Surgery/Orthopedic Nursing",
        "Otolaryngology (ENT)",
        "Pathology",
        "Pediatrics/Pediatric Nursing",
        "Physical Medicine and Rehabilitation",
        "Plastic Surgery",
        "Psychiatry/Psychiatric Nursing",
        "Pulmonology",
        "Radiology/Radiology Nursing",
        "Rheumatology",
        "Sports Medicine",
        "Surgery (General)",
        "Thoracic Surgery",
        "Urology/Urology Nursing",
        "Vascular Surgery",
        "Nurse Anesthetist",
        "Nurse Midwife",
        "Nurse Practitioner",
        "Clinical Nurse Specialist",
        "Neonatal Nursing",
        "Labor and Delivery Nursing",
        "Hospice/Palliative Care Nursing",
        "Holistic Nursing",
        "Home Health Nursing",
        "Forensic Nursing",
        "Emergency Nursing",
        "Critical Care Nursing",
        "Cardiac Nursing",
        "Aesthetic/Cosmetic Nursing",
        "Occupational Health Nursing",
        "Public Health Nursing",
        "Rehabilitation Nursing",
        "School Nursing",
        "Substance Abuse Nursing",
        "Travel Nursing",
        "Women's Health Nursing",
        "Legal Nurse Consultant",
    ];


    const handleSubmit = async (e) => {
        try {
        e.preventDefault()
        const formData = new FormData();


      

        // Medical Board User (Manager) Form State

        formData.append("id", id);
        formData.append("userid", userid);
        formData.append("type", "clinic");
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", emailUser);
        formData.append("dateOfBirth", dateOfBirth);
        formData.append("nationalIDNumber", nationalIdNumber);
        formData.append("phone", phoneUser);
        formData.append("gender", gender);
        formData.append("imageName", imageName);
        formData.append("role", "doctor");


        formData.append("addressLine1", addressUser);
        formData.append("addressLine2", addressUser2);
        formData.append("city", cityUser);
        formData.append("country", countryUser);
        formData.append("postalCode", postcodeUser);



        formData.append("emergency", JSON.stringify({name:emergencyContactName,relation:emergencyContactRelation,phone:emergencyContactPhone}));
        // formData.append("emergencyName", emergencyContactName);
        // formData.append("emergencyRelation", emergencyContactRelation);
        // formData.append("emergencyPhone", emergencyContactPhone);


        formData.append("specialisation", JSON.stringify(specialisation));
        formData.append("qualification", JSON.stringify({name:qualificationName,institute:qualificationInstitute,yearEnd:qualificationyearEnd}));
        // formData.append("qualificationName", qualificationName);
        // formData.append("qualificationInstitute", qualificationInstitute);
        // formData.append("qualificationyearEnd", qualificationyearEnd);
        formData.append("licenseNumber", licenseNumber);
        formData.append("practicingStartDate", practicingStartDate);
        formData.append("employmentStatus", employmentStatus);
        formData.append("profileDescription", profileDescription);
        formData.append("image", digitalSignature);





        if (type == "add") {
            await dispatch(
                addUser(formData)
            ).unwrap();
        }

        else {
            await dispatch(
                editUser(formData)
            ).unwrap();
        }

        navigate("/doctors");
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
    }
    };

    const handleSpecialities = (id) => {

        // if (!specialisation.includes(id)) {
        //     setspecialisation([...specialisation, id]);
        //   } else {
        //     const updatedEventIds = specialisation.filter((itemId) => itemId !== id);
        //     setspecialisation(updatedEventIds);
        //   }

        setspecialisation([id]);

    };

    const AllMedicalBoardClinics = async () => {
        try {
            setload(true);
            setclinics(null);


            const response = await dispatch(
                getMedicalBoardClinics()
            ).unwrap();

            console.log("response.data", response.data);
            setclinics(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            AllMedicalBoardClinics();
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);



    console.log('employmentStatus', employmentStatus)


    return (
        <>
            <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/doctors")}>Doctors</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Doctor</li>
                    </ol>
                </nav>
                <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Doctor</h2>
                <div className="lh-radius-20 white-bg lh-shadow p-4 mb-3">
                    <div className="row mb-3">
                        <div className="col-md-2">
                            <div class="lh-input2">
                                <label for="imageUpload" class="form-label">Profile Photo</label>
                            </div>
                            <div className="lh-profile-upload">
                                <div class="avatar-upload">
                                    <div class="avatar-edit">
                                        <input onChange={(e) => { setabc(true); setimageName(e.target.files[0]) }} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                                        <label for="imageUpload"><Camera width={18} strokeWidth={1} /> Upload</label>
                                    </div>
                                    <div class="avatar-preview">
                                        {imageName && <img id="output" src={!abc ? imageName : URL.createObjectURL(imageName)} alt="" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            {/* <div class="mb-3 lh-input2">
            <label for="uid" class="form-label">UID</label>
            <input disabled value={location?.state?.data?.uid} type="text" class="form-control" id="uid" placeholder="A2434SFDF" />
            </div> */}
                            <div class="mb-3 lh-input2">
                                <label for="First-Name" class="form-label">First Name</label>
                                <input defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" class="form-control" id="First-Name" placeholder="John" />
                            </div>
                            <div class="mb-3 lh-input2">
                                <label for="Email" class="form-label">Email</label>
                                <input defaultValue={emailUser} onChange={(e) => setEmailUser(e.target.value)} type="email" class="form-control" id="Email" placeholder="john@lifewell.health" />
                            </div>
                            <div class="mb-3 lh-input2">
                                <label for="Date-of-Birth" class="form-label">Date of Birth</label>
                                <input defaultValue={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} type="date" class="form-control" id="Date-of-Birth" placeholder="1/12/2024" />
                            </div>
                            <div class="mb-3 lh-input2">
                                <label class="form-label">Gender</label>
                                <div className="d-flex gap-2">
                                    <input checked={gender == "male"} onChange={() => setGender("male")} type="radio" name="gender" class="btn-check" id="male" />
                                    <label class="btn lh-button-select" for="male">Male</label>

                                    <input checked={gender == "female"} onChange={() => setGender("female")} type="radio" name="gender" class="btn-check" id="female" />
                                    <label class="btn lh-button-select" for="female">Female</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div class="mb-3 lh-input2">
                                <label for="Last-Name" class="form-label">Last Name</label>
                                <input defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} type="text" class="form-control" id="Last-Name" placeholder="Marshell" />
                            </div>
                            <div class="mb-3 lh-input2">
                                <label for="National-ID-Number" class="form-label">National ID Number</label>
                                <input defaultValue={nationalIdNumber} onChange={(e) => setNationalIdNumber(e.target.value)} type="text" class="form-control" id="National-ID-Number" placeholder="A2434SFDF" />
                            </div>
                            <div class="mb-3 lh-input2">
                                <label for="Phone" class="form-label">Phone</label>
                                <input defaultValue={phoneUser} onChange={(e) => setPhoneUser(e.target.value)} type="tel" class="form-control" id="Phone" placeholder="+967 354 3644" />
                            </div>
                            <div className="col-md-4">
                                <div class="lh-input2">
                                    <label class="form-label">Clinics</label>
                                </div>
                                <div class="dropdown lh-select-dropdown">
                                    <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedClinicName ? selectedClinicName : "Clinic"}
                                    </a>
                                    <ul class="dropdown-menu">
                                        {clinics?.map((data, i) => (
                                            <>
                                                <li onClick={() => { setid(data._id); setselectedClinicName(data.name) }} key={i}><a class="dropdown-item">{data.name}</a></li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>

                    <p className="font-size-20 blue-color poppins-font fw-semibold">Address</p>
                    <div className="row gy-3 mb-4">
                        <div className="col-md-12">
                            <div class="lh-input2">
                                <label for="Address" class="form-label">Address 1</label>
                                <input defaultValue={addressUser} onChange={(e) => setAddressUser(e.target.value)} type="text" class="form-control" id="Address" placeholder="Pod 1, Greenwood Clinic" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div class="lh-input2">
                                <label for="Address" class="form-label">Address 2</label>
                                <input defaultValue={addressUser2} onChange={(e) => setAddressUser2(e.target.value)} type="text" class="form-control" id="Address" placeholder="Pod 1, Greenwood Clinic" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="City" class="form-label">City</label>
                                <input defaultValue={cityUser} onChange={(e) => setCityUser(e.target.value)} type="text" class="form-control" id="City" placeholder="Riyadh" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Country" class="form-label">Country</label>
                                <input defaultValue={countryUser} onChange={(e) => setCountryUser(e.target.value)} type="text" class="form-control" id="Country" placeholder="Saudi Arabia" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Postcode" class="form-label">Postcode</label>
                                <input defaultValue={postcodeUser} onChange={(e) => setPostcodeUser(e.target.value)} type="text" class="form-control" id="Postcode" placeholder="KWF101" />
                            </div>
                        </div>
                    </div>

                    <p className="font-size-20 blue-color poppins-font fw-semibold">Emergency Contact Information</p>
                    <div className="row gy-3">
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Name" class="form-label">Name</label>
                                <input defaultValue={emergencyContactName} onChange={(e) => setEmergencyContactName(e.target.value)} type="text" class="form-control" id="Name" placeholder="Mr. Cook" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Relation" class="form-label">Relation</label>
                                <input defaultValue={emergencyContactRelation} onChange={(e) => setEmergencyContactRelation(e.target.value)} type="text" class="form-control" id="Relation" placeholder="Father" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Phone" class="form-label">Phone</label>
                                <input defaultValue={emergencyContactPhone} onChange={(e) => setEmergencyContactPhone(e.target.value)} type="tel" class="form-control" id="Phone" placeholder="+967 354 3644" />
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="font-size-24 blue-color poppins-font fw-bold">Additional Info</h2>
                <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">

                    <p className="font-size-20 blue-color poppins-font fw-semibold">Qualifications</p>
                    <div className="row gy-3 mb-4">
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Qualification" class="form-label">Qualification</label>
                                <input onChange={(e) => setqualificationName(e.target.value)} defaultValue={qualificationName} type="text" class="form-control" id="Qualification" placeholder="Masters" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Institute" class="form-label">Institute</label>
                                <input onChange={(e) => setqualificationInstitute(e.target.value)} defaultValue={qualificationInstitute} type="text" class="form-control" id="Institute" placeholder="Riyadh University" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Year-End" class="form-label">Year End</label>
                                <input type="year" onChange={(e) => setqualificationyearEnd(e.target.value)} defaultValue={qualificationyearEnd} class="form-control" id="Year-End" placeholder="2024" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="License-Number" class="form-label">License Number</label>
                                <input onChange={(e) => setlicenseNumber(e.target.value)} defaultValue={licenseNumber} type="text" class="form-control" id="License-Number" placeholder="35SSDDF55" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Practicing-Start-Date" class="form-label">Practicing Start Date</label>
                                <input onChange={(e) => setpracticingStartDate(e.target.value)} defaultValue={practicingStartDate} type="date" class="form-control" id="Practicing-Start-Date" placeholder="Father" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label class="form-label">Specialities</label>
                            </div>
                            <div class="dropdown lh-select-dropdown">
                                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {specialisation?.length > 0 ? specialisation?.map((s, index) => (index === 0 ? s : `, ${s}`)) : "Specialities"}
                                </a>

                                <ul class="dropdown-menu">
                                    {medicalNursingSpecialties?.map((data, i) => (
                                        <li key={i}><a class="dropdown-item">
                                            <div class="form-check">
                                                <input checked={specialisation?.includes(data)} onChange={() => handleSpecialities(data)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {data}
                                                </label>
                                            </div></a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12 lh-input2">
                            <label class="form-label">Employment Status</label>
                            <div className="d-flex gap-2">

                                <input checked={employmentStatus == "fullTime" ? true : false} onChange={() => setemploymentStatus("fullTime")} type="radio" name="employment-status" class="btn-check" id={`select-${"fullTime"}`} />
                                <label class="btn lh-button-select" for={`select-${"fullTime"}`}>Full Time</label>

                                <input checked={employmentStatus == "partTime" ? true : false} onChange={() => setemploymentStatus("partTime")} type="radio" name="employment-status" class="btn-check" id={`select-${"partTime"}`} />
                                <label class="btn lh-button-select" for={`select-${"partTime"}`}>Part Time</label>

                                <input checked={employmentStatus == "consultant" ? true : false} onChange={() => setemploymentStatus("consultant")} type="radio" name="employment-status" class="btn-check" id={`select-${"consultant"}`} />
                                <label class="btn lh-button-select" for={`select-${"consultant"}`}>Consultant</label>


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Profile-Description" class="form-label">Profile Description</label>
                                <textarea onChange={(e) => setprofileDescription(e.target.value)} defaultValue={profileDescription} class="form-control" id="Profile-Description" rows="6"></textarea>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Digital-Signature" class="form-label">Digital Signature</label>
                            </div>
                            <label for="signature" className="lh-profile-upload py-5 d-flex flex-column align-items-center justify-content-between grey-color">
                                <FileUp />
                                <p className="font-size-14 grey-color mb-0"><b>Upload Your Signature</b><br />Or Just Drag it and Drop it</p>
                                <input onChange={(e) => { setdigitalSignature(e.target.files[0]) }} type="file" class="form-control d-none" id="signature" />
                            </label>
                        </div>

                    </div>

                    <div className="text-center">
                        <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">Save</button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddDoctorUser