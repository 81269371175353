import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Pencil, Plus, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation, useNavigate } from 'react-router-dom';
import { createScreeningBlock, editScreeningBlock, getScreeningBlockTemplete, getScreeningPlanDetail } from '../../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';





const ResponseItem = ({ response, index, moveResponse, deleteResponse, editResponse }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    type: 'RESPONSE',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'RESPONSE',
    hover: (item) => {
      if (item.index !== index) {
        moveResponse(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} className={`lh-screening-block ${isHovered ? 'lh-block-hover' : ''}`} style={{ opacity: isDragging ? 1 : 1 }} onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>

      {response?.type === 'Medical Test' && (
        <div id='Medical-Test'>
          <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data?.heading}</h4>
          <p className="font-size-16 blue-color">{response.data?.questionText}</p>
          {response?.data?.answerOptions?.map((answer, i) => (<>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
              <label class="form-check-label" for="flexRadioDefault1">
                {answer?.text}
              </label>
            </div>
          </>))}
        </div>
      )}
      {response?.type === 'Messages' && (
        <div id='Messages'>
          <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data?.heading}</h4>
          <p className="font-size-16 blue-color mb-0">{response.data?.messageText}</p>
        </div>
      )}
      {response?.type === 'Question' && (
        <div id='Question'>
          {response?.data?.type === 'Single Select' && (
            <div id='Single-Select'>
              <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data?.details?.heading}</h4>
              <p className="font-size-16 blue-color">{response.data?.details?.questionText}</p>
              {response.data?.details?.answerOptions?.map((answer, i) => (<>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {answer?.text}
                  </label>
                </div>
              </>))}
            </div>
          )}
          {response?.data?.type === 'Multi Select' && (
            <div id='Multi-Select'>
              <h4 className="font-size-30 blue-color poppins-font fw-medium">{response?.data?.details?.heading}</h4>
              <p className="font-size-16 blue-color">{response?.data?.details?.questionText}</p>
              {response?.data?.details?.answerOptions?.map((answer, i) => (<>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {answer.text}
                  </label>
                </div>
              </>))}
            </div>
          )}
          {response.data.type === 'Numeric Input' && (
            <div id='Numeric-Input'>
              <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data.details.heading}</h4>
              <p className="font-size-16 blue-color">{response.data.details.questionText}</p>
              {response.data.details.answerOptions?.map((answer, i) => (<>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {answer.text}
                  </label>
                </div>
              </>))}
            </div>
          )}
          {response.data.type === 'Alphanumeric Input' && (
            <div id='Alphanumeric-Input'>
              <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data.details.heading}</h4>
              <p className="font-size-16 blue-color">{response.data.details.questionText}</p>
            </div>
          )}
          {response.data.type === 'Date Input' && (
            <div id='Date-Input'>
              <h4 className="font-size-30 blue-color poppins-font fw-medium">{response.data.details.heading}</h4>
              <p className="font-size-16 blue-color">{response.data.details.questionText}</p>
            </div>
          )}
        </div>
      )}

      <div className="lh-screening-block-actions">
        <span className="cursor-pointer" onClick={() => editResponse(index)}><Pencil size={18} /></span>
        <span className="cursor-pointer" onClick={() => deleteResponse(index)}><Trash size={18} /></span>
      </div>
    </div>
  );
};

const QuestionTypes = ['Single Select', 'Multi Select', 'Numeric Input', 'Alphanumeric Input', 'Date Input'];



const AddScreeningPlan = () => {


  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()



  const [activeTab, setActiveTab] = useState('');
  const [activeTab2, setActiveTab2] = useState("");
  const [createMedicalTest, setcreateMedicalTest] = useState(false);
  const [createQuestions, setcreateQuestions] = useState(false);
  const [createMessage, setcreateMessage] = useState(false);
  const [responses, setResponses] = useState([]);
  const [sidebartoggle, setsidebartoggle] = useState(false);
  const [toggle, settoggle] = useState(true);
  const [toggle1, settoggle1] = useState(true);
  const [medicaltestToggle, setmedicaltestToggle] = useState(true);
  const [questionToggle, setquestionToggle] = useState(true);
  const [messagesToggle, setmessagesToggle] = useState(true);
  const [medicalTestList, setmedicalTestList] = useState([]);
  const [questionList, setquestionList] = useState([]);
  const [messageList, setmessageList] = useState([]);
  const [load, setload] = useState(false);
  const [templates, settemplates] = useState([]);



  const [addBlockIndex, setAddBlockIndex] = useState(null);
  const [editBlockIndex, setEditBlockIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const MedicalTestt = [
    "Blood Pressure Test",
    "Glucose Test",
    "Cholesterol Test",
    "Oxygen Level Test"
  ]

  const Questions = [
    "Smoking Question",
    "Alcohol Consumption",
    "Exercise Question",
  ]

  const [name, setName] = useState("")
  const [istemplate, setistemplate] = useState(0)


  const [medicalTest, setMedicalTest] = useState({
    heading: '',
    questionText: '',
    answerOptions: [
      {
        id: uuidv4(),
        text: '',
        unit: '',
        medicalCode: '',
        criticalMinValue: '',
        abnormalMinValue: '',
        abnormalMaxValue: '',
        criticalMaxValue: '',
      },
    ],
  });

  const [questionData, setquestionData] = useState({
    'Single Select': {
      heading: '',
      questionText: '',
      answerOptions: [{id: uuidv4(), text: '', numericValue: '', criticalMinValue: 0, abnormalMinValue: 0, abnormalMaxValue: 0, criticalMaxValue: 0 }],
    },
    'Multi Select': {
      heading: '',
      questionText: '',
      answerOptions: [{id: uuidv4(), text: '', numericValue: '', criticalMinValue: 0, abnormalMinValue: 0, abnormalMaxValue: 0, criticalMaxValue: 0 }],
    },
    'Numeric Input': {
      heading: '',
      questionText: '',
      answerOptions: [{id: uuidv4(), text: '', unit: '', medicalCode: '', criticalMinValue: 0, abnormalMinValue: 0, abnormalMaxValue: 0, criticalMaxValue: 0 }],
    },
    'Alphanumeric Input': {
      heading: '',
      questionText: '',
      medicalCode: '',
      id: uuidv4(),
    },
    'Date Input': {
      heading: '',
      questionText: '',
      medicalCode: '',
      id: uuidv4(),
    },
  });

  const [messagesData, setMessagesData] = useState({
    heading: '',
    messageText: '',
  });

  const handleInputChange = (field, value, index) => {
    if (field === 'answerOption') {
      const updatedAnswerOptions = [...medicalTest.answerOptions];
      updatedAnswerOptions[index] = { ...updatedAnswerOptions[index], ...value };
      setMedicalTest({ ...medicalTest, answerOptions: updatedAnswerOptions });
    } else {
      setMedicalTest({ ...medicalTest, [field]: value });
    }
  };

  const handleAddOption = () => {
    setMedicalTest({
      ...medicalTest,
      answerOptions: [...medicalTest.answerOptions, {id: uuidv4(), text: '', unit: '', medicalCode: '', criticalMinValue: '', abnormalMinValue: '', abnormalMaxValue: '', criticalMaxValue: '' }],
    });
  };

  const handleRemoveOption = (index) => {
    const updatedAnswerOptions = [...medicalTest.answerOptions];
    updatedAnswerOptions.splice(index, 1);
    setMedicalTest({ ...medicalTest, answerOptions: updatedAnswerOptions });
  };

  const handleTabChange = (type) => {
    setActiveTab2(type);
  };

  const handleInputChange2 = (field, value) => {

    if (questionData[activeTab2]) {
      setquestionData((prevData) => ({
        ...prevData,
        [activeTab2]: {
          ...prevData[activeTab2],
          [field]: value,
        },
      }));
    }
    else {

      setquestionData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleOptionChange = (index, field, value) => {

    console.log('index, field, value', index, field, value)
    if (questionData[activeTab2]) {
      setquestionData((prevData) => ({
        ...prevData,
        [activeTab2]: {
          ...prevData[activeTab2],
          answerOptions: prevData[activeTab2].answerOptions.map((option, i) =>
            i === index ? { ...option, [field]: value } : option
          ),
        },
      }));
    }
    else {

      setquestionData(prevQuestionData => {
        const updatedAnswerOptions = [...prevQuestionData.answerOptions];
        updatedAnswerOptions[index] = {
          ...updatedAnswerOptions[index],
          [field]: value
        };
        return {
          ...prevQuestionData,
          answerOptions: updatedAnswerOptions
        };
      });
    }
  };



  const handleAddquestion = () => {

    if (questionData[activeTab2]) {
      setquestionData((prevData) => ({
        ...prevData,
        [activeTab2]: {
          ...prevData[activeTab2],
          answerOptions: [...prevData[activeTab2]?.answerOptions, {id: uuidv4(), text: '', unit: '', criticalMinValue: 0, abnormalMinValue: 0, abnormalMaxValue: 0, criticalMaxValue: 0 }],
        },
      }));
    }
    else {
      setquestionData(prevQuestionData => ({
        ...prevQuestionData,
        answerOptions: [...prevQuestionData.answerOptions, {id: uuidv4(), text: '', unit: '', criticalMinValue: 0, abnormalMinValue: 0, abnormalMaxValue: 0, criticalMaxValue: 0 }]
      }));
    }

  }



  const renderQuestionFields = () => {
    console.log('activeTab2222', activeTab2)
    const activeType = questionData[activeTab2]?questionData[activeTab2]:activeTab2;
    console.log('activeType', activeType)


    switch (activeTab2) {
      case 'Single Select':
      case 'Multi Select':
        return renderCommonFields(activeType);
      case 'Numeric Input':
        return renderNumericFields(activeType);
      case 'Alphanumeric Input':
      case 'Date Input':
        return renderAlphanumericFields(activeType);
      default:
        return null;
    }



  };

  const saveMedicalTest = () => {
    const medicalTestResponse = {
      isTemplate: istemplate,
      type: 'Medical Test',
      data: medicalTest,
    };

    const updatedResponses = [...responses];

    if (editBlockIndex !== null && editBlockIndex < updatedResponses.length) {
      console.log('ifffffffffff')
      updatedResponses.splice(editBlockIndex, 1, medicalTestResponse);
    } else {
      if (sidebartoggle && addBlockIndex !== null && addBlockIndex < updatedResponses.length - 1) {
        console.log('ifffffffffff')
        updatedResponses.splice(addBlockIndex + 1, 0, medicalTestResponse);
      } else {
        console.log('ifffffffffff')
        const firstObjectIndex = sidebartoggle ? 0 : updatedResponses.findIndex(response => response.length - 1);
        console.log('firstObjectIndex', firstObjectIndex)
        const insertionIndex = firstObjectIndex !== -1 ? firstObjectIndex + 2 : updatedResponses.length;
        updatedResponses.splice(insertionIndex, 0, medicalTestResponse);
      }
    }



    // Update the responses state
    setResponses(updatedResponses);
    // setmedicalTestList((prevResponses) => [...prevResponses, medicalTest]);
    setistemplate(0)
    // localStorage.setItem('responses', JSON.stringify(updatedResponses));
    // console.log('Medical Test data:', medicalTest);
    // console.log('Medical Test data list:', medicalTestList);
    // alert('Response saved successfully!');
    setsidebartoggle(false);
  };

  const handlequestionsave = () => {


    const questionResponse = {
      isTemplate: istemplate,
      type: 'Question',
      data: { type: activeTab2, details: questionData[activeTab2] ? questionData[activeTab2] : questionData },
    };

    const updatedResponses = [...responses];

    if (editBlockIndex !== null && editBlockIndex < updatedResponses.length) {
      updatedResponses.splice(editBlockIndex, 1, questionResponse);
    } else {
      if (sidebartoggle && addBlockIndex !== null && addBlockIndex < updatedResponses.length - 1) {
        updatedResponses.splice(addBlockIndex + 1, 0, questionResponse);
      } else {
        const firstObjectIndex = sidebartoggle ? 0 : updatedResponses.findIndex(response => response.length - 1);
        const insertionIndex = firstObjectIndex !== -1 ? firstObjectIndex + 1 : updatedResponses.length;
        updatedResponses.splice(insertionIndex, 0, questionResponse);
      }
    }

    setResponses(updatedResponses);
    // setquestionList((prevResponses) => [...prevResponses, questionResponse]);
    setistemplate(0)
    // localStorage.setItem('responses', JSON.stringify(updatedResponses));
    // console.log('Save Response:', questionResponse);
    // console.log('Question Data:', questionList);
    // alert('Response saved successfully!');
    setsidebartoggle(false);
    setAddBlockIndex(null)
    setEditBlockIndex(null)
  };

  const handleSaveMessage = () => {

    console.log('messageListrtr', messageList)


    const messageResponse = {
      isTemplate: istemplate,
      type: 'Messages',
      data: messagesData,
    };

    const updatedResponses = [...responses];

    if (editBlockIndex !== null && editBlockIndex < updatedResponses.length) {
      updatedResponses.splice(editBlockIndex, 1, messageResponse);
    } else {
      if (sidebartoggle && addBlockIndex !== null && addBlockIndex < updatedResponses.length - 1) {
        updatedResponses.splice(addBlockIndex + 1, 0, messageResponse);
      } else {
        const firstObjectIndex = sidebartoggle ? 0 : updatedResponses.findIndex(response => response.length - 1);
        const insertionIndex = firstObjectIndex !== -1 ? firstObjectIndex + 1 : updatedResponses.length;
        updatedResponses.splice(insertionIndex, 0, messageResponse);
      }
    }

    console.log('messagesData', messagesData)
    // Update the responses state
    setResponses(updatedResponses);
    // setmessageList((prevResponses) => [...prevResponses, messagesData]);
    setistemplate(0)
    setAddBlockIndex(null)
    setEditBlockIndex(null)
    // Save the updated responses to local storage
    // localStorage.setItem('responses', JSON.stringify(updatedResponses));
    // console.log('Save Messages:', messageResponse);
    // console.log('Save Messages list:', messageList);
    // alert('Response saved successfully!');
    setsidebartoggle(false);
  };


  const getTemplates = async () => {
    try {
      setload(true);
      settemplates(null);

      const response = await dispatch(getScreeningBlockTemplete()).unwrap();


      settemplates(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  }


  const getPlanDetails = async () => {
    try {
      setload(true);
      

      const response = await dispatch(getScreeningPlanDetail(location.state)).unwrap();


      console.log('response', response.data)
  
          setResponses(response?.data ? response?.data?.screeningBlocksData?.map((d) => d) : [])
          setName(response?.data ? response?.data?.name : "")
          setistemplate(response?.data ? response?.data?.isTemplate : 0)
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  }


  useEffect(() => {
    getTemplates()
    if(location.state){
      getPlanDetails()
    }
  }, []);

  console.log(responses, "responses");
  console.log(location.state, "llocation.state");

  const moveResponse = (currentIndex, direction) => {
    const updatedResponses = [...responses];
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    if (newIndex >= 0 && newIndex < updatedResponses.length) {
      [updatedResponses[currentIndex], updatedResponses[newIndex]] = [updatedResponses[newIndex], updatedResponses[currentIndex]];

      // Swap the order property of the two responses
      [updatedResponses[currentIndex].order, updatedResponses[newIndex].order] = [updatedResponses[newIndex].order, updatedResponses[currentIndex].order];

      setResponses(updatedResponses);
      // localStorage.setItem('questionnaireResponses', JSON.stringify(updatedResponses));
    }
  };


  const deleteResponse = (index) => {
    const updatedResponses = [...responses];
    updatedResponses.splice(index, 1);
    setResponses(updatedResponses);
    localStorage.setItem('responses', JSON.stringify(updatedResponses));
  };


  const handleAddBlock = (index) => {

    console.log('indexxxxxxxxx', index + 1)
    setsidebartoggle(true);
    setActiveTab("")
    setAddBlockIndex(index + 1);
    setEditBlockIndex(null)
    setcreateMedicalTest(null)
    setcreateQuestions(null)
    setcreateMessage(null)
    setActiveTab2(null)
  };

  console.log('activeTab', activeTab)


 

  console.log('questionData', questionData)

  const renderCommonFields = () => {
    console.log('gfgfggggg', questionData)
    const answerOptions  =  questionData[activeTab2]?questionData[activeTab2]?.answerOptions:questionData?.answerOptions || {};
    const isNumericInput = activeTab2 === 'Numeric Input';
    console.log('answerOptions', answerOptions)


    

    return (
      <>
        <div className="lh-input2 mb-3">
          <label htmlFor="headingg" className="form-label">Heading</label>
          <input
            type="text"
            className="form-control"
            id="headingg"
            placeholder="Heading"
            name="heading"
            value={questionData?.heading}
            onChange={(e) => handleInputChange2('heading', e.target.value)}
          />
        </div>
        <div className="lh-input2 mb-3">
          <label htmlFor="QuestionText" className="form-label">Question Text</label>
          <input
            type="text"
            className="form-control"
            id="QuestionText"
            placeholder="Question Text"
            value={questionData?.questionText}
            onChange={(e) => handleInputChange2('questionText', e.target.value)}
          />
        </div>

        
        {Array.isArray(answerOptions)&&answerOptions?.length>0&&(<>
            <h3 className="font-size-20 blue-color poppins-font fw-bold">Answer Options</h3>
            {answerOptions?.map((option, index) => (
              <div className="mb-3" key={index}>
                <p className="font-size-16 blue-color poppins-font fw-bold mb-2">Option {index + 1}:</p>
                <div className="lh-input2 mb-1">
                  <label for="Text-heading" class="form-label">Text</label>
                  <input type="text" class="form-control" id="Text-heading" placeholder="Text" value={option.text} onChange={(e) => handleOptionChange(index, 'text', e.target.value)} />
                </div>
                {
                  !isNumericInput && <div className="lh-input2 mb-3">
                    <label for="NumericValue" class="form-label">Numeric Value</label>
                    <input type="text" class="form-control" id="NumericValue" placeholder="Numeric Value" value={option.numericValue} onChange={(e) => handleOptionChange(index, 'numericValue', e.target.value)} />
                  </div>
                }

                {isNumericInput && (<>
                  <div className="lh-input2 mb-3">
                    <label for="unit" class="form-label">Unit</label>
                    <input type="text" class="form-control" id="unit" placeholder="Unit" value={option.unit} onChange={(e) => handleOptionChange(index, 'unit', e.target.value)} />
                  </div>
                  <div className="lh-input2 mb-1">
                    <label htmlFor={`medicalcode${index}`} className="form-label">
                      Medical Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`medicalcode${index}`}
                      placeholder="Medical Code"
                      value={option?.medicalCode}
                      onChange={(e) => handleOptionChange(index, 'medicalCode', e.target.value)}
                    />
                  </div>
                </>)}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font-size-16 blue-color poppins-font fw-bold mb-0" >Ranges</p>
                  <span className="cursor-pointer" onClick={() => settoggle(!toggle)}>{toggle ? <ChevronUp /> : <ChevronDown />}</span>
                </div>
                {!toggle && (<div>
                  <div className="lh-input2 mb-1">
                    <label for="critical-min-value" class="form-label">Critical min value</label>
                    <input type="number" class="form-control" id="critical-mi- value" placeholder="Critical min value" value={option?.criticalMinValue} onChange={(e) => handleOptionChange(index, 'criticalMinValue', e.target.value)} />
                  </div>
                  <div className="lh-input2 mb-1">
                    <label for="abnormal-min-value" class="form-label">Abnormal min value</label>
                    <input type="number" class="form-control" id="abnormal-min-value" placeholder="abnormal min value" value={option?.abnormalMinValue} onChange={(e) => handleOptionChange(index, 'abnormalMinValue', e.target.value)} />
                  </div>
                  <div className="lh-input2 mb-1">
                    <label for="abnormal-max-value" class="form-label">Abnormal max value</label>
                    <input type="number" class="form-control" id="abnormal-max-value" placeholder="abnormal max value" value={option?.abnormalMaxValue} onChange={(e) => handleOptionChange(index, 'abnormalMaxValue', e.target.value)} />
                  </div>
                  <div className="lh-input2 mb-1">
                    <label for="critical-max-value" class="form-label">Critical max value</label>
                    <input type="number" class="form-control" id="critical-max-value" placeholder="Critical max value" value={option?.criticalMaxValue} onChange={(e) => handleOptionChange(index, 'criticalMaxValue', e.target.value)} />
                  </div>
                </div>
                )}

                <a className="font-size-16 red-color poppins-font fw-semibold mb-0 cursor-pointer" onClick={() => handleRemoveOption(index)}>Remove Option</a>
              </div>
            ))}
            <p className="font-size-16 blue-color poppins-font fw-semibold mb-4 cursor-pointer" onClick={handleAddquestion}>Add Option</p>
         
        </>)}
        <div>
        </div>
      </>
    );
  };

  const renderNumericFields = () => { return (<>{renderCommonFields()}</>); };

  const renderAlphanumericFields = () => {
    return (
      <>
        {renderCommonFields()}
        <div className="lh-input2 mb-1">
          <label htmlFor="medicalcode" className="form-label">Medical Code</label>
          <input
            type="text"
            className="form-control"
            id="medicalcode"
            placeholder="Medical Code"
            defaultValue={questionData[activeTab2]?.medicalCode || ''}
            onChange={(e) => handleInputChange('medicalCode', e.target.value)}
          />
        </div>
      </>
    );
  };

  const [toggleStates, setToggleStates] = useState(Array(medicalTest.answerOptions.length).fill(true));

  // Update the toggle state
  const handleToggle = (index) => {
    const updatedToggles = [...toggleStates];
    updatedToggles[index] = !updatedToggles[index];
    setToggleStates(updatedToggles);
  };



  const handleSubmit = async (e, isSaveType) => {

    try {



    if(location?.state){
      await dispatch(editScreeningBlock({name: name, data: responses, isSaveType: isSaveType, id: location?.state})).unwrap();
    }
    else{
      await dispatch(createScreeningBlock({name: name, data: responses, isSaveType: isSaveType})).unwrap();
    }
  
  
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
  };


  const editResponse = (index) => {

    setsidebartoggle(true);
    setEditBlockIndex(index)
    setActiveTab(responses[index]?.type)
    setAddBlockIndex(null);




    if (responses[index]?.type == "Medical Test") {

      setmedicaltestToggle(false)
      setmessagesToggle(true)
      setquestionToggle(true)

      setcreateMedicalTest(true)
      setcreateQuestions(false)
      setcreateMessage(false)
      setistemplate(responses[index]?.isTemplate)


      const editData = {
        heading: responses[index]?.data?.heading,
        isTemplate: responses[index]?.data?.isTemplate,
        questionText: responses[index]?.data?.questionText,
        answerOptions: responses[index]?.data?.answerOptions,
      }
      setMedicalTest(editData)
      setmedicalTestList([editData])
    }


    if (responses[index]?.type == "Question") {

      setmedicaltestToggle(true)
      setquestionToggle(false)
      setmessagesToggle(true)

      setcreateMedicalTest(false)
      setcreateQuestions(true)
      setcreateMessage(false)
      setActiveTab2(responses[index]?.data?.type)
      setistemplate(responses[index]?.isTemplate)
      const responseType = responses[index]?.data?.type;

      if (responseType) {

        const editData = responses[index]?.data?.details

        setquestionData(editData);
        setquestionList([editData]);
      }

    }


    if (responses[index]?.type == "Messages") {

      setmedicaltestToggle(true)
      setquestionToggle(true)
      setmessagesToggle(false)

      setcreateMedicalTest(false)
      setcreateQuestions(false)
      setcreateMessage(true)

      setistemplate(responses[index]?.isTemplate)
      setcreateMessage(true)
      const editData = {
        heading: responses[index]?.data?.heading,
        messageText: responses[index]?.data?.messageText,
      }
      setMessagesData(editData)
      setmessageList(editData)
    }



  };

 
  
  const handleTemplateClick=(type,data)=>{

    console.log('dataaaaaaaa', data)
    setActiveTab(data?.type)
    setsidebartoggle(true);


    if(type=="Medical Test"){
      setActiveTab('Medical Test')

      const editData = {
        heading: data?.data?.heading,
        isTemplate: data?.isTemplate,
        questionText: data?.data?.questionText,
        answerOptions: data?.data?.answerOptions,
      }
      setMedicalTest(editData)
      setmedicalTestList([editData])
    }

    if (type == "Question") {
      settoggle1(!toggle1)
      setquestionToggle(false)
      setcreateQuestions(true)
      setActiveTab2(data?.data?.type)



      const editData = data?.data?.details

      setquestionData(editData);
      setquestionList([editData]);
    
}
    if(type=="Messages"){
      setActiveTab('Messages')

      const editData = {
        heading: data?.data?.heading,
        messageText: data?.data?.messageText,
      }
      setMessagesData(editData)
      setmessageList(editData)
    }
  }

  console.log('responsesssssssss', responses)
  console.log('templates', templates)

  return (
    <DndProvider backend={HTML5Backend}>

      <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/screening-plans")}>Screening Plans</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Screening Plans</li>
          </ol>
        </nav>



        <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Screening Plans</h2>
        <div className="lh-radius-20 white-bg lh-shadow p-4 mb-4 position-relative">
          <div className="plan-list-row">
            <div className={`plan-list-col-1 ${sidebartoggle ? "show-less" : ""}`}>
              <div className="row gy-3 mb-5">
                <div className="col-md-6 lh-input2">
                  <label for="Name" class="form-label">Screening Plan Name</label>
                  <input onChange={(e) => setName(e.target.value)} defaultValue={name} type="text" class="form-control" id="Name" placeholder="Screening Plan Name" />
                </div>
                <div className="col-md-6 d-flex gap-3 justify-content-end align-items-center">
                  <button onClick={(e) => handleSubmit(e, 0)} class="btn lh-btn lh-blue-btn px-4">Save as draft</button>
                  <button onClick={(e) => handleSubmit(e, 1)} class="btn lh-btn lh-blue-btn px-4">Publish</button>
                </div>
              </div>



              <div className="lh-plan-block-list">
                {responses && responses.length > 0 ? (
                  responses?.map((response, i) => (<>
                    <ResponseItem key={i} index={i} response={response} moveResponse={moveResponse} deleteResponse={deleteResponse} editResponse={editResponse} />
                    <div className={`lh-add-block ${hoveredIndex === i ? 'lh-add-block-hover' : ''}`} onMouseEnter={() => handleHover(i)} onMouseLeave={() => handleHover(null)}>
                      <span className="line"></span>
                      <div className="lh-block-btn cursor-pointer" onClick={() => handleAddBlock(i)}><Plus size={18} /><span>Insert Block</span></div>
                      <span className="line"></span>
                    </div>
                  </>))) : (
                  <div style={{ height: '50vh', border: '1px dashed rgb(3 63 90 / 50%)', borderRadius: 'var(--radius-20)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className={`lh-add-block w-100`} style={{ opacity: 1 }}>
                      <span className="line"></span>
                      <div className="lh-block-btn cursor-pointer" onClick={() => setsidebartoggle(true)}><Plus size={18} /><span>Insert Block</span></div>
                      <span className="line"></span>
                    </div>
                  </div>
                )}
              </div>
            </div>


            {sidebartoggle ? <div className={`plan-list-col-2`}>
              <div style={{ minHeight: '100vh' }} className={`lh-add-sidebar ${activeTab && "lh-sidebar-more"}`}>


                <div className={`lh-sidebar-col-1`}>
                  <div className="d-flex justify-content-between align-items-center blue-color mb-4">
                    <ChevronLeft className="cursor-pointer" onClick={() => setsidebartoggle(false)} />
                    <p className="font-size-18 blue-color poppins-font mb-0">Screening Block</p>
                  </div>
                  <ul className="lh-sidebar-list">



                    <li className={createMedicalTest && "active"}><a onClick={() => setcreateMedicalTest(!createMedicalTest)}>Medical Tests</a>
                      {createMedicalTest ?
                        <ul className={`lh-submenu`}>
                          {templates ? templates?.filter(item => item.type == 'Medical Test')?.map((data, i) => (
                            <li onClick={() => handleTemplateClick('Medical Test', data)} key={i}><a>{data?.data?.heading}</a></li>
                          )) : null}
                          <li onClick={() => setActiveTab('Medical Test')} className="lh-create-btn">Create a Custom Test <ChevronRight size={18} /></li>
                        </ul>
                        : null}
                    </li>


                    <li className={createQuestions && "active"}><a onClick={() => setcreateQuestions(!createQuestions)}>Questions</a>
                      {createQuestions ?
                        <ul className={`lh-submenu`}>
                          {templates?templates?.filter(item => item.type == 'Question')?.map((data,i)=> (
                            <li onClick={() => handleTemplateClick('Question',data)} key={i}><a>{data?.data?.details?.heading}</a></li>
                          )):null}
                          <li onClick={() => settoggle1(!toggle1)} className="lh-create-btn">Create a Custom Questions <ChevronRight size={18} /></li>
                          {!toggle1 && <ul className="lh-question-list-toggle">
                            {QuestionTypes.map((type, i) => (
                              <li className={`${type === activeTab2 ? 'active' : ''}`} onClick={() => { handleTabChange(type); setActiveTab('Question') }}>
                                {type}
                              </li>
                            ))}
                          </ul>}
                        </ul>
                        : null}
                    </li>


                    <li className={createMessage && "active"}><a onClick={() => setcreateMessage(!createMessage)}>Message</a>
                      {createMessage ?
                        <ul className={`lh-submenu ${createMessage && "show"}`}>
                          {templates ? templates?.filter(item => item.type == 'Messages')?.map((data, i) => (
                            <li onClick={() => handleTemplateClick('Messages', data)} key={i}><a>{data?.data?.heading}</a></li>
                          )) : null}
                          <li onClick={() => setActiveTab('Messages')} className="lh-create-btn">Create a Custom Message <ChevronRight size={18} /></li>
                        </ul>
                        : null}
                    </li>


                  </ul>
                </div>





                {activeTab === 'Medical Test' && (
                  <div className="lh-sidebar-col-2">
                    <div className="lh-input2 mb-1">
                      <label for="Heading" class="form-label">Heading</label>
                      <input type="text" class="form-control" id="Heading" placeholder="Heading" defaultValue={medicalTest.heading} onChange={(e) => handleInputChange('heading', e.target.value)} />
                    </div>
                    <div className="lh-input2 mb-3">
                      <label for="QuestionText" class="form-label">Question Text</label>
                      <input type="text" class="form-control" id="QuestionText" placeholder="Question Text" defaultValue={medicalTest.questionText} onChange={(e) => handleInputChange('questionText', e.target.value)} />
                    </div>
                    <h3 className="font-size-20 blue-color poppins-font fw-bold">Answer Options</h3>
                    {medicalTest.answerOptions.map((option, index) => (
                      <div className="mb-3" key={index}>
                        <p className="font-size-16 blue-color poppins-font fw-bold mb-2">Option {index + 1}:</p>
                        <div className="lh-input2 mb-1">
                          <label for="Text" class="form-label">Text</label>
                          <input type="text" class="form-control" id="Text" placeholder="Text" defaultValue={option.text} onChange={(e) => handleInputChange('answerOption', { text: e.target.value }, index)} />
                        </div>
                        <div className="lh-input2 mb-1">
                          <label for="unit" class="form-label">Unit</label>
                          <input type="text" class="form-control" id="unit" placeholder="Unit" defaultValue={option.unit} onChange={(e) => handleInputChange('answerOption', { unit: e.target.value }, index)} />
                        </div>
                        <div className="lh-input2 mb-3">
                          <label for="medicalcode" class="form-label">Medical Code</label>
                          <input type="text" class="form-control" id="medicalcode" placeholder="Medical Code" defaultValue={option.medicalCode} onChange={(e) => handleInputChange('answerOption', { medicalCode: e.target.value }, index)} />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <p className="font-size-16 blue-color poppins-font fw-bold mb-0" >Ranges</p>
                          <span className="cursor-pointer" onClick={() => handleToggle(index)}>{toggleStates[index] ? <ChevronUp /> : <ChevronDown />}</span>
                        </div>
                        {!toggleStates[index] && (<div>
                          <div className="lh-input2 mb-1">
                            <label for="critical-min-value" class="form-label">Critical min value</label>
                            <input type="number" class="form-control" id="critical-mi- value" placeholder="Critical min value" defaultValue={option.criticalMinValue} onChange={(e) => handleInputChange('answerOption', { criticalMinValue: e.target.value }, index)} />
                          </div>
                          <div className="lh-input2 mb-1">
                            <label for="abnormal-min-value" class="form-label">Abnormal min value</label>
                            <input type="number" class="form-control" id="abnormal-min-value" placeholder="abnormal min value" defaultValue={option.abnormalMinValue} onChange={(e) => handleInputChange('answerOption', { abnormalMinValue: e.target.value }, index)} />
                          </div>
                          <div className="lh-input2 mb-1">
                            <label for="abnormal-max-value" class="form-label">Abnormal max value</label>
                            <input type="number" class="form-control" id="abnormal-max-value" placeholder="abnormal max value" defaultValue={option.abnormalMaxValue} onChange={(e) => handleInputChange('answerOption', { abnormalMaxValue: e.target.value }, index)} />
                          </div>
                          <div className="lh-input2 mb-1">
                            <label for="critical-max-value" class="form-label">Critical max value</label>
                            <input type="number" class="form-control" id="critical-max-value" placeholder="Critical max value" defaultValue={option.criticalMaxValue} onChange={(e) => handleInputChange('answerOption', { criticalMaxValue: e.target.value }, index)} />
                          </div>
                        </div>

                        )}

                        <a className="font-size-16 red-color poppins-font fw-semibold mb-0 cursor-pointer" onClick={() => handleRemoveOption(index)}>Remove Option</a>
                      </div>
                    ))}
                    <p className="font-size-16 blue-color poppins-font fw-semibold mb-4 cursor-pointer" onClick={handleAddOption}>Add Option</p>
                    <div className="lh-input2 mb-1">
                      <input type="checkbox" checked={istemplate == 1 ? true : false} onChange={() => setistemplate(istemplate == 1 ? 0 : 1)} />
                      <label>{" "}Save as Template</label>
                    </div>
                    <button class="btn lh-btn lh-blue-btn px-4" onClick={saveMedicalTest}>Save</button>
                  </div>
                )}


                {activeTab === 'Question' && (
                  <div className="lh-sidebar-col-2">
                    
                    <div>
                      <div className="mb-3">{renderQuestionFields()}</div>
                      <div className="lh-input2 mb-1">
                        <input type="checkbox" checked={istemplate == 1 ? true : false} onChange={() => setistemplate(istemplate == 1 ? 0 : 1)} />
                        <label>{" "}Save as Template</label>
                      </div>
                      <button class="btn lh-btn lh-blue-btn px-4" onClick={handlequestionsave}>Save</button>
                    </div>
                  </div>
                )}


                {activeTab === 'Messages' && (
                  <div className="lh-sidebar-col-2">
                    <div className="lh-input2 mb-3">
                      <label htmlFor="messagesHeading" className="form-label">Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        id="messagesHeading"
                        placeholder="Heading"
                        defaultValue={messagesData.heading}
                        onChange={(e) => setMessagesData({ ...messagesData, heading: e.target.value })}
                      />
                    </div>
                    <div class="lh-input2 mb-3">
                      <label for="messageText" class="form-label">Message Text</label>
                      <textarea class="form-control" id="messageText" rows="6" placeholder="Message Text" defaultValue={messagesData.messageText}
                        onChange={(e) => setMessagesData({ ...messagesData, messageText: e.target.value })}></textarea>
                    </div>
                    <div className="lh-input2 mb-1">
                      <input type="checkbox" checked={istemplate == 1 ? true : false} onChange={() => setistemplate(istemplate == 1 ? 0 : 1)} />
                      <label>{" "}Save as Template</label>
                    </div>
                    <button class="btn lh-btn lh-blue-btn px-4" onClick={handleSaveMessage}>Save</button>

                  </div>
                )}


              </div>
            </div> : null}

          </div>


        </div>
      </div>
    </DndProvider>
  );
}

export default AddScreeningPlan;