import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicalBoardPatientUsers,
} from "../../../store/slices/userSlice";


const Patient = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState();
  const [load, setload] = useState(false);



  const AllMedicalBoardPatients = async () => {
    try {
      setload(true);
      setdata(null);
      setfilteredData(null);

      const response = await dispatch(
        getMedicalBoardPatientUsers()
      ).unwrap();

      console.log("response.data", response.data);
      setdata(response?.data);
      setfilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      AllMedicalBoardPatients();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);


  return (
    <>
      <div className="lh-patient-list-page px-md-5 pt-3 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Patients</li>
          </ol>
        </nav>
        <div className="d-md-flex align-items-center justify-content-between mb-2">
          <h2 className="font-size-24 blue-color poppins-font fw-bold">Patients</h2>
          <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-patient")}>Add Patient</button>
        </div>
        <div className="lh-radius-20 white-bg lh-shadow p-3">
          <p className="font-size-20 blue-color">Patient</p>
          <div class="table-responsive">
            <table class="table lh-table table-borderless">
              <thead>
                <tr>
                  {/* <th style={{width:'70px'}} scope="col">Status</th> */}
                  <th scope="col">MR No.</th>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">Registered Clinic</th>
                  {/* <th style={{width:'400px'}} scope="col">Health Status</th> */}
                  <th style={{ width: '100px' }} scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((d, i) => (
                  <tr>
                    {/* <td style={{verticalAlign: "middle"}}>
      <span className={`lh-dot ${d.status === 1 ? 'green-bg' : (d.status === 2 ? 'red-bg' : (d.status === 3 ? 'blue-bg' : ''))}`}></span>
      </td> */}
                    <td>{d?.roleSpecificData.MRNumber}</td>
                    <td>{d?.firstName} {d?.lastName}</td>
                    <td>{d?.gender}</td>
                    {d?.dateOfBirth ? (<td>{currentYear - d?.dateOfBirth?.slice(0, 4)} yrs</td>) : ""}
                    <td>{d?.clinicId?.name}</td>
                    {/* <td style={{verticalAlign: "middle"}}>
      <div className="progress" role="progressbar" aria-label="Example 20px high" aria-valuenow={d.health_status} aria-valuemin="0" aria-valuemax="100" style={{"height": "14px"}}>
        <div className={`progress-bar ${d.status === 1 ? 'green-bg' : (d.status === 2 ? 'red-bg' : (d.status === 3 ? 'blue-bg' : ''))}`} style={{"width": d.health_status}}></div>
      </div>
      </td> */}
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <div onClick={() => navigate("/add-patient", { state: { data: d, type: "edit" } })} className={`lh-action-icons ${d.status === 1 ? 'light-green-bg' : (d.status === 2 ? 'light-red-bg' : (d.status === 3 ? 'l-blue-bg' : ''))}`}><Pencil width="18" /></div>
                        <div onClick={() => navigate("/patient-detail", { state: { data: d } })} className={`lh-action-icons ${d.status === 1 ? 'light-green-bg' : (d.status === 2 ? 'light-red-bg' : (d.status === 3 ? 'l-blue-bg' : ''))}`}><Activity width="18" /></div>
                        {/* <div className={`lh-action-icons ${d.status === 1 ? 'light-green-bg' : (d.status === 2 ? 'light-red-bg' : (d.status === 3 ? 'l-blue-bg' : ''))}`}><Pill width="18" /></div>
            <div className={`lh-action-icons ${d.status === 1 ? 'light-green-bg' : (d.status === 2 ? 'light-red-bg' : (d.status === 3 ? 'l-blue-bg' : ''))}`}><Stethoscope width="18" /></div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Patient