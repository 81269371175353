import React, { useEffect, useState } from "react";
import { Camera } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany, addUser, editCompany
} from "../../../store/slices/userSlice";

const AddClinicSurgery = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  // Medical Board Form State
  const [id, setid] = useState(location.state ? location.state.data._id : "");
  const [type, settype] = useState(location.state ? location?.state?.type : "");
  const [name, setName] = useState(location.state ? location?.state?.data?.name : "");
  const [email, setEmail] = useState(location.state ? location?.state?.data?.email : "");
  const [phone, setPhone] = useState(location.state ? location?.state?.data?.phone : "");
  const [address, setAddress] = useState(location.state ? location?.state?.data?.address?.line1 : "");
  const [address2, setAddress2] = useState(location.state ? location?.state?.data?.address?.line2 : "");
  const [city, setCity] = useState(location.state ? location?.state?.data?.address?.city : "");
  const [country, setCountry] = useState(location.state ? location?.state?.data?.address?.country : "");
  const [postcode, setPostcode] = useState(location.state ? location?.state?.data?.address?.postalCode : "");
  const [workingHours, setWorkingHours] = useState(location.state ? location?.state?.data?.timings : "");
  const [servicesOffered, setservicesOffered] = useState(location.state ? location?.state?.data?.servicesOffered : []);
  const [specialities, setspecialities] = useState(location.state ? location?.state?.data?.specialities : []);


  // Medical Board User (Manager) Form State
  const [imageName, setimageName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationalIdNumber, setNationalIdNumber] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [gender, setGender] = useState("");

  // Address State
  const [addressUser, setAddressUser] = useState("");
  const [addressUser2, setAddressUser2] = useState("");
  const [cityUser, setCityUser] = useState("");
  const [countryUser, setCountryUser] = useState("");
  const [postcodeUser, setPostcodeUser] = useState("");

  // Emergency Contact Information State
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
  const [emergencyContactPhone, setEmergencyContactPhone] = useState("");

  console.log('servicesOffered', servicesOffered)

  const medicalSpecialties = [
    "Allergy and Immunology",
    "Anesthesiology",
    "Cardiology",
    "Dermatology",
    "Endocrinology",
    "Gastroenterology",
    "Geriatrics",
    "Hematology",
    "Infectious Disease",
    "Nephrology",
    "Neurology",
    "Obstetrics and Gynecology",
    "Oncology",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Otolaryngology (ENT)",
    "Pediatrics",
    "Physical Medicine and Rehabilitation",
    "Plastic Surgery",
    "Psychiatry",
    "Pulmonology",
    "Radiology",
    "Rheumatology",
    "Urology",
    "Vascular Surgery",
    "Neonatology",
    "Sports Medicine",
    "Pain Management",
    "Sleep Medicine",
    "Bariatric Surgery",
    "Podiatry",
    "Gynecologic Oncology",
    "Pediatric Subspecialties",
    "Addiction Medicine",
    "Genetics",
    "Occupational Medicine",
    "Dentistry and Oral Surgery",
    "Dietetics and Nutrition",
    "Dermatopathology",
    "Reproductive Endocrinology and Infertility",
  ];
  const medicalNursingSpecialties = [
    "Allergy and Immunology",
    "Anesthesiology",
    "Cardiology/Cardiac Nursing",
    "Dermatology",
    "Emergency Medicine/Emergency Nursing",
    "Endocrinology",
    "Family Medicine/Family Practice Nursing",
    "Gastroenterology",
    "Geriatrics/Geriatric Nursing",
    "Hematology",
    "Infectious Disease Nursing",
    "Internal Medicine",
    "Medical Genetics",
    "Nephrology/Nephrology Nursing",
    "Neurology/Neuroscience Nursing",
    "Obstetrics and Gynecology",
    "Oncology/Oncology Nursing",
    "Ophthalmology",
    "Orthopedic Surgery/Orthopedic Nursing",
    "Otolaryngology (ENT)",
    "Pathology",
    "Pediatrics/Pediatric Nursing",
    "Physical Medicine and Rehabilitation",
    "Plastic Surgery",
    "Psychiatry/Psychiatric Nursing",
    "Pulmonology",
    "Radiology/Radiology Nursing",
    "Rheumatology",
    "Sports Medicine",
    "Surgery (General)",
    "Thoracic Surgery",
    "Urology/Urology Nursing",
    "Vascular Surgery",
    "Nurse Anesthetist",
    "Nurse Midwife",
    "Nurse Practitioner",
    "Clinical Nurse Specialist",
    "Neonatal Nursing",
    "Labor and Delivery Nursing",
    "Hospice/Palliative Care Nursing",
    "Holistic Nursing",
    "Home Health Nursing",
    "Forensic Nursing",
    "Emergency Nursing",
    "Critical Care Nursing",
    "Cardiac Nursing",
    "Aesthetic/Cosmetic Nursing",
    "Occupational Health Nursing",
    "Public Health Nursing",
    "Rehabilitation Nursing",
    "School Nursing",
    "Substance Abuse Nursing",
    "Travel Nursing",
    "Women's Health Nursing",
    "Legal Nurse Consultant",
  ];


  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
    const formData = new FormData();

    // Medical Board Form State
    formData.append("id", id);
    formData.append("companyName", name);
    formData.append("companyEmail", email);
    formData.append("companyPhone", phone);
    formData.append("companyAddressLine1", address);
    formData.append("companyAddressLine2", address2);
    formData.append("companyCity", city);
    formData.append("companyCountry", country);
    formData.append("companyPostalCode", postcode);
    formData.append("timings", workingHours);
    formData.append("servicesOffered", JSON.stringify(servicesOffered));
    formData.append("specialities", JSON.stringify(specialities));
    formData.append("type", "clinic");


    // Medical Board User (Manager) Form State

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", emailUser);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("nationalIDNumber", nationalIdNumber);
    formData.append("phone", phoneUser);
    formData.append("gender", gender);
    formData.append("imageName", imageName);
    formData.append("role", "clinicManager");


    formData.append("addressLine1", addressUser);
    formData.append("addressLine2", addressUser2);
    formData.append("city", cityUser);
    formData.append("country", countryUser);
    formData.append("postalCode", postcodeUser);


    formData.append("emergencyName", emergencyContactName);
    formData.append("emergencyRelation", emergencyContactRelation);
    formData.append("emergencyPhone", emergencyContactPhone);

    if (id && type == "add") {
      await dispatch(
        addUser(formData)
      ).unwrap();
    }

    else if (id && type == "edit") {
      await dispatch(
        editCompany({
          id: id,
          companyName: name,
          companyEmail: email,
          companyPhone: phone,
          companyAddressLine1: address,
          companyAddressLine2: address2,
          companyCity: city,
          companyCountry: country,
          companyPostalCode: postcode,
          timings: workingHours,
          servicesOffered: JSON.stringify(servicesOffered),
          specialities: JSON.stringify(specialities),
          type: "clinic"
        })
      ).unwrap();
    }

    else {
      await dispatch(
        addCompany(formData)
      ).unwrap();
    }

    navigate("/clinic-surgery");
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };



  const handleservicesOffered = (id) => {

    if (!servicesOffered.includes(id)) {
      setservicesOffered([...servicesOffered, id]);
    } else {
      const updatedEventIds = servicesOffered.filter((itemId) => itemId !== id);
      setservicesOffered(updatedEventIds);
    }

  };

  const handleSpecialities = (id) => {

    if (!specialities.includes(id)) {
      setspecialities([...specialities, id]);
    } else {
      const updatedEventIds = specialities.filter((itemId) => itemId !== id);
      setspecialities(updatedEventIds);
    }

  };



  return (
    <>
      <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/clinic-surgery")}>Clinic/Surgery</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Clinic/Surgery</li>
          </ol>
        </nav>
        <h2 className="font-size-24 blue-color poppins-font fw-bold">{id && type == "edit" ? "Clinic/Surgery Details" : id && type == "add" ? "" : "Add Clinic/Surgery"}</h2>
        {id && type == "edit" || !id && !type ? (<><div className="lh-radius-20 white-bg lh-shadow p-4 mb-4">
          <div className="row gy-3">
            <div className="col-md-4 lh-input2">
              <label htmlFor="Name" defaultValue={name} className="form-label">
                Name
              </label>
              <input
                defaultValue={name}
                type="text"
                className="form-control"
                id="Name"
                placeholder="John"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-4 lh-input2">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                defaultValue={email}
                className="form-control"
                id="Email"
                placeholder="john@lifewell.health"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4 lh-input2">
              <label htmlFor="Phone" className="form-label">
                Phone
              </label>
              <input
                defaultValue={phone}
                type="tel"
                className="form-control"
                id="Phone"
                placeholder="+967 354 3644"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <div className="lh-input2">
                <label htmlFor="Address" className="form-label">
                  Address 1
                </label>
                <input
                  defaultValue={address}
                  type="text"
                  className="form-control"
                  id="Address"
                  placeholder="Pod 1, Greenwood Clinic"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="lh-input2">
                <label htmlFor="Address" className="form-label">
                  Address 2
                </label>
                <input
                  defaultValue={address2}
                  type="text"
                  className="form-control"
                  id="Address"
                  placeholder="Pod 1, Greenwood Clinic"
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="lh-input2">
                <label htmlFor="City" className="form-label">
                  City
                </label>
                <input
                  defaultValue={city}
                  type="text"
                  className="form-control"
                  id="City"
                  placeholder="Riyadh"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="lh-input2">
                <label htmlFor="Country" className="form-label">
                  Country
                </label>
                <input
                  defaultValue={country}
                  type="text"
                  className="form-control"
                  id="Country"
                  placeholder="Saudi Arabia"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="lh-input2">
                <label htmlFor="Postcode" className="form-label">
                  Postcode
                </label>
                <input
                  defaultValue={postcode}
                  type="text"
                  className="form-control"
                  id="Postcode"
                  placeholder="KWF101"
                  onChange={(e) => setPostcode(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="lh-input2">
                <label htmlFor="Working-Hours" className="form-label">
                  Operational Timings
                </label>
                <input
                  defaultValue={workingHours}
                  type="text"
                  className="form-control"
                  id="Working-Hours"
                  placeholder="KWF101"
                  onChange={(e) => setWorkingHours(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div class="lh-input2">
                <label class="form-label">Services Offered</label>
              </div>
              <div class="dropdown lh-select-dropdown">
                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {servicesOffered?.length > 0 ? servicesOffered?.map((s, index) => (index === 0 ? s : `, ${s}`)) : "Services Offered"}
                </a>

                <ul class="dropdown-menu">
                  {medicalSpecialties.map((data, i) => (
                    <li key={i}><a class="dropdown-item">
                      <div class="form-check">
                        <input checked={servicesOffered?.includes(data)} onChange={() => handleservicesOffered(data)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          {data}
                        </label>
                      </div></a></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div class="lh-input2">
                <label class="form-label">Specialities</label>
              </div>
              <div class="dropdown lh-select-dropdown">
                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {specialities?.length > 0 ? specialities?.map((s, index) => (index === 0 ? s : `, ${s}`)) : "Specialities"}
                </a>

                <ul class="dropdown-menu">
                  {medicalSpecialties.map((data, i) => (
                    <li key={i}><a class="dropdown-item">
                      <div class="form-check">
                        <input checked={specialities?.includes(data)} onChange={() => handleSpecialities(data)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                          {data}
                        </label>
                      </div></a></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div></>) : null}

        {id && type == "add" || !id && !type ? (<><h2 className="font-size-24 blue-color poppins-font fw-bold">
          {id && type == "add" ? "Add Clinic/Surgery User" : "Add Clinic/Surgery User (Manager)"}
        </h2>
          <div className="lh-radius-20 white-bg lh-shadow p-4 mb-3">
            <div className="row mb-3">
              <div className="col-md-4">
                {/* <div class="mb-3 lh-input2">
                <label for="uid" class="form-label">
                  UID
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setUid(e.target.value)}
                  id="uid"
                  placeholder="A2434SFDF"
                />
              </div> */}
                <div class="mb-3 lh-input2">
                  <label for="First-Name" class="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    class="form-control"
                    id="First-Name"
                    placeholder="John"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    onChange={(e) => setEmailUser(e.target.value)}
                    id="Email"
                    placeholder="john@lifewell.health"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Date-of-Birth" class="form-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    id="Date-of-Birth"
                    placeholder="1/12/2024"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label class="form-label">Gender</label>
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      name="gender"
                      onChange={() => setGender("male")}
                      class="btn-check"
                      id="male"
                    />
                    <label class="btn lh-button-select" for="male">
                      Male
                    </label>

                    <input
                      type="radio"
                      name="gender"
                      onChange={() => setGender("female")}
                      class="btn-check"
                      id="female"
                    />
                    <label class="btn lh-button-select" for="female">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="mb-3 lh-input2">
                  <label for="Last-Name" class="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setLastName(e.target.value)}
                    id="Last-Name"
                    placeholder="Marshell"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="National-ID-Number" class="form-label">
                    National ID Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setNationalIdNumber(e.target.value)}
                    id="National-ID-Number"
                    placeholder="A2434SFDF"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    onChange={(e) => setPhoneUser(e.target.value)}
                    id="Phone"
                    placeholder="+967 354 3644"
                  />
                </div>
                {/* <div class="mb-3 lh-input2"> */}

                {/* </div> */}
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="imageUpload" class="form-label">
                    Profile
                  </label>
                </div>
                <div className="lh-profile-upload py-4">
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <input
                        onChange={(e) => {
                          setimageName(e.target.files[0]);
                        }}
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label for="imageUpload">
                        <Camera width={18} strokeWidth={1} /> Upload
                      </label>
                    </div>
                    <div class="avatar-preview">
                      {imageName && (
                        <img
                          id="output"
                          src={URL.createObjectURL(imageName)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="font-size-20 blue-color poppins-font fw-semibold">
              Address
            </p>
            <div className="row gy-3 mb-4">
              <div className="col-md-12">
                <div class="lh-input2">
                  <label for="Address" class="form-label">
                    Address 1
                  </label>
                  <input
                    onChange={(e) => setAddressUser(e.target.value)}
                    type="text"
                    class="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="lh-input2">
                  <label for="Address" class="form-label">
                    Address 2
                  </label>
                  <input
                    onChange={(e) => setAddressUser2(e.target.value)}
                    type="text"
                    class="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="City" class="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setCityUser(e.target.value)}
                    class="form-control"
                    id="City"
                    placeholder="Riyadh"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Country" class="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setCountryUser(e.target.value)}
                    class="form-control"
                    id="Country"
                    placeholder="Saudi Arabia"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Postcode" class="form-label">
                    Postcode
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPostcodeUser(e.target.value)}
                    class="form-control"
                    id="Postcode"
                    placeholder="KWF101"
                  />
                </div>
              </div>
            </div>

            <p className="font-size-20 blue-color poppins-font fw-semibold">
              Emergency Contact Information
            </p>
            <div className="row gy-3">
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Name" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setEmergencyContactName(e.target.value)}
                    class="form-control"
                    id="Name"
                    placeholder="Mr. Cook"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Relation" class="form-label">
                    Relation
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setEmergencyContactRelation(e.target.value)}
                    class="form-control"
                    id="Relation"
                    placeholder="Father"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    onChange={(e) => setEmergencyContactPhone(e.target.value)}
                    class="form-control"
                    id="Phone"
                    placeholder="+967 354 3644"
                  />
                </div>
              </div>
            </div>
          </div></>) : null}
        <div className="text-center">
          <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">Save</button>
        </div>
      </div>
    </>
  )
}

export default AddClinicSurgery