import { Pencil } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllScreeningBookings } from '../../../store/slices/userSlice';
import moment from 'moment';
import BookingCalendarView from './BookingCalendarView';


const Bookings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  const [booking, setbooking] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [value, setvalue] = useState("");
  const [load, setload] = useState(false);

  const [activeTab, setActiveTab] = useState('list');




  const listOfBooking = async () => {
    try {
      setload(true);
      setbooking(null);
      setfilteredData(null);


      const response = await dispatch(getAllScreeningBookings()).unwrap();


      setbooking(response?.data);
      setfilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  

  useEffect(() => {
    let mount = true;
    if (mount) {
      listOfBooking();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);

  const handleFilters = () => {
    const filteredDataa = booking?.filter((item) => {
     
      return value === "" || item.status === value;
    });
  
    setfilteredData(filteredDataa);
  };
  

  useEffect(() => {
    handleFilters();
  }, [value]);
  

  console.log('value', value)
  console.log('filteredData', filteredData)
  

  return (
    <>
      <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">All Bookings</li>
          </ol>
        </nav>
        <div className="d-md-flex align-items-center justify-content-between mb-2">
          <h2 className="font-size-24 blue-color poppins-font fw-bold">Bookings</h2>
          <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-booking")}>Add Booking</button>
        </div>
        <div className="lh-tabs d-flex flex-wrap gap-2 mb-3">
          <div className={`lh-tab-btn ${activeTab == "list" && 'active'}`} onClick={() => setActiveTab('list')}>List View</div>
          <div className={`lh-tab-btn ${activeTab == "calendar" && 'active'}`} onClick={() => setActiveTab('calendar')}>Calendar View</div>
        </div>
        {activeTab === 'list' && (<>
          <div className="row gy-3 mb-3">
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-4">Total Appointments</p>
                <br/>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{booking?.length}</h3>
                  <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>setvalue("")}><u>View All</u></p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-5">Confirmed</p>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{booking?.filter((booking)=>booking.status=="Confirmed")?.length}</h3>
                  <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>setvalue("Confirmed")}><u>View All</u></p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-5">Cancelled</p>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{booking?.filter((booking)=>booking.status=="Cancel")?.length}</h3>
                  <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>setvalue("Cancel")}><u>View All</u></p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-5">No Shows</p>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{booking?.filter((booking)=>booking.status=="No Show")?.length}</h3>
                  <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>setvalue("No Show")}><u>View All</u></p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-5">Rescheduled</p>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{booking?.filter((booking)=>booking.status=="Rescheduled")?.length}</h3>
                  <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>setvalue("Rescheduled")}><u>View All</u></p>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="lh-shadow white-bg lh-radius-20 p-3">
                <p className="font-size-16 blue-color fw-semibold mb-5">TBC</p>
                <div className="d-flex justify-content-between align-items-center pt-2">
                  <h3 className="font-size-36 green-color fw-bold mb-0">{0}</h3>
                  {/* <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer"><u>View All</u></p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="lh-radius-20 white-bg lh-shadow p-3">
            <p className="font-size-20 blue-color">Scheduled Appointments</p>
            <div class="table-responsive">
              <table class="table lh-table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">Duration</th>
                    <th scope="col">MR No.</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Doctor</th>
                    <th scope="col">Room / Pod</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?filteredData?.map((list, i) => (
                    <tr>
                      <td>{moment(list?.slotId?.date).format("MMM Do YYYY")}</td>
                      <td>{list?.slotId?.startTime}</td>
                      <td>{list?.slotId?.duration}</td>
                      <td>{list?.patientId?.roleSpecificData?.MRNumber}</td>
                      <td>{list?.patientId?.firstName + " " + list?.patientId?.lastName}</td>
                      <td>{list.type}</td>
                      <td>None</td>
                      <td>{list?.screeningPod?.name}</td>
                      <td><span className="d-flex align-items-center gap-2"><span className={`lh-dot ${(list.status === 'Cancel' || list.status === 'No Show' ? 'red-bg' : list.status === 'Confirmed' ? 'green-bg' : 'blue-bg')}`}></span>{list.status}</span></td>
                      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            {list.status === 'Confirmed'&&(<div onClick={()=>navigate("/booking-details", {state: list})} className={`lh-action-icons l-blue-bg`}><Pencil width="18" /></div>)}
        </div>
      </td>
                    </tr>
                  )):null}
                </tbody>
              </table>
            </div>
          </div>
        </>
        )}
        {activeTab === 'calendar' && (<>
          <div className="lh-radius-20 white-bg lh-shadow p-3">
            <BookingCalendarView data={filteredData}/>
          </div>
        </>)}
      </div>
    </>
  )
}

export default Bookings