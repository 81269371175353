import React, { useState } from 'react';
import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import {
  attachment
} from "../../../store/slices/userSlice";
import NoRecordFound from './NoRecordFound';

const LabResults = ({state,patientId,dataa,handleData,removeData}) => {
  const dispatch= useDispatch()
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [testName, setTestName] = useState('');
  const [date, setDate] = useState('');
  const [results, setResults] = useState('');
  const [providers, setProviders] = useState('');
  const [notes, setNotes] = useState('');
  const [fileAttachment, setFileAttachment] = useState(null); 

  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };

  const handleSubmit = (e) => {

    try {
    e.preventDefault();

    const formData = {
      id: uuidv4(),
      testName: testName,
      date: date,
      results: results,
      providers: providers,
      notes: notes,
      fileAttachment: fileAttachment,
      type: state,
    };

    handleData(formData)


    setTestName('');
    setDate('');
    setResults('');
    setProviders('');
    setNotes('');
    setFileAttachment('')

    setIsFormVisible(false);

  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };


  const handleChange=async(e)=>{
    const formData = new FormData();
    formData.append('imageName', e.target.files[0]);
    const abc= await dispatch(attachment(formData)).unwrap();

    if(abc){
      console.log('abc', abc)
      setFileAttachment(abc.data)
    }
  }


  console.log('fileAttachment', fileAttachment)

  return (
    <>
      <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
      <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Lab Results<button className="btn lh-btn lh-blue-btn px-3 add-history"  onClick={toggleFormVisibility}>{isFormVisible ? 'Close' : 'Add'}</button></p></div>
        {isFormVisible && (
          <div className="row gy-3 mb-4">
           <div className="row gy-3 mb-4">
<div className="col-md-4">
    <div class="lh-input2">
        <label for="Test-Name" class="form-label">Test Name</label>
        <input value={testName} onChange={(e) => setTestName(e.target.value)} type="text" class="form-control" id="Test-Name" placeholder="Test Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date" class="form-label">Date </label>
        <input value={date} onChange={(e) => setDate(e.target.value)} type="date" class="form-control" id="Date" placeholder="Date" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Results" class="form-label">Results</label>
        <input value={results} onChange={(e) => setResults(e.target.value)} type="text" class="form-control" id="Results" placeholder="Results" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Providers" class="form-label">Providers</label>
        <input value={providers} onChange={(e) => setProviders(e.target.value)} type="text" class="form-control" id="Providers" placeholder="Providers" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Notes" class="form-label">Notes</label>
        <input value={notes} onChange={(e) => setNotes(e.target.value)} type="text" class="form-control" id="Notes" placeholder="Notes" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Attachments" class="form-label">Attachments</label>
        <input type="file" class="form-control" id="Attachments" placeholder="Attachments" />
    </div>
    </div>
    </div>
            <div className="text-center">
              <button onClick={(e)=>handleSubmit(e)} className="btn lh-btn lh-blue-btn px-4">
                Save
              </button>
            </div>
          </div>
        )}

{dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive"> 

          <table className="table lh-table table-borderless">
            <thead>
              <tr>
                <th scope="col">Test name</th>
                <th scope="col">Date</th>
                <th scope="col">Results</th>
                <th scope="col">Providers</th>
                <th scope="col">Notes</th>
                <th style={{ width: '100px' }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
                <tr key={i}>
                  <td>{d.testName}</td>
                  <td>{moment(d.date).format("MMM DD YYYY")}</td>
                  <td>{d.results}</td>
                  <td>{d.providers}</td>
                  <td>{d.notes}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
                      <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                      <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                      <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
                      <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>):(!isFormVisible&&<NoRecordFound />)}

   
      </div>
    </>
  )
}

export default LabResults