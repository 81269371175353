import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../../context/context';
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, ArrowRight, ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react';
// import { useDispatch, useSelector } from "react-redux";

const AdminSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleButton, SetToggleButton } = useContext(context);
    const [toggle, settoggle] = useState(false);
    const [toggle1, settoggle1] = useState(false);
    const [toggle2, settoggle2] = useState(false);
    const [toggle3, settoggle3] = useState(false);
    const [toggleStates, setToggleStates] = useState({});
    const [sideBarData, setsideBarData] = useState();

    const AdminSidebarMenu = [
        {
            id: 1,
            name: 'Dashboard',
            slug: ['/'],
            icon_color:'triage-icon.svg',
        },
        {
            id: 2,
            name: 'Medical Boards',
            slug: ['/medical-boards','/add-medical-board'],
            icon_color:'screening-icon.svg',
        },
    ]

    console.log('location.pathnameeee', location.pathname)
    return (
        <>
        
            <div className={toggleButton ? "sidebar close" : "sidebar"}>
            <div onClick={() => SetToggleButton((prev) => !prev)}  className="lh-sidebar-toggle-btn">
            {toggleButton ? <ChevronRight /> : <ChevronLeft />}
            </div>
                <div class="logo-details">
                <img onClick={() => navigate("/")} class="lh-sidebar-logo cursor-pointer" width="190" alt="logo" src="../assets/images/logo.svg" />
                <img onClick={() => navigate("/")} class="lh-sidebar-logo small cursor-pointer" width="42" alt="fav-logo" src="../assets/images/fav-logo.svg" />
                </div>
                <ul class="nav-links">
                    {AdminSidebarMenu.map((data,i)=> (
                        <li className={data.slug.includes(location?.pathname) ? "active" : ""}>
                        <a onClick={()=>navigate(`${data.slug[0]}`)}>
                            <img class="lh-sidebar-icon white" alt="notifications" src={`../assets/images/white-${data.icon_color}`} />
                            <img class="lh-sidebar-icon color" alt="notifications" src={`../assets/images/${data.icon_color}`} />
                            <span class="link_name">{data.name}</span>
                        </a>
                        <ul class="sub-menu blank">
                            <li><a class="link_name" onClick={()=>navigate(`${data.slug[0]}`)}>{data.name}</a></li>
                        </ul>
                    </li>
                    ))}
                </ul>
                <div className="lh-carebox-sidebar">
                    <h4 className="font-size-22 black-color fw-bold mb-2">Life Well<br/>Health Care</h4>
                    <p className="font-size-12 black-color mb-2">Publishing Industries for previewing layouts and visual mockups.</p>
                    <p className="font-size-16 black-color fw-bold mb-2">Health Today!</p>
                    <img alt="Life Well" src="../assets/images/medical-heart.svg" />
                </div>
            </div>
        </>
    )
}

export default AdminSidebar