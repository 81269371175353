import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { extraReducers } from "../reducer/userReducer";


axios.defaults.baseURL = process.env.REACT_APP_APIURL;
const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
console.log(user);
axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
const initialState = {
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  user: user,
  event_list: null,
  terms: null,
  privacy: null,
  dashboard: null,
  user_events_tasks: null,
  unread_messages: [],
  socket: null
};

export const signinUser = createAsyncThunk(
  "user/signin",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/signin`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const updatePassword = createAsyncThunk(
  "user/changepassword",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`user/changepassword`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const userProfile = createAsyncThunk(
  "user/profile",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.get(`user/profile`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const updateTerms = createAsyncThunk(
  "terms_and_conditions",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePrivacy = createAsyncThunk(
  "privacy_policy",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/signout",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/signout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllMedicalBoards = createAsyncThunk(
  "user/getallmedicalboards",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getallmedicalboards`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyUsers = createAsyncThunk(
  "user/getcompanyusers",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getcompanyusers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardDoctors = createAsyncThunk(
  "user/getmedicalboarddoctors",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboarddoctors`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardNurses = createAsyncThunk(
  "user/getmedicalboardnurses",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardnurses`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createScreeningLocation = createAsyncThunk(
  "screening/create-screening-location",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-screening-location`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editScreeningLocation = createAsyncThunk(
  "screening/edit-screening-location",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/edit-screening-location`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSScreeningSlots = createAsyncThunk(
  "screening/get-list-of-screening-slots",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-list-of-screening-slots`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllScreeingPlans = createAsyncThunk(
  "screening/get-screening-plans",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-screening-plans`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rescheduleBooking = createAsyncThunk(
  "screening/reschedule-booking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/reschedule-booking`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPatientsScreeningSessions = createAsyncThunk(
  "screening/patient-screening-sessions/:patientId",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/patient-screening-sessions/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScreeningResultList = createAsyncThunk(
  "screening/get-screening-result-list/:sessionId",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-screening-result-list/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const screeningReferalDetail = createAsyncThunk(
  "screening/referal-detail/:sessionId",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/referal-detail/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReferalActionDropdown = createAsyncThunk(
  "screening/get-referal-actions",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-referal-actions`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const listOfCommentsOnReferal = createAsyncThunk(
  "screening/referal-comments/:id",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/referal-comments/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCommentOnReferal = createAsyncThunk(
  "screening/add-comment-on-referal",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/add-comment-on-referal`,bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScreeningPlanDetail = createAsyncThunk(
  "screening/screeing-plan-detail/:id",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('bodyData', bodyData)
      const response = await axios.get(`screening/screeing-plan-detail/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

export const listOfReferals = createAsyncThunk(
  "screening/list-of-referals",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('bodyData', bodyData)
      const response = await axios.get(`screening/list-of-referals`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const createScreeingPod = createAsyncThunk(
  "screening/create-screening-pod",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-screening-pod`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createReferal = createAsyncThunk(
  "screening/create-referal",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-referal`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createScreeningBlock = createAsyncThunk(
  "screening/create-screening-block",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-screening-block`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addScreeningBooking = createAsyncThunk(
  "screening/create-screening-booking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-screening-booking`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScreeningBlockTemplete = createAsyncThunk(
  "screening/get-screening-blocks-templete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-screening-blocks-templete`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editScreeningBlock = createAsyncThunk(
  "screening/edit-screening-block",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/edit-screening-block`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editScreeingPod = createAsyncThunk(
  "screening/edit-screening-pod",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/edit-screening-pod`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createScreeningSlot = createAsyncThunk(
  "screening/create-screening-slots",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/create-screening-slots`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAllScreeningLocations = createAsyncThunk(
  "screening/list-of-screening-location",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/list-of-screening-location`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllScreeningPods = createAsyncThunk(
  "screening/get-list-of-screening-pod",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-list-of-screening-pod`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAlLocations = createAsyncThunk(
  "screening/get-location-dropdown",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/get-location-dropdown`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScreeningSLotsForBooking = createAsyncThunk(
  "screening/get-screening-slots-for-booking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`screening/get-screening-slots-for-booking`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllScreeningBookings = createAsyncThunk(
  "screening/screening-booking-list",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`screening/screening-booking-list`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardClinicManagers = createAsyncThunk(
  "user/getmedicalboardclinicmanagers",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardclinicmanagers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReferralTeams = createAsyncThunk(
  "user/getmedicalboardreferralteams",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardreferralteams`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCallCenterTeams = createAsyncThunk(
  "user/getmedicalboardcallcenterteams",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardcallcenterteams`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardReferralUsers = createAsyncThunk(
  "user/getmedicalboardreferralusers",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardreferralusers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardCallCenterUsers = createAsyncThunk(
  "user/getmedicalboardcallcenterusers",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardcallcenterusers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardClinics = createAsyncThunk(
  "user/getmedicalboardclinics",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardclinics`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCompany = createAsyncThunk(
  "user/addcompany",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/addcompany`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCompany = createAsyncThunk(
  "user/editcompany",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/editcompany`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "user/adduser",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/adduser`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addConsentLegalForms = createAsyncThunk(
  "user/addconsentlegalforms",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/addconsentlegalforms`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFamilyMedicalHistory = createAsyncThunk(
  "user/addfamilyhistory",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/addfamilyhistory`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addMedicalHistory = createAsyncThunk(
  "user/addmedicalhistory",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/addmedicalhistory`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const attachment = createAsyncThunk(
  "user/attachment",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/attachment`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/edituser",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/edituser`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalBoardPatientUsers = createAsyncThunk(
  "user/getmedicalboardpatientusers",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalboardpatientusers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMedicalHistory = createAsyncThunk(
  "user/getmedicalhistory",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getmedicalhistory`, {
        params: bodyData
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getConsentLegalForms = createAsyncThunk(
  "user/getconsentlegalforms",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getconsentlegalforms`, {
        params: bodyData
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFamilyMedicalHistory = createAsyncThunk(
  "user/getfamilyhistory",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getfamilyhistory`, {
        params: bodyData
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const terms = createAsyncThunk(
  "tcandpp/content/getTcPp",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const privacy = createAsyncThunk(
  "tcandpp/content/getTcPp1",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    token: (state) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        state.user = user;
      }
    },
  },
  extraReducers
});

export const { setMessages } = userSlice.actions;

export const getSocket = (state) => state?.users?.socket;
export const getUserStatus = (state) => state?.users?.status;
export const getUserError = (state) => state?.users?.error;
export const getUsertoken = (state) => state?.users?.user?.token;
export const getProfile = (state) => state?.users?.user;
export const getTerms = (state) => state?.users?.terms;
export const getPrivacy = (state) => state?.users?.privacy;
export const getAllReviews = (state) => state?.users?.reviews;
export const getAllCharges = (state) => state?.users?.charges;
export const getDashboard = (state) => state?.users?.dashboard;
export const getUnreadMessagesNotification = (state) => state?.users?.unread_messages;
export const getEventTasks = (state) => state?.users?.user_events_tasks;


export const { token } = userSlice.actions;

export default userSlice.reducer;
