import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout, getProfile } from "../../store/slices/userSlice"

import { context } from '../../context/context';
import { ChevronDown } from 'lucide-react';

const Navbar = () => {
  // const dispatch = useDispatch();
  const { toggleButton, SetToggleButton } = useContext(context);
  const dispatch= useDispatch()
  const navigate = useNavigate();
  const profile=useSelector(getProfile)  

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleLogout=async()=>{
    try {
   
        await dispatch(userLogout()).unwrap()
        navigate("/")
      
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
}





  return (
    <>
      <header className={`${isScrolled ? 'lh-navbar white-bg' : 'lh-navbar'}`}>
        <div className="row px-2 gy-4">
          <div className="col-md-6 d-md-flex align-items-center gap-4 text-center">
            <p className="font-size-24 blue-color mb-0 fw-medium">Greetings, <span className="lh-text-highlight-navbar fw-bold">{profile.firstName}{" "}{profile.lastName}</span></p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
            <img class="" alt="messages" src="./assets/images/notification-icon.svg" />
            <div class="d-flex align-items-center gap-3 lh-profile-img">
              <img class="rounded-circle" alt="avatar" src={profile?.imageName?profile?.imageName:"https://via.placeholder.com/65x65"} />
              {/* <p className="font-size-16 blue-color mb-0 fw-semibold">Dr. John Cooper</p> */}
            </div>
            <div class="dropdown lh-nav-dropdown-link">
              <a class="blue-color" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <ChevronDown />
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item">Profile Setting</a></li>
                <li onClick={handleLogout}><a class="dropdown-item">Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar