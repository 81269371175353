import { Activity, Pill, Stethoscope, UserRound } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import {
  addFamilyMedicalHistory,
  getFamilyMedicalHistory
} from "../../../store/slices/userSlice";

const FamilyMedicalHistory = ({patientId}) => {

  const dispatch= useDispatch()
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [data, setdata] = useState([]);
  const [load, setload] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [illnessName, setIllnessName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [dateOfDiagnosis, setDateOfDiagnosis] = useState('');
  const [severityStage, setSeverityStage] = useState('');
  const [initialTreatmentDate, setInitialTreatmentDate] = useState('');
  const [initialTreatmentName, setInitialTreatmentName] = useState('');
  const [followUpAdjustmentsDate, setFollowUpAdjustmentsDate] = useState('');
  const [followUpAdjustmentsName, setFollowUpAdjustmentsName] = useState('');
  const [specialistConsultationsDate, setSpecialistConsultationsDate] = useState('');
  const [specialistConsultationsName, setSpecialistConsultationsName] = useState('');
  const [complicationsDate, setComplicationsDate] = useState('');
  const [complicationsName, setComplicationsName] = useState('');
  const [currentStatusDate, setCurrentStatusDate] = useState('');
  const [currentStatusName, setCurrentStatusName] = useState('');



  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };


 



const AllForms = async () => {
  try {
    setload(true);
    setdata(null);
    setfilteredData(null);

    const response = await dispatch(
      getFamilyMedicalHistory({id: patientId})
    ).unwrap();

    console.log("response.data", response.data);
    setdata(response?.data);
    setfilteredData(response?.data);
    if(response?.data?.length<1){
      setIsFormVisible(true)
    }
    setload(false);
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
};


useEffect(() => {
  let mount = true;
  if (mount) {
    AllForms();
  }
  return () => {
    mount = false;
  };
}, [dispatch]);


const handleSubmit = async (e) => {

  try {
e.preventDefault()
  
  const formData = {
    userId: patientId,
    illnessName: illnessName,
    relationship:relationship,
    dateOfDiagnosis:dateOfDiagnosis,
    severityStage:severityStage,
    initialTreatment: {
      date: initialTreatmentDate,
      name: initialTreatmentName,
    },
    followUpAdjustments: {
      date: followUpAdjustmentsDate,
      name: followUpAdjustmentsName,
    },
    specialistConsultations: {
      date: specialistConsultationsDate,
      name: specialistConsultationsName,
    },
    complications: {
      date: complicationsDate,
      name: complicationsName,
    },
    currentStatus: {
      date: currentStatusDate,
      name: currentStatusName,
    },
  };
  
  


  await dispatch(addFamilyMedicalHistory(formData)).unwrap();
  setIsFormVisible(false)
   setIllnessName('');
   setRelationship('');
   setDateOfDiagnosis('');
   setSeverityStage('');
   setInitialTreatmentDate('');
   setInitialTreatmentName('');
   setFollowUpAdjustmentsDate('');
   setFollowUpAdjustmentsName('');
   setSpecialistConsultationsDate('');
   setSpecialistConsultationsName('');
   setComplicationsDate('');
   setComplicationsName('');
   setCurrentStatusDate('');
   setCurrentStatusName('');
  
   AllForms();


  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}

};



  const Doctorslist =[
    {
        id: 1 ,
        name:'None',
        date:'24-12-2992',
        Injuries_Sustained: '24-12-2992',
        Initial_Treatment:'General Consent for Treatment',
        Followup_Treatment:'ddd',
        Recovery:'dd',
        Provider:'dd'
    },
    {
      id: 1 ,
      name:'None',
      date:'24-12-2992',
      Injuries_Sustained: '24-12-2992',
      Initial_Treatment:'General Consent for Treatment',
      Followup_Treatment:'ddd',
      Recovery:'dd',
      Provider:'dd'
  },
  {
    id: 1 ,
    name:'None',
    date:'24-12-2992',
    Injuries_Sustained: '24-12-2992',
    Initial_Treatment:'General Consent for Treatment',
    Followup_Treatment:'ddd',
    Recovery:'dd',
    Provider:'dd'
},
{
  id: 1 ,
  name:'None',
  date:'24-12-2992',
  Injuries_Sustained: '24-12-2992',
  Initial_Treatment:'General Consent for Treatment',
  Followup_Treatment:'ddd',
  Recovery:'dd',
  Provider:'dd'
},
]
  return (
    <>
    <div className="d-flex align-items-center justify-content-between">
    <p className="font-size-20 blue-color">Family Medical History</p>
    <button class="btn lh-btn lh-blue-btn px-3 additional-info-add" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button>
    </div> 

    {isFormVisible && (
<>
<div className="row gy-3 mb-4">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Illness-Name" class="form-label">Illness Name</label>
        <input value={illnessName} onChange={(e) => setIllnessName(e.target.value)} type="text" class="form-control" id="Illness-Name" placeholder="Illness Name" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Relationship" class="form-label">Relationship</label>
        <input value={relationship} onChange={(e) => setRelationship(e.target.value)} type="text" class="form-control" id="Relationship" placeholder="Relationship" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Date-Of-Diagnosis" class="form-label">Date Of Diagnosis</label>
        <input value={dateOfDiagnosis} onChange={(e) => setDateOfDiagnosis(e.target.value)} type="date" class="form-control" id="Date-Of-Diagnosis" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Severity/Stage" class="form-label">Severity/Stage</label>
        <input value={severityStage} onChange={(e) => setSeverityStage(e.target.value)} type="text" class="form-control" id="Severity/Stage" placeholder="2024" />
    </div>
    </div>
    </div>
    <p className="font-size-20 blue-color poppins-font fw-semibold">Initial Treatment</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Initial-Treatment-date" class="form-label">Initial Treatment Date</label>
        <input value={initialTreatmentDate} onChange={(e) => setInitialTreatmentDate(e.target.value)} type="date" class="form-control" id="Initial-Treatment-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Initial-Treatment-Name" class="form-label">Initial Treatment Name</label>
        <input value={initialTreatmentName} onChange={(e) => setInitialTreatmentName(e.target.value)} type="text" class="form-control" id="Initial-Treatment-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Follow up Adjustments</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Follow-up-Adjustments-date" class="form-label">Follow up Adjustments Date</label>
        <input value={followUpAdjustmentsDate} onChange={(e) => setFollowUpAdjustmentsDate(e.target.value)} type="date" class="form-control" id="Follow-up-Adjustments-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Follow-up-Adjustments-Name" class="form-label">Follow up Adjustments Name</label>
        <input value={followUpAdjustmentsName} onChange={(e) => setFollowUpAdjustmentsName(e.target.value)} type="text" class="form-control" id="Follow-up-Adjustments-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Specialist Consultations</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Specialist-Consultations-date" class="form-label">Specialist Consultations Date</label>
        <input value={specialistConsultationsDate} onChange={(e) => setSpecialistConsultationsDate(e.target.value)} type="date" class="form-control" id="Specialist-Consultations-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Specialist-Consultations-Name" class="form-label">Specialist Consultations Name</label>
        <input value={specialistConsultationsName} onChange={(e) => setSpecialistConsultationsName(e.target.value)} type="text" class="form-control" id="Specialist-Consultations-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Complications</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Complications-date" class="form-label">Complications Date</label>
        <input value={complicationsDate} onChange={(e) => setComplicationsDate(e.target.value)} type="date" class="form-control" id="Complications-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Complications-Name" class="form-label">Complications Name</label>
        <input value={complicationsName} onChange={(e) => setComplicationsName(e.target.value)} type="text" class="form-control" id="Complications-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Current Status</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Current-Status-date" class="form-label">Current Status Date</label>
        <input value={currentStatusDate} onChange={(e) => setCurrentStatusDate(e.target.value)} type="date" class="form-control" id="Current-Status-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Current-Status-Name" class="form-label">Current Status Name</label>
        <input value={currentStatusName} onChange={(e) => setCurrentStatusName(e.target.value)} type="text" class="form-control" id="Current-Status-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <div className="text-center">
    <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
</>)}

{filteredData?.length>0&&!isFormVisible&&(<div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Illness Name</th>
      <th scope="col">Relationship</th>
      <th scope="col">Date Of Diagnosis</th>
      <th scope="col">Severity/Stage</th>
      <th scope="col">Initial Treatment</th>
      <th scope="col">Follow up Adjustments</th>
      <th scope="col">Specialist Consultations</th>
      <th scope="col">Complications</th>
      <th scope="col">Current Status</th>
      {/* <th style={{width:'100px'}} scope="col">Actions</th> */}
    </tr>
  </thead>
  <tbody>
  {filteredData?.map((d,i)=> (
    <tr>
      <td>{d.illnessName}</td>
      <td>{d.relationship}</td>
      <td>{moment(d.dateOfDiagnosis).format("MMM DD YYYY")}</td>
      <td>{d.severityStage}</td>
      <td>{d.initialTreatment?.name}</td>
      <td>{d.followUpAdjustments?.name}</td>
      <td>{d.specialistConsultations?.name}</td>
      <td>{d.complications?.name}</td>
      <td>{d.currentStatus?.name}</td>
      {/* <td>
        <div className="d-flex align-items-center gap-2">
            <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div>
        </div>
      </td> */}
    </tr>
  ))}
  </tbody>
    </table>
    </div>
 
</div>)}
    </>
  )
}

export default FamilyMedicalHistory