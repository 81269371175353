import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';



const MenstrualFemale = ({state,patientId,dataa,handleData,removeData}) => {

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [menarcheAge, setmenarcheAge] = useState('');
  const [frequency, setfrequency] = useState('');
  const [lastMenstrualPeriod, setlastMenstrualPeriod] = useState('');
  const [cycleDays, setcycleDays] = useState('');

  


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };

  const handleSubmit = async (e) => {

    try {
     e.preventDefault()
  
   const formData = {
        id: uuidv4(),
        menarcheAge:menarcheAge,
        frequency:frequency,
        lastMenstrualPeriod:lastMenstrualPeriod,
        cycleDays:cycleDays,
        type: state,
      } 
    
    
      
    handleData(formData)

      setIsFormVisible(false)
      setmenarcheAge('')
      setfrequency('')
      setlastMenstrualPeriod('')
      setcycleDays('')
  

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  };



  return (
    <>
      <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
      <p className="font-size-20 blue-color">Menstrual (Female only)<button class=" btn lh-btn lh-blue-btn px-3" style={{ "marginLeft": "1200px" }} onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button></p>
    
    
    
      {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Menarche-Age" class="form-label">Menarche Age</label>
        <input onChange={(e)=>setmenarcheAge(e.target.value)} value={menarcheAge} type="text" class="form-control" id="Menarche-Age" placeholder="Menarche Age" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Frequency" class="form-label">Frequency</label>
        <div className="d-flex gap-2">
            <input onChange={()=>setfrequency("regular")} checked={frequency=="regular"} type="radio" name="Frequency" class="btn-check" id={`select-regular`}/>
            <label class="btn lh-button-select" for={`select-regular`}>Regular</label>

            <input onChange={()=>setfrequency("irregular")} value={frequency=="irregular"} type="radio" name="Frequency" class="btn-check" id={`select-irregular`}/>
            <label class="btn lh-button-select" for={`select-irregular`}>Irregular</label>
            </div>
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Cycle-Days" class="form-label">Cycle Days</label>
        <input onChange={(e)=>setcycleDays(e.target.value)} value={cycleDays} type="text" class="form-control" id="Cycle-Days" placeholder="Cycle Days" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Last-Menstrual-Period" class="form-label">Last Menstrual Period</label>
        <input onChange={(e)=>setlastMenstrualPeriod(e.target.value)} value={lastMenstrualPeriod} type="text" class="form-control" id="Last-Menstrual-Period" placeholder="Last Menstrual Period" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </>)}

    {dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Menarche Age</th>
      <th scope="col">Frequency</th>
      <th scope="col">Cycle Days</th>
      <th scope="col">Last Menstrual Period</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.menarcheAge}</td>
      <td>{d.frequency}</td>
      <td>{d.cycleDays}</td>
      <td>{d.lastMenstrualPeriod}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}

          
</div>
    </>
  )
}

export default MenstrualFemale