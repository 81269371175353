import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Spinner from "./Spinner";
import Login from "../../pages/Authentication/Login";
import RequireAuth from "../../pages/Authentication/RequireAuth";
import UnAuthorized from "../../pages/Authentication/UnAuthorized";
import { useSelector } from "react-redux";
import {
  getUserStatus,
  getUsertoken,
  getProfile,
} from "../../store/slices/userSlice";
// import TermsAndConditions from "../../pages/Authentication/TermsAndConditions";
// import PrivacyPolicy from "../../pages/Authentication/PrivacyPolicy";
import { context } from "../../context/context";
import Navbar from "./Navbar";
import MedicalBoardSidebar from "./Sidebars/MedicalBoardSidebar";



import AdminSidebar from "./Sidebars/AdminSidebar";
import AdminDashboard from "../../pages/Admin/AdminDashboard";
import AddMedicalBoard from "../../pages/Admin/AddMedicalBoard";
import MedicalBoards from "../../pages/Admin/MedicalBoards";


import AddUser from '../../pages/MedicalBoard/AddUser';
import MedicalBoardDashboard from '../../pages/MedicalBoard/MedicalBoardDashboard';
import MedicalBoardUser from '../../pages/MedicalBoard/MedicalBoardModule/MedicalBoardUser';
import AddMedicalBoardUser from '../../pages/MedicalBoard/MedicalBoardModule/AddMedicalBoardUser';




import AddDoctorUser from '../../pages/MedicalBoard/ClinicSurgeryModule/AddDoctorUser';
import ClinicSurgery from '../../pages/MedicalBoard/ClinicSurgeryModule/ClinicSurgery';
import AddClinicSurgery from '../../pages/MedicalBoard/ClinicSurgeryModule/AddClinicSurgery';
import Doctors from '../../pages/MedicalBoard/ClinicSurgeryModule/Doctors';
import Nurses from '../../pages/MedicalBoard/ClinicSurgeryModule/Nurses';
import AddNurses from '../../pages/MedicalBoard/ClinicSurgeryModule/AddNurses';
import ClinicMangers from '../../pages/MedicalBoard/ClinicSurgeryModule/ClinicMangers';
import AddClinicManger from '../../pages/MedicalBoard/ClinicSurgeryModule/AddClinicManger';




import CallCenters from '../../pages/MedicalBoard/CallCenterModule/CallCenters';
import AddCallCenter from '../../pages/MedicalBoard/CallCenterModule/AddCallCenter';
import AddCallCenterUser from '../../pages/MedicalBoard/CallCenterModule/AddCallCenterUser';
import CallCenterUsers from '../../pages/MedicalBoard/CallCenterModule/CallCenterUsers';





import Patient from '../../pages/MedicalBoard/PatientModule/Patient';
import PatientDetail from '../../pages/MedicalBoard/PatientModule/PatientDetail';
import AddPatient from '../../pages/MedicalBoard/PatientModule/AddPatient';


import ReferalTeams from '../../pages/MedicalBoard/ReferralTeamModule/ReferalTeams';
import ReferalTeamUsers from '../../pages/MedicalBoard/ReferralTeamModule/ReferalTeamUsers';
import AddReferalTeam from '../../pages/MedicalBoard/ReferralTeamModule/AddReferalTeam';
import AddReferalTeamUser from '../../pages/MedicalBoard/ReferralTeamModule/AddReferalTeamUser';


import Triage from '../../pages/MedicalBoard/ScreeningModule/Triage';
import Bookings from '../../pages/MedicalBoard/ScreeningModule/Bookings';
import Outcomes from '../../pages/MedicalBoard/ScreeningModule/Outcomes';
import AddScreeningSlots from '../../pages/MedicalBoard/ScreeningModule/AddScreeningSlots';
import AddScreeningPod from '../../pages/MedicalBoard/ScreeningModule/AddScreeningPod';
import AddScreeningLocation from '../../pages/MedicalBoard/ScreeningModule/AddScreeningLocation';
import ScreeningLocations from '../../pages/MedicalBoard/ScreeningModule/ScreeningLocations';
import ScreeningPods from '../../pages/MedicalBoard/ScreeningModule/ScreeningPods';
import ScreeningSlots from '../../pages/MedicalBoard/ScreeningModule/ScreeningSlots';
import ScreeningPlans from "../../pages/MedicalBoard/ScreeningModule/ScreeningPlans";
import AddScreeningPlan from "../../pages/MedicalBoard/ScreeningModule/AddScreeningPlan";
import AddBooking from "../../pages/MedicalBoard/ScreeningModule/AddBooking";
import BookingDetails from "../../pages/MedicalBoard/ScreeningModule/BookingDetails";
import ScreeningDetails from "../../pages/MedicalBoard/ScreeningModule/ScreeningDetails";
import Referrals from "../../pages/MedicalBoard/ReferralsModule/Referrals";
import ReferralDetails from "../../pages/MedicalBoard/ReferralsModule/ReferralDetail";





const Layout = () => {
  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus);
  const authtoken = useSelector(getUsertoken);
  const profile = useSelector(getProfile);

  return (
    <>
      {status === "loading" || isLoading
        ? <Spinner />
        : null}

      <BrowserRouter>
        <Content authtoken={authtoken} profile={profile} />
      </BrowserRouter>
    </>
  );
};

const Content = ({ authtoken, profile }) => {
  var dash = "";
  const location = useLocation();
  const p = location.pathname.split("/");
  const p1 = p[p.length - 1];

  for (var i = p1.length - 1; i >= 0; i--) {
    if (p1[i] == "_") {
      console.log("trueeeeeeeee");
      dash = i;
      break;
    }
  }

  const t = decodeURIComponent(p1.replace(/-/g, " "));

  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    document.title =
      location.pathname == "/" && !authtoken
        ? "Login" : location.pathname == "/" && authtoken
          ? "Dashboard"
          : dash !== ""
            ? capitalizeEachWord(t.substring(0, dash))
            : capitalizeEachWord(t);
  }, [dash, t]);



  return (
    <>
      {authtoken ? (
        <>
          {profile?.role == "admin" ? (
            <>
              <main className="dashboard">
                <AdminSidebar />
                <>
                  <Navbar />
                  <section className="content-section">
                    <Routes>
                      <Route path="/" element={<RequireAuth allowedRoles={["admin"]}><AdminDashboard /></RequireAuth>} />
                      <Route path="/add-medical-board" element={<RequireAuth allowedRoles={["admin"]}><AddMedicalBoard /></RequireAuth>} />
                      <Route path="/medical-boards" element={<RequireAuth allowedRoles={["admin"]}><MedicalBoards /></RequireAuth>} />
                    </Routes>
                  </section>
                </>
              </main>
            </>
          ) : profile?.role == "medicalBoard" ? (
            <>
              <main className="dashboard">
                <MedicalBoardSidebar />
                <>
                  <Navbar />
                  <section className="content-section">
                    <Routes>

                      <Route path="/" element={<RequireAuth allowedRoles={["medicalBoard"]}><MedicalBoardDashboard /></RequireAuth>} />
                      <Route path="/clinic-surgery" element={<RequireAuth allowedRoles={["medicalBoard"]}><ClinicSurgery /></RequireAuth>} />
                      <Route path="/medical-board-user" exact element={<RequireAuth allowedRoles={["medicalBoard"]}><MedicalBoardUser /></RequireAuth>} />
                      <Route path="/add-medical-board-user" exact element={<RequireAuth allowedRoles={["medicalBoard"]}><AddMedicalBoardUser /></RequireAuth>} />
                      <Route path="/add-clinic-surgery" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddClinicSurgery /></RequireAuth>} />
                      <Route path="/doctors" element={<RequireAuth allowedRoles={["medicalBoard"]}><Doctors /></RequireAuth>} />
                      <Route path="/add-doctor" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddDoctorUser /></RequireAuth>} />
                      <Route path="/nurses" element={<RequireAuth allowedRoles={["medicalBoard"]}><Nurses /></RequireAuth>} />
                      <Route path="/add-nurse" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddNurses /></RequireAuth>} />
                      <Route path="/clinic-managers" element={<RequireAuth allowedRoles={["medicalBoard"]}><ClinicMangers /></RequireAuth>} />
                      <Route path="/add-clinic-manager" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddClinicManger /></RequireAuth>} />
                      <Route path="/call-centers" element={<RequireAuth allowedRoles={["medicalBoard"]}><CallCenters /></RequireAuth>} />
                      <Route path="/add-call-center" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddCallCenter /></RequireAuth>} />
                      <Route path="/call-center-users" element={<RequireAuth allowedRoles={["medicalBoard"]}><CallCenterUsers /></RequireAuth>} />
                      <Route path="/add-call-center-user" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddCallCenterUser /></RequireAuth>} />
                      <Route path="/referral-teams" element={<RequireAuth allowedRoles={["medicalBoard"]}><ReferalTeams /></RequireAuth>} />
                      <Route path="/add-referral-team" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddReferalTeam /></RequireAuth>} />
                      <Route path="/referral-team-users" element={<RequireAuth allowedRoles={["medicalBoard"]}><ReferalTeamUsers /></RequireAuth>} />
                      <Route path="/add-referral-team-user" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddReferalTeamUser /></RequireAuth>} />
                      <Route path="/patients" element={<RequireAuth allowedRoles={["medicalBoard"]}><Patient /></RequireAuth>} />
                      <Route path="/add-patient" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddPatient /></RequireAuth>} />
                      <Route path="/patient-detail" element={<RequireAuth allowedRoles={["medicalBoard"]}><PatientDetail /></RequireAuth>} />
                      <Route path="/screening-plans" element={<RequireAuth allowedRoles={["medicalBoard"]}><ScreeningPlans /></RequireAuth>} />
                      <Route path="/add-screening-plan" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddScreeningPlan /></RequireAuth>} />
                      <Route path="/add-screening-slot" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddScreeningSlots /></RequireAuth>} />
                      <Route path="/add-screening-pod" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddScreeningPod /></RequireAuth>} />
                      <Route path="/add-screening-location" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddScreeningLocation /></RequireAuth>} />
                      <Route path="/screening-locations" element={<RequireAuth allowedRoles={["medicalBoard"]}><ScreeningLocations /></RequireAuth>} />
                      <Route path="/screening-pods" element={<RequireAuth allowedRoles={["medicalBoard"]}><ScreeningPods /></RequireAuth>} />
                      <Route path="/screening-slots" element={<RequireAuth allowedRoles={["medicalBoard"]}><ScreeningSlots /></RequireAuth>} />
                      <Route path="/screening-detail" element={<RequireAuth allowedRoles={["medicalBoard"]}><ScreeningDetails /></RequireAuth>} />
                      <Route path="/bookings" element={<RequireAuth allowedRoles={["medicalBoard"]}><Bookings /></RequireAuth>} />
                      <Route path="/add-booking" element={<RequireAuth allowedRoles={["medicalBoard"]}><AddBooking /></RequireAuth>} />
                      <Route path="/booking-details" element={<RequireAuth allowedRoles={["medicalBoard"]}><BookingDetails /></RequireAuth>} />
                      <Route path="/referrals" element={<RequireAuth allowedRoles={["medicalBoard"]}><Referrals /></RequireAuth>} />
                      <Route path="/referral-detail" element={<RequireAuth allowedRoles={["medicalBoard"]}><ReferralDetails /></RequireAuth>} />

                    </Routes>
                  </section>
                </>
              </main>
            </>
          ) : profile?.role == "doctor" ? (
            <>
              <main className="dashboard">
             
                <>
                
                  <Routes>
              
                  </Routes>
                </>
              </main>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" exact element={<Login />} />
            <Route path="/unauthorized" element={<UnAuthorized />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default Layout;
