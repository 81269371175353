import React, { useState } from 'react';
import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';
import moment from 'moment'



const ImmunizationRecords = ({state,patientId,dataa,handleData,removeData}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [vaccineName, setVaccineName] = useState('');
  const [doseNumber, setDoseNumber] = useState('');
  const [doseDate, setDoseDate] = useState('');
  const [provider, setProvider] = useState('');
  const [notes, setNotes] = useState('');

  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };

  const handleSubmit = (e) => {

    try {
    e.preventDefault()

    const formData = {
      id: uuidv4(),
      vaccineName: vaccineName,
      doseNumber: doseNumber,
      doseDate: doseDate,
      provider: provider,
      notes: notes,
      type: state,
    };

    handleData(formData)

    // Reset form fields
    setVaccineName('');
    setDoseNumber('');
    setDoseDate('');
    setProvider('');
    setNotes('');

    setIsFormVisible(false);

  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };



  return (
    <>
      <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
      <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Immunization Records<button className="btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible ? 'Close' : 'Add'}</button>
        </p></div>

        {isFormVisible && (
          <>
            <div className="row gy-3 mb-4">
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Vaccine-Name" className="form-label">
                    Vaccine Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Vaccine-Name"
                    placeholder="Vaccine Name"
                    value={vaccineName}
                    onChange={(e) => setVaccineName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Dose-number" className="form-label">
                    Dose No.
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="Dose-number"
                    placeholder="Dose No."
                    value={doseNumber}
                    onChange={(e) => setDoseNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Dose-date" className="form-label">
                    Dose date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="Dose-date"
                    placeholder="Dose date"
                    value={doseDate}
                    onChange={(e) => setDoseDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="provider" className="form-label">
                    Provider
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="provider"
                    placeholder="Provider"
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="lh-input2">
                  <label htmlFor="Notes" className="form-label">
                    Notes
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Notes"
                    placeholder="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="text-center">
              <button onClick={(e)=>handleSubmit(e)} className="btn lh-btn lh-blue-btn px-4">
                Save
              </button>
            </div>
          </>
        )}

{dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive"> 
<table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Vaccine Name</th>
      <th scope="col">Dose No.</th>
      <th scope="col">Dose date</th>
      <th scope="col">Notes</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.vaccineName}</td>
      <td>{d.doseNumber}</td>
      <td>{moment(d.doseDate).format("MMM DD YYYY")}</td>
      <td>{d.notes}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
    </div>
    </>
  )
}

export default ImmunizationRecords