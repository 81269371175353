
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../store/slices/userSlice";
import { useSelector } from "react-redux";

const RequireAuth = ({ user, allowedRoles, children }) => {
  const navigate = useNavigate()

  const profile = useSelector(getProfile);


  if (profile && allowedRoles?.includes(profile.role)) {
    return children
  }
  else {
    return navigate("/unauthorized")
  }
};

export default RequireAuth;