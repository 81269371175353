import React, { useEffect, useState } from "react";
import { Camera, FileUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  editUser,
  getReferralTeams
} from "../../../store/slices/userSlice";

const AddReferalTeamUser = () => {
    const dispatch= useDispatch()
    const navigate = useNavigate();
    const location = useLocation();


     // Medical Board User (Manager) Form State
  const [referralteams, setreferralteams] = useState([]);
  const [load, setload] = useState(false);
  const [id, setid] = useState(location.state?location.state.data.referralTeamId._id:"");
  const [userid, setuserid] = useState(location.state?location.state.data._id:"");
  const [selectedreferralteamName, setselectedreferralteamName] = useState(location.state?location.state.data.referralTeamId.name:"");
  const [type, settype] = useState(location.state?location.state.type:"add");
  const [abc, setabc] = useState(false);
  const [imageName, setimageName] = useState(location.state?location.state.data.imageName:"");
  const [firstName, setFirstName] = useState(location.state?location.state.data.firstName:"");
  const [lastName, setLastName] = useState(location.state?location.state.data.lastName:"");
  const [emailUser, setEmailUser] = useState(location.state?location.state.data.email:"");
  const [dateOfBirth, setDateOfBirth] = useState(location.state?location.state.data.dateOfBirth:"");
  const [nationalIdNumber, setNationalIdNumber] = useState(location.state?location.state.data.nationalIDNumber:"");
  const [phoneUser, setPhoneUser] = useState(location.state?location.state.data.phone:"");
  const [gender, setGender] = useState(location.state?location.state.data.gender:""); 

  // Address State
  const [addressUser, setAddressUser] = useState(location?.state?.data?.address?location.state.data.address.line1:"");
  const [addressUser2, setAddressUser2] = useState(location?.state?.data?.address?location.state.data.address.line2:"");
  const [cityUser, setCityUser] = useState(location?.state?.data?.address?location.state.data.address.city:"");
  const [countryUser, setCountryUser] = useState(location?.state?.data?.address?location.state.data.address.country:"");
  const [postcodeUser, setPostcodeUser] = useState(location?.state?.data?.address?location.state.data.address.postalCode:"");

  // Emergency Contact Information State
  const [emergencyContactName, setEmergencyContactName] = useState(location?.state?.data?.emergency?location.state.data.emergency.name:"");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState(location?.state?.data?.emergency?location.state.data.emergency.relation:"");
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(location?.state?.data?.emergency?location.state.data.emergency.phone:"");

  const handleSubmit = async (e) => {

    try {
    e.preventDefault()
    const formData = new FormData();

    
    
    // Medical Board User (Manager) Form State
    
    formData.append("id", id);
    formData.append("userid", userid);
    formData.append("type", "referralTeam");
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", emailUser);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("nationalIDNumber", nationalIdNumber);
    formData.append("phone", phoneUser);
    formData.append("gender", gender);
    formData.append("imageName", imageName);
    formData.append("role", "referralTeam");

    
    formData.append("addressLine1", addressUser);
    formData.append("addressLine2", addressUser2);
    formData.append("city", cityUser);
    formData.append("country", countryUser);
    formData.append("postalCode", postcodeUser);

 
    formData.append("emergencyName", emergencyContactName);
    formData.append("emergencyRelation", emergencyContactRelation);
    formData.append("emergencyPhone", emergencyContactPhone);




    if (type=="add") {
        await dispatch(
          addUser(formData)
        ).unwrap();
      } 
      
      else {
        await dispatch(
          editUser(formData)
        ).unwrap();
      }
  
      navigate("/referral-team-users");

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
    };



    const AllMedicalBoardReferralTeams = async () => {
        try {
          setload(true);
          setreferralteams(null);
         
    
          const response = await dispatch(
            getReferralTeams()
          ).unwrap();
    
          console.log("response.data", response.data);
          setreferralteams(response?.data);
          setload(false);
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

      
      useEffect(() => {
        let mount = true;
        if (mount) {
            AllMedicalBoardReferralTeams();
        }
        return () => {
          mount = false;
        };
      }, [dispatch]);


  return (
    <>
    <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/referral-team-users")}>Referral Team User</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Referral Team User</li>
        </ol>
    </nav>
    <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Referral Team User</h2>
    <div className="lh-radius-20 white-bg lh-shadow p-4 mb-3">
        <div className="row mb-3">
        <div className="col-md-2">
            <div class="lh-input2">
            <label for="imageUpload" class="form-label">Profile Photo</label>
            </div>
                <div className="lh-profile-upload">
                <div class="avatar-upload">
                <div class="avatar-edit">
                    <input onChange={(e) => {setabc(true); setimageName(e.target.files[0])}} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                    <label for="imageUpload"><Camera width={18} strokeWidth={1} /> Upload</label>
                </div>
                <div class="avatar-preview">
                {imageName && <img id="output" src={!abc?imageName:URL.createObjectURL(imageName)} alt="" />}
                </div>
            </div>
                </div>
            </div>
            <div className="col-md-5">
            {/* <div class="mb-3 lh-input2">
            <label for="uid" class="form-label">UID</label>
            <input disabled value={location?.state?.data?.uid} type="text" class="form-control" id="uid" placeholder="A2434SFDF" />
            </div> */}
            <div class="mb-3 lh-input2">
            <label for="First-Name" class="form-label">First Name</label>
            <input defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" class="form-control" id="First-Name" placeholder="John" />
            </div>
            <div class="mb-3 lh-input2">
            <label for="Email" class="form-label">Email</label>
            <input defaultValue={emailUser} onChange={(e) => setEmailUser(e.target.value)} type="email" class="form-control" id="Email" placeholder="john@lifewell.health" />
            </div>
            <div class="mb-3 lh-input2">
            <label for="Date-of-Birth" class="form-label">Date of Birth</label>
            <input defaultValue={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} type="date" class="form-control" id="Date-of-Birth" placeholder="1/12/2024" />
            </div>
            <div class="mb-3 lh-input2">
            <label class="form-label">Gender</label>
            <div className="d-flex gap-2">
            <input checked={gender=="male"} onChange={() => setGender("male")} type="radio" name="gender" class="btn-check" id="male"/>
            <label class="btn lh-button-select" for="male">Male</label>

            <input checked={gender=="female"} onChange={() => setGender("female")} type="radio" name="gender" class="btn-check" id="female"/>
            <label class="btn lh-button-select" for="female">Female</label>
            </div>
            </div>
            </div>
            <div className="col-md-5">
            <div class="mb-3 lh-input2">
            <label for="Last-Name" class="form-label">Last Name</label>
            <input defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} type="text" class="form-control" id="Last-Name" placeholder="Marshell" />
            </div>
            <div class="mb-3 lh-input2">
            <label for="National-ID-Number" class="form-label">National ID Number</label>
            <input defaultValue={nationalIdNumber} onChange={(e) => setNationalIdNumber(e.target.value)} type="text" class="form-control" id="National-ID-Number" placeholder="A2434SFDF" />
            </div>
            <div class="mb-3 lh-input2">
            <label for="Phone" class="form-label">Phone</label>
            <input defaultValue={phoneUser} onChange={(e) => setPhoneUser(e.target.value)} type="tel" class="form-control" id="Phone" placeholder="+967 354 3644" />
            </div>
            <div className="col-md-4">
        <div class="lh-input2">
            <label class="form-label">Referral Teams</label>
        </div>
        <div class="dropdown lh-select-dropdown">
            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedreferralteamName?selectedreferralteamName:"Referral Team"}
            </a>
            <ul class="dropdown-menu">
            {referralteams?.map((data,i)=> (
                <>
                <li onClick={()=>{setid(data._id);setselectedreferralteamName(data.name)}} key={i}><a class="dropdown-item">{data.name}</a></li>
                </>
            ))}
            </ul>
        </div>
        </div>
            
            
            </div>
        </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Address</p>
    <div className="row gy-3 mb-4">
        <div className="col-md-12">
        <div class="lh-input2">
            <label for="Address" class="form-label">Address 1</label>
            <input defaultValue={addressUser} onChange={(e) => setAddressUser(e.target.value)} type="text" class="form-control" id="Address" placeholder="Pod 1, Greenwood Clinic" />
        </div>
        </div>
        <div className="col-md-12">
        <div class="lh-input2">
            <label for="Address" class="form-label">Address 2</label>
            <input defaultValue={addressUser2} onChange={(e) => setAddressUser2(e.target.value)} type="text" class="form-control" id="Address" placeholder="Pod 1, Greenwood Clinic" />
        </div>
        </div>
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="City" class="form-label">City</label>
            <input defaultValue={cityUser} onChange={(e) => setCityUser(e.target.value)} type="text" class="form-control" id="City" placeholder="Riyadh" />
        </div>
        </div>
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="Country" class="form-label">Country</label>
            <input defaultValue={countryUser} onChange={(e) => setCountryUser(e.target.value)}type="text" class="form-control" id="Country" placeholder="Saudi Arabia" />
        </div>
        </div>
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="Postcode" class="form-label">Postcode</label>
            <input defaultValue={postcodeUser} onChange={(e) => setPostcodeUser(e.target.value)} type="text" class="form-control" id="Postcode" placeholder="KWF101" />
        </div>
        </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Emergency Contact Information</p>
    <div className="row gy-3">
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="Name" class="form-label">Name</label>
            <input defaultValue={emergencyContactName} onChange={(e) => setEmergencyContactName(e.target.value)} type="text" class="form-control" id="Name" placeholder="Mr. Cook" />
        </div>
        </div>
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="Relation" class="form-label">Relation</label>
            <input defaultValue={emergencyContactRelation} onChange={(e) => setEmergencyContactRelation(e.target.value)} type="text" class="form-control" id="Relation" placeholder="Father" />
        </div>
        </div>
        <div className="col-md-4">
        <div class="lh-input2">
            <label for="Phone" class="form-label">Phone</label>
            <input defaultValue={emergencyContactPhone} onChange={(e) => setEmergencyContactPhone(e.target.value)} type="tel" class="form-control" id="Phone" placeholder="+967 354 3644" />
        </div>
        </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </div>
    </>
  )
}

export default AddReferalTeamUser