import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { screeningReferalDetail, getReferalActionDropdown, addCommentOnReferal, listOfCommentsOnReferal, getProfile } from '../../../store/slices/userSlice';
import moment from 'moment';
import toast from 'react-hot-toast';

const ReferralDetail = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const profile=useSelector(getProfile)


    const [load, setload] = useState(false);
    const selectedPatient= location?.state?.patientDetails
    const [referralDetail, setReferralDetail] = useState([]);

    const [actions, setactions] = useState([]);
    const [comments, setcomments] = useState([]);
    const [selectedaction, setselectedaction] = useState({});
    const [comment, setcomment] = useState("");
    const [active, setactive] = useState(location?.state?.isActive);



    const ReferralDetails = async () => {
        try {
            setload(true);
            setReferralDetail(null);


            const response = await dispatch(
                screeningReferalDetail(location?.state?.id)
            ).unwrap();

            setReferralDetail(response.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const ReferalActionDropdown = async () => {
        try {
            setload(true);
            setactions(null);


            const response = await dispatch(
                getReferalActionDropdown()
            ).unwrap();

            setactions(response.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const listOfComments = async () => {
        try {
            setload(true);
            setcomments(null);


            const response = await dispatch(
                listOfCommentsOnReferal(location?.state?.id)
            ).unwrap();

            setcomments(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    
    useEffect(() => {
        let mount = true;
        if (mount) {
            ReferralDetails();
            ReferalActionDropdown();
            listOfComments();
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);


    const handleSubmit = async (e,status) => {

        try {

            console.log('status', status)

            
            e.preventDefault();

            let payload = {
                screeningReferalId: location?.state?.id, 
                actionId: selectedaction, 
                comment: comment, 
                status: status
            }
            
            console.log('asd',payload)


           if(comment){

            const newObject={
                
                    
                        "_id": "65c5fecb4e9f44ebbe2233f1",
                        "userId": {
                            "_id": "65b097e47cff534af35838cf",
                            "createdBy": "65af78ffe5e8e261821da1dc",
                            "isManager": 1,
                            "email": "medicaluser@gmail.com",
                            "firstName": "sas",
                            "lastName": "as",
                            "dateOfBirth": "2024-01-20",
                            "uid": "2024081797",
                            "nationalIDNumber": "12345",
                            "password": "$2b$10$3GStmy43nXE2KkHgGnEWh.elvOSfHjvWWHkyIyXazewP3OsnUzbB.",
                            "gender": "male",
                            "isForget": false,
                            "firstLogin": false,
                            "isVerify": true,
                            "imageName": "https://res.cloudinary.com/dwlkjqsed/image/upload/v1706771261/samples/man-portrait.jpg",
                            "user_social_token": null,
                            "user_social_type": null,
                            "user_device_type": null,
                            "user_device_token": null,
                            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWIwOTdlNDdjZmY1MzRhZjM1ODM4Y2YiLCJpYXQiOjE3MDczODI3NDd9.Yne-JJXY2ptettxYASzlQ9VY1th0D2Y44Kq0cU-fshM",
                            "profilecompleted": false,
                            "role": "medicalBoard",
                            "address": {
                                "line2": "aas",
                                "city": "asas",
                                "country": "asas",
                                "postalCode": "assa1212",
                                "line1": "assa"
                            },
                            "emergency": {
                                "relation": "saas",
                                "phone": "121212",
                                "name": "sa"
                            },
                            "is_notification": false,
                            "createdAt": "2024-01-24T04:53:56.854Z",
                            "updatedAt": "2024-02-08T08:59:07.195Z",
                            "__v": 1,
                            "boardId": "65b097e47cff534af35838d1",
                            "specialisation": []
                        },
                        "screeningReferalId": "65c5c56abe3583efecc1fdbd",
                        "actionId": "65c5aeaf0dd3ae9010bf3542",
                        "date": "Fri Feb 09 2024 15:12:08 GMT+0500 (Pakistan Standard Time)",
                        "comment": comment,
                        "createdAt": Date.now(),
                        "updatedAt": Date.now(),
                        "__v": 0,
                        "referalactions": {
                            "_id": "65c5aeaf0dd3ae9010bf3542",
                            "createdBy": profile?._id,
                            "name": selectedaction?.name,
                            "createdAt": Date.now(),
                            "updatedAt": Date.now(),
                            "__v": 0
                        }
                    
                
            }



                await dispatch(addCommentOnReferal(payload)).unwrap();

                setcomments(c => [...c, newObject]);
                setactive(status)
                setcomment("")
                setselectedaction("")

             
                
         }
         else{
            toast.error("Comment cannot be empty")
         }
                
          
       

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    console.log('referralDetail', referralDetail)
    console.log('cpmments', comments)


  return (
    <>
       <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/referrals")}>Referrals</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Referral Details</li>
                    </ol>
                </nav>
                <div className="d-md-flex align-items-center justify-content-between mb-2">
                    <h2 className="font-size-24 blue-color poppins-font fw-bold">Referral Details</h2>
                </div>


                                    {/* Patient Details */}
                {selectedPatient && <div className="row gy-4">

<div className="col-md-3 d-flex gap-4">
    <img className="lh-img-100 rounded-circle mt-2" src={selectedPatient?.imageName ? selectedPatient?.imageName : "https://via.placeholder.com/100x100"} />
    <div className="flex-shrink-1 ">
        <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Patient Details</p>
        <p className="font-size-16 blue-color mb-0"><b>Name:</b> {selectedPatient?.firstName} {selectedPatient?.lastName}</p>
        <p className="font-size-16 blue-color mb-0"><b>Date Of Birth:</b> {moment(selectedPatient?.dateOfBirth).format("MMM DD YYYY")}</p>
        <p className="font-size-16 blue-color mb-0"><b>Email:</b> {selectedPatient?.email}</p>
        <p className="font-size-16 blue-color mb-0"><b>Gender:</b> {selectedPatient?.gender}</p>
        <p className="font-size-16 blue-color mb-0"><b>Phone:</b> {selectedPatient?.phone}</p>
    </div>

</div>
<div style={{ paddingLeft: "70px" }} className="col-md-3">
    <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Contact Details</p>
    <p className="font-size-16 blue-color mb-0"><b>City:</b> {selectedPatient?.address.city}</p>
    <p className="font-size-16 blue-color mb-0"><b>Country:</b> {selectedPatient?.address.country}</p>
    <p className="font-size-16 blue-color mb-0"><b>Address 1:</b> {selectedPatient?.address.line1}</p>
    <p className="font-size-16 blue-color mb-0"><b>Address 2:</b> {selectedPatient?.address.line2}</p>
    <p className="font-size-16 blue-color mb-0"><b>Postal Code:</b> {selectedPatient?.address.postalCode}</p>

</div>

<div className="col-md-3">
    <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Insurance Details</p>
    <p className="font-size-16 blue-color mb-0"><b>MR Number:</b> {selectedPatient?.roleSpecificData?.MRNumber}</p>
    <p className="font-size-16 blue-color mb-0"><b>Name:</b> {selectedPatient?.roleSpecificData?.insurance?.name}</p>
    <p className="font-size-16 blue-color mb-0"><b>Number:</b> {selectedPatient?.roleSpecificData?.insurance?.number}</p>
    <p className="font-size-16 blue-color mb-0"><b>Start Date:</b> {moment(selectedPatient?.roleSpecificData?.insurance?.startDate).format("MMM DD YYYY")}</p>
    <p className="font-size-16 blue-color mb-0"><b>End Date:</b> {moment(selectedPatient?.roleSpecificData?.insurance?.endDate).format("MMM DD YYYY")}</p>
</div>

<div className="col-md-3">
    <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Medical Details</p>

    <p className="font-size-16 blue-color mb-0"><b>Blood Group:</b> {selectedPatient?.roleSpecificData?.bloodGroup}</p>
    <p className="font-size-16 blue-color mb-0"><b>Smoking Status:</b> {selectedPatient?.roleSpecificData?.smokingStatus}</p>
    <p className="font-size-16 blue-color mb-0"><b>Alcohol Consumption:</b> {selectedPatient?.roleSpecificData?.alcoholConsumption}</p>
    <p className="font-size-16 blue-color mb-0"><b>Exercise Habits:</b> {selectedPatient?.roleSpecificData?.exerciseHabits}</p>
    <p className="font-size-16 blue-color mb-0"><b>Dietary Habits:</b> {selectedPatient?.roleSpecificData?.dietaryHabits}</p>

</div>


</div>}


                                    {/* Screening Details Table*/}
                <div className="lh-radius-20 white-bg lh-shadow p-3">
                    <p className="font-size-20 blue-color">Screening Results</p>
                    <div class="table-responsive">
    <table class="table lh-table table-borderless">
    <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Ranges</th>
                                    <th scope="col">Referral Team</th>
                                    {/* <th style={{ width: '100px' }} scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {referralDetail?.map((list, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>{list?.screeningresults?.blockType=="Medical Test"?list?.blocks[0]?.data?.heading:list?.blocks[0]?.data?.details?.heading}</td>
                                        {list?.finalresult?.map((s)=>(<td style={{ verticalAlign: 'middle' }}> <span className={`${(s?.answerText <= s?.abnormalMinValue ? 'red-color' : (s?.answerText > s?.abnormalMinValue && s?.answerText <= s?.criticalMinValue  ? 'yellow-color' : s?.answerText > s?.criticalMinValue && s?.answerText < s?.criticalMaxValue  ? '': s?.answerText >= s?.criticalMaxValue && s?.answerText < s?.abnormalMaxValue ? 'yellow-color' : s?.answerText >= s?.abnormalMaxValue ? 'red-color'  :''))}`}>{s?.answerText}</span> </td>))}
                                        <td style={{ verticalAlign: 'middle' }}>{list?.screeningresults?.blockType}</td>
                                        {list?.finalresult?.map((s)=>(<td style={{ verticalAlign: 'middle' }}><span className="red-color">{s?.abnormalMinValue}</span> &lt; <span className="yellow-color">{s?.criticalMinValue}</span> &lt; {"normal"} &lt; <span className="yellow-color">{s?.criticalMaxValue}</span> &lt; <span className="red-color">{s?.abnormalMaxValue}</span></td>))}
                                        <td>{list?.referralteams?.name}</td>
                                        {/* <td style={{ verticalAlign: 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Eye width="18" /></div>
                                            </div>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
    </table>
    </div>
                </div>
            </div>



    <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
    <div className="lh-radius-20 white-bg lh-shadow p-3">


                                    {/* Referral Comments*/}

        {comments?.length>0&&comments?.map((c)=>(<div className="lh-add-reffrel-comment px-3 mb-4 d-flex flex-column gap-3">
            <div className="lh-reffral-comment">
              <div className="d-flex justify-content-between align-items-start">
              <p className="font-size-16 blue-color fw-bold mb-0">{c?.userId?.firstName} {c?.userId?.lastName}</p>
                <p className="font-size-16 green-color mb-2">{c?.referalactions?.name}</p>
              </div>
              <div className="d-flex justify-content-between">
              <p className="font-size-16 black-color mb-0 w-75">
                {c?.comment}
              </p>
                <p className="font-size-16 grey-color mb-2">{moment(c?.date).format("MMM DD YYYY")}</p>
              </div>
            </div>      
        </div>))}


     {active==1&&(<><div className="row gy-3 mb-4">
        <div className="col-md-4">
        <div class="lh-input2">
            <label class="form-label">Action</label>
        </div>
        <div class="dropdown lh-select-dropdown">
            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedaction?.name?selectedaction?.name:"Action"}
            </a>
            <ul class="dropdown-menu">
                {actions?.map((data,i)=> (
                <li onClick={()=>setselectedaction(data)} key={i}><a class="dropdown-item">{data?.name}</a></li>
            ))}
            </ul>
        </div>
        </div>
        <div className="col-md-8">
        <div class="lh-input2">
            <label for="Comment" class="form-label">Comment</label>
            <textarea value={comment} onChange={(e)=>setcomment(e.target.value)} class="form-control" id="Comment" rows="2"></textarea>
        </div>
        </div>
      </div>
      <div className="d-flex gap-2 justify-content-center">
        <button onClick={(e)=>handleSubmit(e,1)} class="btn lh-btn lh-blue-btn px-4">Save</button>
        <button onClick={(e)=>handleSubmit(e,0)} class="btn lh-btn lh-blue-btn px-4">Save & Close</button>
      </div>
      </>)}
    </div>
    </div>
    </>
  )
}

export default ReferralDetail