import React, { useState } from 'react';
import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';
import moment from 'moment'

const AccidentsInjuries = ({state,patientId,dataa,handleData,removeData}) => {
 
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [name, setname] = useState('');
  const [date, setdate] = useState('');
  const [description, setdescription] = useState('');
  const [injuriesSustained, setinjuriesSustained] = useState('');
  const [initialTreatment, setinitialTreatment] = useState('');
  const [followUpTreatment, setfollowUpTreatment] = useState('');
  const [recovery, setrecovery] = useState('');
  const [provider, setprovider] = useState('');


  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };

  const handleSubmit = (e) => {

    try {
    e.preventDefault()

    const formData = {
      id: uuidv4(),
      name: name,
      date: date,
      description: description,
      injuriesSustained: injuriesSustained,
      initialTreatment: initialTreatment,
      followUpTreatment: followUpTreatment,
      recovery: recovery,
      provider: provider,
      type: state,
    };

    handleData(formData)

   
    setname('');
    setdate('');
    setdescription('');
    setinjuriesSustained('');
    setinitialTreatment('');
    setfollowUpTreatment('');
    setrecovery('');
    setprovider('');

    setIsFormVisible(false);
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };

  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">

    <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Accidents Injuries<button className="btn lh-btn lh-blue-btn px-3 add-history"  onClick={toggleFormVisibility}>{isFormVisible ? 'Close' : 'Add'}</button></p></div>
    
    
    {isFormVisible && (
          <>
<div className="row gy-3 mb-4">
<div className="col-md-4">
    <div class="lh-input2">
        <label for="Name" class="form-label">Name</label>
        <input value={name} onChange={(e) => setname(e.target.value)} type="text" class="form-control" id="Name" placeholder="Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date" class="form-label">Date </label>
        <input value={date} onChange={(e) => setdate(e.target.value)} type="date" class="form-control" id="Date" placeholder="Date" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Description" class="form-label">Description</label>
        <input value={description} onChange={(e) => setdescription(e.target.value)} type="text" class="form-control" id="Description" placeholder="Description" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Injuries-Sustained" class="form-label">Injuries Sustained</label>
        <input value={injuriesSustained} onChange={(e) => setinjuriesSustained(e.target.value)} type="text" class="form-control" id="Injuries-Sustained" placeholder="Injuries Sustained" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Initial-Treatment" class="form-label">Initial Treatment</label>
        <input value={initialTreatment} onChange={(e) => setinitialTreatment(e.target.value)} type="text" class="form-control" id="Initial-Treatment" placeholder="Initial Treatment" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Follow-up-Treatment" class="form-label">Follow-up Treatment</label>
        <input value={followUpTreatment} onChange={(e) => setfollowUpTreatment(e.target.value)} type="text" class="form-control" id="Follow-up-Treatment" placeholder="Follow-up Treatment" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Recovery" class="form-label">Recovery</label>
        <input value={recovery} onChange={(e) => setrecovery(e.target.value)} type="text" class="form-control" id="Recovery" placeholder="Recovery" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Provider" class="form-label">Provider</label>
        <input value={provider} onChange={(e) => setprovider(e.target.value)} type="text" class="form-control" id="Provider" placeholder="Provider" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div></>
        )}

{dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive"> 

    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Date</th>
      <th scope="col">Injuries Sustained</th>
      <th scope="col">Initial Treatment</th>
      <th scope="col">Follow-up Treatment</th>
      <th scope="col">Recovery</th>
      <th scope="col">Provider</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.name}</td>
      <td>{moment(d.date).format("MMM DD YYYY")}</td>
      <td>{d.injuriesSustained}</td>
      <td>{d.initialTreatment}</td>
      <td>{d.followUpTreatment}</td>
      <td>{d.recovery}</td>
      <td>{d.provider}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
</div>
</>
  )
}

export default AccidentsInjuries