import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import {
  getMedicalBoardClinicManagers,
} from "../../../store/slices/userSlice";


const Nurses = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState();
  const [load, setload] = useState(false);


  const AllMedicalBoardManagers = async () => {
    try {
      setload(true);
      setdata(null);
      setfilteredData(null);

      const response = await dispatch(
        getMedicalBoardClinicManagers()
      ).unwrap();

      console.log("response.data", response.data);
      setdata(response?.data);
      setfilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      AllMedicalBoardManagers();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);



  return (
    <>
      <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Clinic Managers</li>
          </ol>
        </nav>
        <div className="d-md-flex align-items-center justify-content-between mb-2">
          <h2 className="font-size-24 blue-color poppins-font fw-bold">Clinic Managers</h2>
          {/* <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-clinic-manager")}>Add Clinic Managers</button> */}
        </div>
        <div className="lh-radius-20 white-bg lh-shadow p-3">
          <p className="font-size-20 blue-color">Clinic Managers</p>
          <div class="table-responsive">
            <table class="table lh-table table-borderless">
              <thead>
                <tr>

                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Gender</th>
                  {/* <th scope="col">Specialisation</th> */}

                  <th scope="col">City/Country</th>
                  <th style={{ width: '100px' }} scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((d, i) => (
                  <tr>

                    <td>{d.firstName} {d.lastName}</td>
                    <td>{d.email}</td>
                    <td>{d.phone}</td>
                    <td>{d.gender}</td>
                    {/* {d?.roleSpecificData?.specialisation.map((s)=>(<td>{s}</td>))} */}
                    <td>{d?.address?.city}, {d?.address?.country}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div> */}
                        <div onClick={() => navigate("/add-clinic-manager", { state: { data: d, type: "edit" } })} className={`lh-action-icons l-blue-bg`}><Pencil width="18" /></div>
                        {/* <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                        <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                        <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Nurses