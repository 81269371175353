import React, { useEffect, useState } from 'react'
import { Pencil } from 'lucide-react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllScreeingPlans, getMedicalBoardPatientUsers, getScreeningSLotsForBooking, addScreeningBooking, rescheduleBooking } from '../../../store/slices/userSlice';
import moment from 'moment';

const BookingDetails = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);


    const [screeingPlans, setscreeingPlans] = useState([]);
    const [load, setload] = useState(false);
    const [patients, setpatients] = useState([]);
    const [slots, setslots] = useState([]);
    
    
    console.log('location.state', location.state)
    const [selectedPatient, setselectedPatient] = useState(location.state.patientId);
    const [date, setDate] = useState(moment(tomorrow).format("YYYY-MM-DD"));
    const [plan, setplan] = useState();
    const [bookingSlot, setbookingSlot] = useState();
    const [filterData, setfilterData] = useState([]);
    const [toggle, settoggle] = useState(false);


    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const listOfScreenings = async () => {
        try {
            console.log('addddddddddsd')
            setload(true);
            setscreeingPlans(null);


            const response = await dispatch(getAllScreeingPlans()).unwrap();


            setscreeingPlans(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const listOfAvailableSlots = async () => {
        try {
            console.log("firsasaasst")
            setload(true);
            setslots(null);

            let payload = { date: moment(date).format("MM-DD-YYYY"), id: plan._id }


            const response = await dispatch(getScreeningSLotsForBooking(payload)).unwrap();


            setslots(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };




    const handlePlan = async (data) => {   
        setplan(data)
    };

    const handlePatient = async (data) => {
        setselectedPatient(data)
    };




 

    useEffect(() => {
        let mount = true;
        if (mount) {
            listOfAvailableSlots();

        }
        return () => {
            mount = false;
        };
    }, [date, plan]);

    const AllMedicalBoardPatients = async () => {
        try {
            setload(true);
            setpatients(null);
            setfilterData(null);

            const response = await dispatch(
                getMedicalBoardPatientUsers()
            ).unwrap();

            setpatients(response?.data);
            setfilterData(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    
    useEffect(() => {
        let mount = true;
        if (mount) {
            AllMedicalBoardPatients();
            listOfScreenings();
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);



    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setDate(moment(tomorrow).format("YYYY-MM-DD"));
    }, []);


    const handleSubmit = async (e,status) => {

        try {

            
            e.preventDefault();
            let payload = {
                bookingId: location?.state?._id,
                slotId: location?.state?.slotId?._id,
                status: status
            }
            
            console.log('asd',payload)


           
                await dispatch(rescheduleBooking(payload)).unwrap();
         
            navigate("/bookings")
          
            
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    console.log('toggle', toggle)

    const ClinicSurgerylist=[{}]


  return (
    <>
    <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/bookings")}>Bookings</a></li>
            <li class="breadcrumb-item active" aria-current="page">Booking Detail</li>
        </ol>
    </nav>
    <h2 className="font-size-24 blue-color poppins-font fw-bold">Booking Detail</h2>
    <div className="lh-radius-20 white-bg lh-shadow p-4 mb-4">
   


    {selectedPatient && <div className="row gy-4">

                    <div className="col-md-3 d-flex gap-4">
                        <img className="lh-img-100 rounded-circle mt-2" src={selectedPatient?.imageName ? selectedPatient?.imageName : "https://via.placeholder.com/100x100"} />
                        <div className="flex-shrink-1 ">
                            <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Patient Details</p>
                            <p className="font-size-16 blue-color mb-0"><b>Name:</b> {selectedPatient?.firstName} {selectedPatient?.lastName}</p>
                            <p className="font-size-16 blue-color mb-0"><b>Date Of Birth:</b> {moment(selectedPatient?.dateOfBirth).format("MMM DD YYYY")}</p>
                            <p className="font-size-16 blue-color mb-0"><b>Email:</b> {selectedPatient?.email}</p>
                            <p className="font-size-16 blue-color mb-0"><b>Gender:</b> {selectedPatient?.gender}</p>
                            <p className="font-size-16 blue-color mb-0"><b>Phone:</b> {selectedPatient?.phone}</p>
                        </div>

                    </div>
                    <div style={{ paddingLeft: "70px" }} className="col-md-3">
                        <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Contact Details</p>
                        <p className="font-size-16 blue-color mb-0"><b>City:</b> {selectedPatient?.address.city}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Country:</b> {selectedPatient?.address.country}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Address 1:</b> {selectedPatient?.address.line1}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Address 2:</b> {selectedPatient?.address.line2}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Postal Code:</b> {selectedPatient?.address.postalCode}</p>

                    </div>

                    <div className="col-md-3">
                        <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Insurance Details</p>
                        <p className="font-size-16 blue-color mb-0"><b>MR Number:</b> {selectedPatient?.roleSpecificData?.MRNumber}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Name:</b> {selectedPatient?.roleSpecificData?.insurance?.name}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Number:</b> {selectedPatient?.roleSpecificData?.insurance?.number}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Start Date:</b> {moment(selectedPatient?.roleSpecificData?.insurance?.startDate).format("MMM DD YYYY")}</p>
                        <p className="font-size-16 blue-color mb-0"><b>End Date:</b> {moment(selectedPatient?.roleSpecificData?.insurance?.endDate).format("MMM DD YYYY")}</p>
                    </div>

                    <div className="col-md-3">
                        <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Medical Details</p>

                        <p className="font-size-16 blue-color mb-0"><b>Blood Group:</b> {selectedPatient?.roleSpecificData?.bloodGroup}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Smoking Status:</b> {selectedPatient?.roleSpecificData?.smokingStatus}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Alcohol Consumption:</b> {selectedPatient?.roleSpecificData?.alcoholConsumption}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Exercise Habits:</b> {selectedPatient?.roleSpecificData?.exerciseHabits}</p>
                        <p className="font-size-16 blue-color mb-0"><b>Dietary Habits:</b> {selectedPatient?.roleSpecificData?.dietaryHabits}</p>

                    </div>


                </div>}


    </div>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-4">
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Start Time</th>
      <th scope="col">Duration</th>
      <th scope="col">Location</th>
      <th scope="col">Pod</th>
      <th scope="col">Screening Name</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {/* {location?.state?.slotId?.map((list,i)=> ( */}
    <tr>
      <td>{moment(location?.state?.slotId?.date).format("MMM Do YYYY")}</td>
      <td>{location?.state?.slotId?.startTime}</td>
      <td>{location?.state?.slotId?.duration}</td>
      <td>{location?.state?.screeingLocation?.name}</td>
      <td>{location?.state?.screeningPod?.name}</td>
      <td>{location?.state?.screeningPlanId?.name}</td>
      <td><div className="d-flex gap-2">
      <button class="btn lh-btn lh-blue-btn px-3" onClick={()=>settoggle(!toggle)}>Reschedule</button>
      <button class="btn lh-btn lh-blue-btn px-3" onClick={(e)=>handleSubmit(e,"Cancel")}>Cancel</button>
        </div></td>
    </tr>
  {/* ))}  */}
  </tbody>
    </table>
    </div>
    </div>
    {toggle && (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-4 mb-2">
                <div className="row justify-content-between mb-3">
                    <div className="col-md-4">
                        <div class="lh-input2">
                            <label class="form-label">Screenings</label>
                        </div>
                        <div class="dropdown lh-select-dropdown">
                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {plan ? plan?.name : "Screenings"}
                            </a>
                            <ul class="dropdown-menu">
                                {screeingPlans?.length>0&&screeingPlans?.map((data, i) => (
                                    <li key={i} onClick={() => handlePlan(data)}><a class="dropdown-item" >{data?.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div class="lh-input2">
                            <label for="date" class="form-label">Date</label>
                            <input type="date" min={getCurrentDate()} class="form-control" id="date" defaultValue={date} onChange={(e) => setDate(e.target.value)} placeholder="5-7" />
                        </div>
                    </div>
                </div>
                <div className="lh-book-screening">
                    {slots?.map((data, index) => (

                        <div className="lh-screening-book-single" key={index}>
                            <p className="font-size-16 blue-color fw-bold mb-0">{data?.locationName}</p>
                            {
                                data?.pods?.map((data2, index2) => (
                                    <div key={index2}>
                                        <p className="font-size-16 grey-color mb-2">{data2?.name}</p>
                                        <div className="d-flex gap-2 flex-wrap">
                                            {data2.slots?.map((day, i) => (
                                                <>
                                                    <input
                                                        type="radio"
                                                        name="day"
                                                        className="btn-check"
                                                        id={`day-${day?._id}`}
                                                        onChange={() => setbookingSlot(day)}
                                                    />
                                                    <label className="btn lh-button-select" htmlFor={`day-${day?._id}`}>
                                                        {day?.startTime} to {day?.endTime}
                                                    </label>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))}
                </div>
            </div>
            {slots?.length>0&&(<div className="text-center">
                <button onClick={(e)=>handleSubmit(e,"Rescheduled")} class="btn lh-btn lh-blue-btn px-4">Save</button>
            </div>)}
    </>)}
    </div>
    </>
  )
}

export default BookingDetails