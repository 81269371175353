import { Eye, EyeOff } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signinUser } from "../../store/slices/userSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true);

  const handleSubmit = async (e) => {
    try {
    e.preventDefault();
      await dispatch(signinUser({ email, password })).unwrap();
      navigate("/");
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
      <section class="vh-100 authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <img
                onClick={() => navigate("/")}
                className="mx-auto d-block auth-logo mb-5 cursor-pointer w-auto"
                width="350"
                src={"../assets/images/logo.svg"}
              />
              <div class="card rounded border-0 lh-shadow lh-radius-20 light-blue-bg">
                <div class="card-body p-4">
                  <p className="font-size-20 blue-color poppins-font fw-semibold mb-4">
                    Sign in
                  </p>
                  <form
                    onSubmit={handleSubmit}
                    className="needs-validation"
                    novalidate
                  >
                    <div class="mb-3 lh-input">
                      <label for="email" class="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        autocomplete="email"
                      />
                    </div>
                    <div class="mb-3 lh-input position-relative">
                      <label for="password" class="form-label">
                        Password
                      </label>
                      <input
                        class="form-control"
                        id="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        type={isSecureEntry ? "password" : "text"}
                        autocomplete="password"
                        required
                      />
                      <span
                        onClick={() => {
                          setisSecureEntry((prev) => !prev);
                        }}
                        className={`eye-password`}
                      >
                        {isSecureEntry ? <Eye /> : <EyeOff />}
                      </span>
                    </div>
                    <p className="font-size-14 blue-color poppins-font mb-4">
                      <a onClick={() => navigate("/forget-password")}>
                        Forgot password?
                      </a>
                    </p>
                    <button
                      class="btn lh-btn lh-green-btn w-100 mb-4"
                      type="submit"
                    >
                      Log in
                    </button>
                  </form>
                  {/* <div className="d-flex align-items-center justify-content-center gap-2">
                                        <div className="lh-social-login"><i class="bi bi-facebook"></i></div>
                                        <div className="lh-social-login"><i class="bi bi-google"></i></div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
