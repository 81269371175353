import toast from "react-hot-toast";
import axios from "axios";
import {
  privacy,
  terms,
  signinUser,
  updatePassword,
  updatePrivacy,
  updateTerms,
  userLogout,
  getAllMedicalBoards,
  getPatientsScreeningSessions,
  getScreeningResultList,
  getScreeningBlockTemplete,
  listOfCommentsOnReferal,
  addCommentOnReferal,
  getReferalActionDropdown,
  getMedicalBoardClinics,
  getMedicalBoardDoctors,
  getMedicalBoardNurses,
  listOfReferals,
  screeningReferalDetail,
  getCompanyUsers,
  addCompany,
  createReferal,
  editCompany,
  getReferralTeams,
  getCallCenterTeams,
  addUser,
  addMedicalHistory,
  addFamilyMedicalHistory,
  editUser,
  rescheduleBooking,
  getScreeningPlanDetail,
  addScreeningBooking,
  editScreeningBlock,
  getMedicalBoardClinicManagers,
  getMedicalBoardReferralUsers,
  getMedicalBoardCallCenterUsers,
  createScreeningLocation,
  createScreeningBlock,
  createScreeingPod,
  getAllScreeningPods,
  getAllScreeningLocations,
  createScreeningSlot,
  getAllSScreeningSlots,
  editScreeingPod,
  editScreeningLocation,
  getAllScreeingPlans,
  getScreeningSLotsForBooking,
  getAllScreeningBookings

} from "../slices/userSlice";
export const extraReducers = (builder) => {
  builder

    // Sign In
    .addCase(signinUser.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(signinUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action?.payload?.message);


      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.user = action.payload.data;
      console.log(state.user);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.data.token}`;

      state.error = null;

    })
    .addCase(signinUser.rejected, (state, action) => {
      console.log('stateeeeeeeeeee', action?.payload?.message)
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })


    // Log Out
    .addCase(userLogout.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(userLogout.fulfilled, (state, action) => {
      toast.success(action?.payload?.message);
      localStorage.clear();
      state.status = "succeeded";
      state.error = null;
      state.user = null;
      state.event_list = null;
      state.terms = null;
      state.privacy = null;
      state.user_events_tasks = null;
      state.unread_messages = null;
      state.dashboard = null;
      state.socket = null;
    })
    .addCase(userLogout.rejected, (state, action) => {
      toast.error(action?.payload?.message);
      state.status = "failed";
      state.error = action?.payload?.message;
    })




    // update Password
    .addCase(updatePassword.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(updatePassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // addCompany
    .addCase(addCompany.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addCompany.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // editCompany
    .addCase(editCompany.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(editCompany.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(editCompany.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // addUser
    .addCase(addUser.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // addMedicalHistory
    .addCase(addMedicalHistory.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addMedicalHistory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addMedicalHistory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // addFamilyMedicalHistory
    .addCase(addFamilyMedicalHistory.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addFamilyMedicalHistory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addFamilyMedicalHistory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // editUser
    .addCase(editUser.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(editUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(editUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // rescheduleBooking
    .addCase(rescheduleBooking.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(rescheduleBooking.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      
    })
    .addCase(rescheduleBooking.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      
    })

    // getScreeningPlanDetail
    .addCase(getScreeningPlanDetail.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getScreeningPlanDetail.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      
    })
    .addCase(getScreeningPlanDetail.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
   
    })

    // addScreeningBooking
    .addCase(addScreeningBooking.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addScreeningBooking.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addScreeningBooking.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // editScreeningBlock
    .addCase(editScreeningBlock.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(editScreeningBlock.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(editScreeningBlock.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(createScreeningLocation.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(createScreeningLocation.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(createScreeningLocation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(createScreeningBlock.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(createScreeningBlock.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(createScreeningBlock.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(createScreeingPod.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(createScreeingPod.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })

    .addCase(createScreeingPod.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(editScreeingPod.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(editScreeingPod.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })

    .addCase(editScreeingPod.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(editScreeningLocation.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(editScreeningLocation.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })

    .addCase(editScreeningLocation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    .addCase(getAllScreeningPods.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllScreeningPods.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getAllScreeningPods.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
    .addCase(getAllScreeningBookings.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllScreeningBookings.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getAllScreeningBookings.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    .addCase(getScreeningSLotsForBooking.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getScreeningSLotsForBooking.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getScreeningSLotsForBooking.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    .addCase(getAllScreeingPlans.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllScreeingPlans.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getAllScreeingPlans.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    
    .addCase(getAllScreeningLocations.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllScreeningLocations.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getAllScreeningLocations.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
    
    .addCase(getAllSScreeningSlots.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(getAllSScreeningSlots.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })

    .addCase(getAllSScreeningSlots.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })


    .addCase(createScreeningSlot.pending, (state, action) => {
      state.status = "loading";
    })

    .addCase(createScreeningSlot.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message)
    })

    .addCase(createScreeningSlot.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action.payload.message)
    })



    // Get TcPp
    .addCase(terms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(terms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
    })
    .addCase(terms.rejected, (state, action) => {
      console.log("action.payload :>> ", action.payload);
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllMedicalBoards
    .addCase(getAllMedicalBoards.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getAllMedicalBoards.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllMedicalBoards.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getPatientsScreeningSessions
    .addCase(getPatientsScreeningSessions.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getPatientsScreeningSessions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getPatientsScreeningSessions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getScreeningResultList
    .addCase(getScreeningResultList.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getScreeningResultList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getScreeningResultList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // createReferal
    .addCase(createReferal.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(createReferal.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action.payload.message)
      state.error = null;
    })
    .addCase(createReferal.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action.payload.message)
    })

    // addCommentOnReferal
    .addCase(addCommentOnReferal.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(addCommentOnReferal.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action.payload.message)
      state.error = null;
    })
    .addCase(addCommentOnReferal.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action.payload.message)
    })

    // getScreeningBlockTemplete
    .addCase(getScreeningBlockTemplete.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getScreeningBlockTemplete.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getScreeningBlockTemplete.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getReferalActionDropdown
    .addCase(getReferalActionDropdown.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getReferalActionDropdown.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getReferalActionDropdown.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // listOfCommentsOnReferal
    .addCase(listOfCommentsOnReferal.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(listOfCommentsOnReferal.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(listOfCommentsOnReferal.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardDoctors
    .addCase(getMedicalBoardDoctors.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardDoctors.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardDoctors.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardNurses
    .addCase(getMedicalBoardNurses.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardNurses.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardNurses.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // listOfReferals
    .addCase(listOfReferals.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(listOfReferals.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(listOfReferals.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardClinicManagers
    .addCase(getMedicalBoardClinicManagers.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardClinicManagers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardClinicManagers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardClinics
    .addCase(getMedicalBoardClinics.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardClinics.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardClinics.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getCompanyUsers
    .addCase(getCompanyUsers.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getCompanyUsers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getCompanyUsers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // screeningReferalDetail
    .addCase(screeningReferalDetail.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(screeningReferalDetail.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(screeningReferalDetail.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getReferralTeams
    .addCase(getReferralTeams.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getReferralTeams.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getReferralTeams.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getCallCenterTeams
    .addCase(getCallCenterTeams.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getCallCenterTeams.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getCallCenterTeams.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardReferralUsers
    .addCase(getMedicalBoardReferralUsers.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardReferralUsers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardReferralUsers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getMedicalBoardCallCenterUsers
    .addCase(getMedicalBoardCallCenterUsers.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getMedicalBoardCallCenterUsers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getMedicalBoardCallCenterUsers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // Get TcPp
    .addCase(privacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(privacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
    })
    .addCase(privacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // Update TcPp
    .addCase(updateTerms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updateTerms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
      toast.success(action?.payload?.message);
    })
    .addCase(updateTerms.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })
    // Update TcPp
    .addCase(updatePrivacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePrivacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
      toast.success(action?.payload?.message);
    })
    .addCase(updatePrivacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    });
};
