import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';
import moment from 'moment'


const Hospitalizations = ({state,patientId,dataa,handleData,removeData}) => {

 

 
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [hospitalName, setHospitalName] = useState('');
  const [admissionDate, setAdmissionDate] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [reasonForHospitalization, setReasonForHospitalization] = useState('');
  const [treatmentProvided, setTreatmentProvided] = useState('');
  const [outcome, setOutcome] = useState('');
  const [followupCare, setFollowupCare] = useState('');


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };


  const handleSubmit = async (e) => {


    try {
    e.preventDefault("")
  
    const formData = {
      id: uuidv4(),
      hospitalName:hospitalName,
      admissionDate:admissionDate,
      dischargeDate:dischargeDate,
      reasonForHospitalization:reasonForHospitalization,
      treatmentProvided:treatmentProvided,
      outcome:outcome,
      followupCare:followupCare, 
      type: state,
    }
    
    
    handleData(formData)
    // const currentData = data.data || [];
    // setdata([currentData, formData])
    setIsFormVisible(false)
    setHospitalName('');
    setAdmissionDate('');
    setDischargeDate('');
    setReasonForHospitalization('');
    setTreatmentProvided('');
    setOutcome('');
    setFollowupCare('');  
  

  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };

  console.log('daeeeta', dataa)



  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3 ">
      
      <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Hospitalizations<button class="btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button>
  </p></div>
  

    {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Hospital Name" class="form-label">Hospital Name</label>
        <input onChange={(e) => setHospitalName(e.target.value)} value={hospitalName}  type="text" class="form-control" id="Hospital Name" placeholder="Hospital Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Admission-Date" class="form-label">Admission Date</label>
        <input onChange={(e) => setAdmissionDate(e.target.value)} value={admissionDate} type="date" class="form-control" id="Admission-Date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Discharge-Date" class="form-label">Discharge Date</label>
        <input onChange={(e) => setDischargeDate(e.target.value)} value={dischargeDate} type="date" class="form-control" id="Discharge-Date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Reason-for-Hospitalization" class="form-label">Reason for Hospitalization</label>
        <input onChange={(e) =>setReasonForHospitalization(e.target.value)} value={reasonForHospitalization} type="text" class="form-control" id="Reason-for-Hospitalization" placeholder="Reason for Hospitalization" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Treatment-Provided" class="form-label">Treatment Provided</label>
        <input onChange={(e) =>setTreatmentProvided(e.target.value)} value={treatmentProvided} type="text" class="form-control" id="Treatment-Provided" placeholder="Treatment Provided" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Outcome" class="form-label">Outcome</label>
        <input onChange={(e) =>setOutcome(e.target.value)} value={outcome} type="text" class="form-control" id="Outcome" placeholder="Outcome" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Followup-Care" class="form-label">Followup Care</label>
        <input onChange={(e) => setFollowupCare(e.target.value)} value={followupCare} type="text" class="form-control" id="Followup-Care" placeholder="Followup Care" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </>)}



{dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Hospital Name</th>
      <th scope="col">Admission Date</th>
      <th scope="col">Discharge Date</th>
      <th scope="col">Reason for Hospitalization</th>
      <th scope="col">Treatment Provided</th>
      <th scope="col">Outcome</th>
      <th scope="col">Followup Care</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.hospitalName}</td>
      <td>{moment(d.admissionDate).format("MMM DD YYYY")}</td>
      <td>{moment(d.dischargeDate).format("MMM DD YYYY")}</td>
      <td>{d.reasonForHospitalization}</td>
      <td>{d.treatmentProvided}</td>
      <td>{d.outcome}</td>
      <td>{d.followupCare}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
    </div>
    </>
  )

}

export default Hospitalizations