import { Activity, Pill, Stethoscope, UserRound } from 'lucide-react'
import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import {
  addConsentLegalForms,
  getConsentLegalForms
} from "../../../store/slices/userSlice";

const ConsentLegalForms = ({patientId}) => {
  const dispatch= useDispatch()
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [data, setdata] = useState([]);
  const [load, setload] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [documentReferenceNo, setDocumentReferenceNo] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [dateOfConsent, setDateOfConsent] = useState('');
  const [dateOfExpiration, setDateOfExpiration] = useState('');
  const [fileAttachment, setFileAttachment] = useState(null); 


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };
  const consentForms = [
    "General Consent for Treatment",
    "Informed Consent for Specific Procedures",
    "Consent for Anesthesia",
    "Telemedicine Consent",
    "Consent for Release of Information",
    "HIPAA Consent and Acknowledgement Forms",
    "Advance Directives Living Will",
    "Advance Directives Durable Power of Attorney for Healthcare",
    "Do Not Resuscitate (DNR) Orders",
    "Consent for Minor (if patient is a child)",
    "Mental Health Treatment Consent",
    "Consent for Photographing/Recording for Medical Purposes",
    "Acknowledgement of Receipt of Privacy Practices",
    "Refusal of Treatment Form",
    "Consent for Blood Transfusion",
    "Consent for Participation in Clinical Trials",
    "Financial Agreement or Payment Consent",
    "Insurance Authorization and Assignment Forms",
    "Medication Consent Forms",
    "Surgical Consent Forms",
    "Emergency Treatment Consent",
    "Patient Rights and Responsibilities Acknowledgement",
    "Data Sharing and Confidentiality Agreements",
    "Arbitration Agreements",
    "Release Forms for Medical Records",
    "Language Services Consent (if interpreter services are used)",
    "Parental/Guardian Consent for Pediatric Patients",
    "Consent for Genetic Testing",
    "End-of-Life Care Decisions Consent",
    "Acknowledgement of Notice of Non-Discrimination",
    "Patient Portal Access Agreement",
    "COVID-19 Specific Consent Forms",
  ];



const AllForms = async () => {
  try {
    setload(true);
    setdata(null);
    setfilteredData(null);

    const response = await dispatch(
      getConsentLegalForms({id: patientId})
    ).unwrap();

    console.log("response.data", response.data);
    setdata(response?.data);
    setfilteredData(response?.data);
    if(response?.data?.length<1){
      setIsFormVisible(true)
    }
    setload(false);
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
};


useEffect(() => {
  let mount = true;
  if (mount) {
    AllForms();
  }
  return () => {
    mount = false;
  };
}, [dispatch]);


const handleSubmit = async (e) => {

  try {

  e.preventDefault()

  const formData = new FormData();

  
  formData.append('userId', patientId);
  formData.append('documentType', documentType);
  formData.append('documentReferenceNumber', documentReferenceNo);
  formData.append('documentName', documentName);
  formData.append('description', description);
  formData.append('dateOfConsent', dateOfConsent);
  formData.append('dateOfExpiration', dateOfExpiration);
  formData.append('imageName', fileAttachment);

  await dispatch(addConsentLegalForms(formData)).unwrap();
  setIsFormVisible(false)
  setDocumentType('');
  setDocumentReferenceNo('');
  setDocumentName('');
  setDescription('');
  setDateOfConsent('');
  setDateOfExpiration('');
  setFileAttachment(null); 
  AllForms();


} catch (rejectedValueOrSerializedError) {
  console.log(rejectedValueOrSerializedError);
}
};

console.log('filteredData', filteredData)
  return (

    
    <>
    <div className="d-flex align-items-center justify-content-between">
    <p className="font-size-20 blue-color">Consent & Legal Forms</p>
    <button class="btn lh-btn lh-blue-btn px-3 additional-info-add" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button>
    </div>

        {isFormVisible && (
<>
<div className="row gy-3 mb-4">
<div className="col-md-4">
        <div class="lh-input2">
            <label class="form-label">Document Type</label>
        </div>
        <div class="dropdown lh-select-dropdown">
            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {documentType?documentType:"Document Type"}
            </a>
            <ul class="dropdown-menu">
            {consentForms.map((data,i)=> (
                <li onClick={()=>setDocumentType(data)} key={i}><a class="dropdown-item">{data}</a></li>
            ))}
            </ul>
        </div>
        </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Document-Reference-No" class="form-label">Document Reference No. </label>
        <input onChange={(e)=>setDocumentReferenceNo(e.target.value)} value={documentReferenceNo} type="text" class="form-control" id="Document-Reference-No" placeholder="Document Reference No." />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Document Name" class="form-label">Document Name</label>
        <input onChange={(e)=>setDocumentName(e.target.value)} value={documentName} type="text" class="form-control" id="Document Name" placeholder="Document Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Description" class="form-label">Description</label>
        <input onChange={(e)=>setDescription(e.target.value)} value={description} type="text" class="form-control" id="Description" placeholder="Description" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date-of-Consent" class="form-label">Date of Consent</label>
        <input onChange={(e)=>setDateOfConsent(e.target.value)} value={dateOfConsent} type="date" class="form-control" id="Date-of-Consent" placeholder="Date of Consent" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date-of-Expiration" class="form-label">Date of Expiration</label>
        <input type="date" onChange={(e)=>setDateOfExpiration(e.target.value)} value={dateOfExpiration} class="form-control" id="Date-of-Expiration" placeholder="Date of Expiration" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="File-Attachment" class="form-label">File Attachment</label>
        <input type="file" onChange={(e) => {setFileAttachment(e.target.files[0])}} class="form-control" id="File-Attachment" placeholder="File Attachment" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
</>
)}

    {filteredData?.length>0&&!isFormVisible&&(<div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Document Reference No</th>
      <th scope="col">Document Name</th>
      <th scope="col">Document Type</th>
      <th scope="col">Date of Consent</th>
      <th scope="col">Date of Expiration</th>
      {/* <th style={{width:'100px'}} scope="col">Actions</th> */}
    </tr>
  </thead>
  <tbody>
  {filteredData?.map((d,i)=> (
    <tr>
      <td>{d.documentReferenceNumber}</td>
      <td>{d.documentName}</td>
      <td>{d.documentType}</td>
      <td>{moment(d.dateOfConsent).format("MMM DD YYYY")}</td>
      <td>{moment(d.dateOfExpiration).format("MMM DD YYYY")}</td>
      {/* <td>
        <div className="d-flex align-items-center gap-2">
            <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div>
        </div>
      </td> */}
    </tr>
  ))}
  </tbody>
    </table>
    </div>

    </div>)}
    </>
  )
}

export default ConsentLegalForms