import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import {
  getReferralTeams,
} from "../../../store/slices/userSlice";



const ReferalTeams = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState();
  const [load, setload] = useState(false);

   


    const AllMedicalBoardReferralTeams = async () => {
      try {
        setload(true);
        setdata(null);
        setfilteredData(null);
  
        const response = await dispatch(
          getReferralTeams()
        ).unwrap();
  
        console.log("response.data", response.data);
        setdata(response?.data);
        setfilteredData(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        AllMedicalBoardReferralTeams();
      }
      return () => {
        mount = false;
      };
    }, [dispatch]);


  return (
    <>
    <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Referral Team</li>
        </ol>
    </nav>
    <div className="d-md-flex align-items-center justify-content-between mb-2">
    <h2 className="font-size-24 blue-color poppins-font fw-bold">Referral Team</h2>
    <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-referral-team")}>Add Referral Team</button>
    </div>
    <div className="lh-radius-20 white-bg lh-shadow p-3">
    <p className="font-size-20 blue-color">Referral Team</p>
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Team Code</th>
      <th scope="col">Name</th>
      <th scope="col">Operational Timings</th>
      <th scope="col">Specialities</th>
      <th scope="col">City/Country</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {filteredData?.map((d,i)=> (
    <tr>
      <td>{d.teamCode}</td>
      <td>{d.name}</td>
      <td>{d.timings}</td>
      <td>{d.specialities.map((s)=>(<p>{s}</p>))}</td>
      <td>{d?.address?.city}, {d?.address?.country}</td>
      <td>
      <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-2">
            <div onClick={()=>navigate("/add-referral-team",{state: {data: d, type: "add"}})} className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div onClick={()=>navigate("/add-referral-team",{state: {data: d, type: "edit"}})} className={`lh-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {/* <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
        </div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>
    </div>
    </div>
    </>
  )
}

export default ReferalTeams