import { Activity, Pencil, Pill, Stethoscope, UserRound } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllScreeningPods } from '../../../store/slices/userSlice';

const ScreeningPods = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();


  const [screeningPods, setscreeningPods] = useState([]);
  const [load, setload] = useState(false);



  const listOfScreenings = async () => {
    try {
      setload(true);
      setscreeningPods(null);


      const response = await dispatch(getAllScreeningPods()).unwrap();


      setscreeningPods(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };




  useEffect(() => {
    let mount = true;
    if (mount) {
      listOfScreenings();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);

  return (
    <>
      <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Screening Pods</li>
          </ol>
        </nav>
        <div className="d-md-flex align-items-center justify-content-between mb-2">
          <h2 className="font-size-24 blue-color poppins-font fw-bold">Screening Pods</h2>
          <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-screening-pod")}>Add Screening Pod</button>
        </div>
        <div className="lh-radius-20 white-bg lh-shadow p-3">
          <p className="font-size-20 blue-color">Screening Pods</p>
          <div class="table-responsive">
            <table class="table lh-table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Machine MAC Address</th>
                  <th scope="col">Manufacturer</th>
                  <th scope="col">Model No.</th>
                  <th style={{ width: '100px' }} scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {screeningPods?.map((list, i) => (
                  <tr key={i}>
                    <td>{list?.name}</td>
                    <td>{list?.screeninglocations.name}</td>
                    <td>{list?.macAddress}</td>
                    <td>{list?.manufacturer}</td>
                    <td>{list?.modelNo}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <div className={`lh-action-icons l-blue-bg`} onClick={() => navigate("/add-screening-pod", { state: list })}><Pencil width="18" /></div>
                        {/* <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                        <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                        <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScreeningPods