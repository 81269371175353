import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { createScreeningLocation, editScreeningLocation, getAlLocations, getProfile } from '../../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const AddScreeningLocation = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()


    const [detail, setdetail] = useState(location?.state);
    const [address, setaddress] = useState(detail ? detail?.location.address : '');
    const [city, setcity] = useState(detail ? detail?.location.city : '');
    const [country, setcountry] = useState(detail ? detail?.location.country : '');
    const [postCode, setpostCode] = useState(detail ? detail?.location.postCode : '');
    const [contactNumber, setcontactNumber] = useState(detail ? detail?.contactNumber : '');
    const [managerName, setmanagerName] = useState(detail ? detail?.managerName : '');
    const [name, setname] = useState(detail ? detail?.name : '');
    const [type, settype] = useState(detail ? detail?.type : '');
    const [locations, setlocations] = useState();


    const listOfLocations = async () => {
        try {
            setlocations(null);

            const response = await dispatch(getAlLocations()).unwrap();

            setlocations(response?.data);

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            listOfLocations();
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);


    const handleSubmit = async (e) => {

        try {

        e.preventDefault();
            let payload = {
                name,
                address,
                city,
                country,
                postCode,
                managerName,
                contactNumber,
                type,

            }

            let ediPayload = {
                id: detail?._id,
                name,
                address,
                city,
                country,
                postCode,
                managerName,
                contactNumber,
                type
            }

            if (detail) {
                await dispatch(editScreeningLocation(ediPayload)).unwrap();
            } else {
                await dispatch(createScreeningLocation(payload)).unwrap();
            }

            setname("")
            setaddress("")
            setcity("")
            setcountry("")
            setpostCode("")
            setmanagerName("")
            setcontactNumber("")
            navigate("/screening-locations");
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    return (
        <>
            <div className="lh-plans-list-page px-md-5 pt-3 px-3 pb-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/clinic-surgery")}>Screening locations</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Screening location</li>
                    </ol>
                </nav>
                <h2 className="font-size-24 blue-color poppins-font fw-bold">Add Screening location</h2>
                <div className="lh-radius-20 white-bg lh-shadow p-4 mb-4">
                    <div className="row gy-3">
                        <div className="col-md-6 lh-input2">
                            <label for="Name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="Name" placeholder="John" defaultValue={name} onChange={(e) => setname(e.target.value)} />
                        </div>

                        <div className="col-md-6">
                            <div class="lh-input2">
                                <label class="form-label">Type</label>
                            </div>
                            <div class="dropdown lh-select-dropdown">
                                <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {type ? type : "Type"}
                                </a>
                                <ul class="dropdown-menu">
                                    {locations?.map((data, i) => (
                                        <>
                                            <li onClick={() => { settype(data.name) }} key={i}><a class="dropdown-item">{data.name}</a></li>
                                        </>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div class="lh-input2">
                                <label for="Address" class="form-label">Address</label>
                                <input type="text" class="form-control" id="Address" placeholder="Pod 1, Greenwood Clinic" defaultValue={address} onChange={(e) => setaddress(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="City" class="form-label">City</label>
                                <input type="text" class="form-control" id="City" placeholder="Riyadh" defaultValue={city} onChange={(e) => setcity(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Country" class="form-label">Country</label>
                                <input type="text" class="form-control" id="Country" placeholder="Saudi Arabia" defaultValue={country} onChange={(e) => setcountry(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="lh-input2">
                                <label for="Postcode" class="form-label">Postcode</label>
                                <input type="text" class="form-control" id="Postcode" placeholder="KWF101" defaultValue={postCode} onChange={(e) => setpostCode(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="lh-input2">
                                <label for="Manager-name" class="form-label">Manager Name</label>
                                <input type="text" class="form-control" id="Manager-name" placeholder="John" defaultValue={managerName} onChange={(e) => setmanagerName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="lh-input2">
                                <label for="Contact-Number" class="form-label">Contact Number</label>
                                <input type="tel" class="form-control" id="Contact-Number" placeholder="+967 258 2588" defaultValue={contactNumber} onChange={(e) => setcontactNumber(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button class="btn lh-btn lh-blue-btn px-4" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </>
    )
}

export default AddScreeningLocation