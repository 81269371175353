import React, { useEffect, useState } from "react";
import { Camera } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany, addUser, editCompany
} from "../../store/slices/userSlice";

const AddMedicalBoard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  // Medical Board Form State
  const [id, setid] = useState(location.state ? location.state.data._id : "");
  const [type, settype] = useState(location.state ? location.state.type : "");
  const [name, setName] = useState(location.state ? location.state.data.name : "");
  const [email, setEmail] = useState(location.state ? location.state.data.email : "");
  const [phone, setPhone] = useState(location.state ? location.state.data.phone : "");
  const [address, setAddress] = useState(location.state ? location.state.data.address.line1 : "");
  const [address2, setAddress2] = useState(location.state ? location.state.data.address.line2 : "");
  const [city, setCity] = useState(location.state ? location.state.data.address.city : "");
  const [country, setCountry] = useState(location.state ? location.state.data.address.country : "");
  const [postcode, setPostcode] = useState(location.state ? location.state.data.address.postalCode : "");
  const [boardJurisdiction, setBoardJurisdiction] = useState(location.state ? location.state.data.boardJurisdiction : "");
  const [workingHours, setWorkingHours] = useState(location.state ? location.state.data.timings : "");

  // Medical Board User (Manager) Form State
  const [imageName, setimageName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationalIdNumber, setNationalIdNumber] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [gender, setGender] = useState("");

  // Address State
  const [addressUser, setAddressUser] = useState("");
  const [addressUser2, setAddressUser2] = useState("");
  const [cityUser, setCityUser] = useState("");
  const [countryUser, setCountryUser] = useState("");
  const [postcodeUser, setPostcodeUser] = useState("");

  // Emergency Contact Information State
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
  const [emergencyContactPhone, setEmergencyContactPhone] = useState("");



  const handleSubmit = async () => {

    try {
    const formData = new FormData();

    // Medical Board Form State
    formData.append("id", id);
    formData.append("companyName", name);
    formData.append("companyEmail", email);
    formData.append("companyPhone", phone);
    formData.append("companyAddressLine1", address);
    formData.append("companyAddressLine2", address2);
    formData.append("companyCity", city);
    formData.append("companyCountry", country);
    formData.append("companyPostalCode", postcode);
    formData.append("boardJurisdiction", boardJurisdiction);
    formData.append("timings", workingHours);
    formData.append("type", "board");


    // Medical Board User (Manager) Form State

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", emailUser);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("nationalIDNumber", nationalIdNumber);
    formData.append("phone", phoneUser);
    formData.append("gender", gender);
    formData.append("imageName", imageName);
    formData.append("role", "medicalBoard");


    formData.append("addressLine1", addressUser);
    formData.append("addressLine2", addressUser2);
    formData.append("city", cityUser);
    formData.append("country", countryUser);
    formData.append("postalCode", postcodeUser);


    formData.append("emergencyName", emergencyContactName);
    formData.append("emergencyRelation", emergencyContactRelation);
    formData.append("emergencyPhone", emergencyContactPhone);

    if (id && type == "add") {
      await dispatch(
        addUser(formData)
      ).unwrap();
    }

    else if (id && type == "edit") {
      await dispatch(
        editCompany({
          id: id,
          companyName: name,
          companyEmail: email,
          companyPhone: phone,
          companyAddressLine1: address,
          companyAddressLine2: address2,
          companyCity: city,
          companyCountry: country,
          companyPostalCode: postcode,
          boardJurisdiction: boardJurisdiction,
          timings: workingHours,
          type: "board"
        })
      ).unwrap();
    }

    else {
      await dispatch(
        addCompany(formData)
      ).unwrap();
    }

    navigate("/medical-boards");
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
}
  };

  console.log('idddd', id, type)

  return (
    <>
      <div className="lh-plans-list-page px-5 pt-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item">
              <a>Menu</a>
            </li>
            <li class="breadcrumb-item">
              <a onClick={() => navigate("/medical-boards")}>Medical Board</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Medical Board
            </li>
          </ol>
        </nav>
        {id && type == "edit" || !id && !type ? (<><h2 className="font-size-24 blue-color poppins-font fw-bold">
          {id && type == "edit" ? "Medical Board Details" : id && type == "add" ? "" : "Add Medical Board"}
        </h2>
          <div className="lh-radius-20 white-bg lh-shadow p-4 mb-4">
            <div className="row gy-3">
              <div className="col-md-4 lh-input2">
                <label htmlFor="Name" defaultValue={name} className="form-label">
                  Name
                </label>
                <input
                  defaultValue={name}
                  type="text"
                  className="form-control"
                  id="Name"
                  placeholder="John"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-md-4 lh-input2">
                <label htmlFor="Email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  defaultValue={email}
                  className="form-control"
                  id="Email"
                  placeholder="john@lifewell.health"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-4 lh-input2">
                <label htmlFor="Phone" className="form-label">
                  Phone
                </label>
                <input
                  defaultValue={phone}
                  type="tel"
                  className="form-control"
                  id="Phone"
                  placeholder="+967 354 3644"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <div className="lh-input2">
                  <label htmlFor="Address" className="form-label">
                    Address 1
                  </label>
                  <input
                    defaultValue={address}
                    type="text"
                    className="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="lh-input2">
                  <label htmlFor="Address" className="form-label">
                    Address 2
                  </label>
                  <input
                    defaultValue={address2}
                    type="text"
                    className="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="City" className="form-label">
                    City
                  </label>
                  <input
                    defaultValue={city}
                    type="text"
                    className="form-control"
                    id="City"
                    placeholder="Riyadh"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Country" className="form-label">
                    Country
                  </label>
                  <input
                    defaultValue={country}
                    type="text"
                    className="form-control"
                    id="Country"
                    placeholder="Saudi Arabia"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Postcode" className="form-label">
                    Postcode
                  </label>
                  <input
                    defaultValue={postcode}
                    type="text"
                    className="form-control"
                    id="Postcode"
                    placeholder="KWF101"
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Board-Jurisdiction" className="form-label">
                    Board Jurisdiction
                  </label>
                  <input
                    defaultValue={boardJurisdiction}
                    type="text"
                    className="form-control"
                    id="Board-Jurisdiction"
                    placeholder="KWF101"
                    onChange={(e) => setBoardJurisdiction(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="lh-input2">
                  <label htmlFor="Working-Hours" className="form-label">
                    Working Hours
                  </label>
                  <input
                    defaultValue={workingHours}
                    type="text"
                    className="form-control"
                    id="Working-Hours"
                    placeholder="KWF101"
                    onChange={(e) => setWorkingHours(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div></>) : null}
        {id && type == "add" || !id && !type ? (<><h2 className="font-size-24 blue-color poppins-font fw-bold">
          {id && type == "add" ? "Add Medical Board User" : "Add Medical Board User (Manager)"}
        </h2>
          <div className="lh-radius-20 white-bg lh-shadow p-4 mb-3">
            <div className="row mb-3">
              <div className="col-md-4">
                {/* <div class="mb-3 lh-input2">
                <label for="uid" class="form-label">
                  UID
                </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setUid(e.target.value)}
                  id="uid"
                  placeholder="A2434SFDF"
                />
              </div> */}
                <div class="mb-3 lh-input2">
                  <label for="First-Name" class="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    class="form-control"
                    id="First-Name"
                    placeholder="John"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    onChange={(e) => setEmailUser(e.target.value)}
                    id="Email"
                    placeholder="john@lifewell.health"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Date-of-Birth" class="form-label">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    id="Date-of-Birth"
                    placeholder="1/12/2024"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label class="form-label">Gender</label>
                  <div className="d-flex gap-2">
                    <input
                      type="radio"
                      name="gender"
                      onChange={() => setGender("male")}
                      class="btn-check"
                      id="male"
                    />
                    <label class="btn lh-button-select" for="male">
                      Male
                    </label>

                    <input
                      type="radio"
                      name="gender"
                      onChange={() => setGender("female")}
                      class="btn-check"
                      id="female"
                    />
                    <label class="btn lh-button-select" for="female">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="mb-3 lh-input2">
                  <label for="Last-Name" class="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setLastName(e.target.value)}
                    id="Last-Name"
                    placeholder="Marshell"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="National-ID-Number" class="form-label">
                    National ID Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setNationalIdNumber(e.target.value)}
                    id="National-ID-Number"
                    placeholder="A2434SFDF"
                  />
                </div>
                <div class="mb-3 lh-input2">
                  <label for="Phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    onChange={(e) => setPhoneUser(e.target.value)}
                    id="Phone"
                    placeholder="+967 354 3644"
                  />
                </div>
                {/* <div class="mb-3 lh-input2"> */}

                {/* </div> */}
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="imageUpload" class="form-label">
                    Profile
                  </label>
                </div>
                <div className="lh-profile-upload py-4">
                  <div class="avatar-upload">
                    <div class="avatar-edit">
                      <input
                        onChange={(e) => {
                          setimageName(e.target.files[0]);
                        }}
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label for="imageUpload">
                        <Camera width={18} strokeWidth={1} /> Upload
                      </label>
                    </div>
                    <div class="avatar-preview">
                      {imageName && (
                        <img
                          id="output"
                          src={URL.createObjectURL(imageName)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="font-size-20 blue-color poppins-font fw-semibold">
              Address
            </p>
            <div className="row gy-3 mb-4">
              <div className="col-md-12">
                <div class="lh-input2">
                  <label for="Address" class="form-label">
                    Address 1
                  </label>
                  <input
                    onChange={(e) => setAddressUser(e.target.value)}
                    type="text"
                    class="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div class="lh-input2">
                  <label for="Address" class="form-label">
                    Address 2
                  </label>
                  <input
                    onChange={(e) => setAddressUser2(e.target.value)}
                    type="text"
                    class="form-control"
                    id="Address"
                    placeholder="Pod 1, Greenwood Clinic"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="City" class="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setCityUser(e.target.value)}
                    class="form-control"
                    id="City"
                    placeholder="Riyadh"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Country" class="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setCountryUser(e.target.value)}
                    class="form-control"
                    id="Country"
                    placeholder="Saudi Arabia"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Postcode" class="form-label">
                    Postcode
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPostcodeUser(e.target.value)}
                    class="form-control"
                    id="Postcode"
                    placeholder="KWF101"
                  />
                </div>
              </div>
            </div>

            <p className="font-size-20 blue-color poppins-font fw-semibold">
              Emergency Contact Information
            </p>
            <div className="row gy-3">
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Name" class="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setEmergencyContactName(e.target.value)}
                    class="form-control"
                    id="Name"
                    placeholder="Mr. Cook"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Relation" class="form-label">
                    Relation
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setEmergencyContactRelation(e.target.value)}
                    class="form-control"
                    id="Relation"
                    placeholder="Father"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div class="lh-input2">
                  <label for="Phone" class="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    onChange={(e) => setEmergencyContactPhone(e.target.value)}
                    class="form-control"
                    id="Phone"
                    placeholder="+967 354 3644"
                  />
                </div>
              </div>
            </div>
          </div></>) : null}
        <div className="text-center">
          <button onClick={handleSubmit} class="btn lh-btn lh-blue-btn px-4">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddMedicalBoard;
