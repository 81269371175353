import { Activity, UserRoundCog, Pill, Stethoscope, UserRound, ArrowRight } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const ScreeningDetails = () => {
    const navigate = useNavigate();

    const ClinicSurgerylist = [
        {
            id: 1,
            title: 'Clark Huel',
            result: 'Yes',
            type: 'Question - Single Select',
            range: 'abnormal'
        },
        {
            id: 1,
            title: 'Clark Huel',
            result: 'Yes',
            type: 'Question - Single Select',
            range: 'normal'
        },
        {
            id: 1,
            title: 'Clark Huel',
            result: 'Yes',
            type: 'Question - Single Select',
            range: 'abnormal'
        },
        {
            id: 1,
            title: 'Clark Huel',
            result: 'Yes',
            type: 'Question - Single Select',
            range: 'normal'
        },
    ]
    const medicalNursingSpecialties = [
        "Allergy and Immunology",
        "Anesthesiology",
        "Cardiology/Cardiac Nursing",
        "Dermatology",
    ];
    return (
        <>
            <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/screenings")}>Screenings</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Screening Details</li>
                    </ol>
                </nav>
                <div className="d-md-flex align-items-center justify-content-between mb-2">
                    <h2 className="font-size-24 blue-color poppins-font fw-bold">Screening Details</h2>
                </div>
                <div className="lh-radius-20 white-bg lh-shadow p-3 mb-4">
                    <div className="row gy-4">
                        <div className="col-md-1">
                            <img className="lh-img-100 rounded-circle" src={"https://via.placeholder.com/100x100"} />
                        </div>
                        <div className="col-md-11">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="d-flex align-items-center gap-2 mb-2">
                                        <p className="font-size-22 blue-color poppins-font fw-semibold mb-0">Edmond Lebsack</p>
                                        <span className="blue-color cursor-pointer">
                                            <UserRoundCog size={18} />
                                        </span>
                                    </div>
                                    <p className="font-size-16 blue-color mb-0"><b>MR#:</b> 123-123-123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Emirates ID:</b> 123123-123123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Registered on:</b> Feb 20 2023 </p>
                                </div>
                                <div className="col-md-3 pt-4">
                                    <p className="font-size-16 blue-color mb-0"><b>Male:</b> 123-123-123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>DOB:</b> 123123-123123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Maritial Status:</b> Feb 20 2023 </p>
                                </div>
                                <div className="col-md-3 pt-4">
                                    <p className="font-size-16 blue-color mb-0"><b>Tel:</b> 123-123-123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Email:</b> 123123-123123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Mobile:</b> Feb 20 2023 </p>
                                </div>
                                <div className="col-md-3">
                                    <p className="font-size-22 blue-color poppins-font fw-semibold mb-0">CVD Risk Score Screening</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Location:</b> 123-123-123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Time:</b> 123-123-123</p>
                                    <p className="font-size-16 blue-color mb-0"><b>Score / Outcome:</b> 123-123-123</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lh-radius-20 white-bg lh-shadow p-3">
                    <p className="font-size-20 blue-color">Screening Results</p>
                    <div class="table-responsive">
                        <table class="table lh-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Ranges</th>
                                    <th scope="col">Referral</th>
                                    <th style={{ width: '100px' }} scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ClinicSurgerylist.map((list, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>{list.title}</td>
                                        <td style={{ verticalAlign: 'middle' }}> <span className={`${(list.range === 'normal' ? '' : (list.range === 'abnormal' ? 'red-color' : ''))}`}>{list.result}</span> </td>
                                        <td style={{ verticalAlign: 'middle' }}>{list.type}</td>
                                        <td style={{ verticalAlign: 'middle' }}><span className="red-color">100</span> &lt; <span className="yellow-color">120</span> &lt; {list.range} &lt; <span className="yellow-color">160</span> &lt; <span className="red-color">180</span></td>
                                        <td><div class="dropdown lh-select-dropdown">
                                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className='w-100 d-flex justify-content-between align-items-center'>Screenings <span className='lh-action-icons l-blue-bg'><ArrowRight width="18" /></span></span>
                                            </a>
                                            <ul class="dropdown-menu">
                                                {medicalNursingSpecialties.map((data, i) => (
                                                    <li key={i}><a class="dropdown-item" href="#">{data}</a></li>
                                                ))}
                                            </ul>
                                        </div></td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScreeningDetails