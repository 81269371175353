import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import NoRecordFound from './NoRecordFound';


const Medications = ({state,patientId,dataa,handleData,removeData}) => {

 
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [medicineName, setMedicineName] = useState('');
  const [illnessName, setillnessName] = useState('');
  const [dosage, setDosage] = useState('');
  const [intakeFrequency, setIntakeFrequency] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };

  const handleSubmit = async (e) => {


    try {
     e.preventDefault()
  
   const formData = {
        id: uuidv4(),
        medicineName:medicineName,
        illnessName:illnessName,
        dosage:dosage,
        intakeFrequency:intakeFrequency,
        startDate:startDate,
        endDate:endDate,
        type: state,
      } 
    
    
      
    handleData(formData)

      setIsFormVisible(false)
      setMedicineName('')
      setillnessName('')
      setDosage('')
      setIntakeFrequency('')
      setStartDate('')
      setEndDate('')
  
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  };


  

  console.log('daeeeta', dataa)


  return (
    <>
        <div className="lh-radius-20 white-bg lh-shadow p-3">
        <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Medications<button class="btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button></p>
        </div>

        {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Medicine-Name" class="form-label">Medicine Name</label>
        <input onChange={(e)=>setMedicineName(e.target.value)} value={medicineName} type="text" class="form-control" id="Medicine-Name" placeholder="Medicine Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Prescribed-For" class="form-label">Prescribed For (Illness Name)</label>
        <input onChange={(e)=>setillnessName(e.target.value)} value={illnessName} type="text" class="form-control" id="Prescribed-For" placeholder="Illness Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Dosage-quantity" class="form-label">Dosage (Quantity)</label>
        <input onChange={(e)=>setDosage(e.target.value)} value={dosage} type="number" class="form-control" id="Dosage-quantity" placeholder="Quantity" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Intake-Frequency" class="form-label">Intake Frequency</label>
        <input onChange={(e)=>setIntakeFrequency(e.target.value)} value={intakeFrequency} type="text" class="form-control" id="Intake-Frequency" placeholder="Intake Frequency" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Start-Date" class="form-label">Start Date</label>
        <input onChange={(e)=>setStartDate(e.target.value)} value={startDate} type="date" class="form-control" id="Start-Date" placeholder="Start Date" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="End-Date" class="form-label">End Date</label>
        <input onChange={(e)=>setEndDate(e.target.value)} value={endDate} type="date" class="form-control" id="End-Date" placeholder="End Date" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
</>)}


{dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table  table-borderless">
  <thead>
    <tr>
      <th scope="col">Medicine Name</th>
      <th scope="col">Prescribed For (Illness Name)</th>
      <th scope="col">Dosage (Quantity)</th>
      <th scope="col">Intake Frequency</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.medicineName}</td>
      <td>{d.illnessName}</td>
      <td>{d.dosage}</td>
      <td>{d.intakeFrequency}</td>
      <td>{moment(d.startDate).format("MMM DD YYYY")}</td>
      <td>{moment(d.endDate).format("MMM DD YYYY")}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
</div>
    </>
  )
}

export default Medications