import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';
import moment from 'moment'



const Allergies = ({state,patientId,dataa,handleData,removeData}) => {

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [trigger, settrigger] = useState('');
  const [reaction, setreaction] = useState('');
  const [identificationdate, setidentificationdate] = useState('');

  


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };

  const handleSubmit = async (e) => {

    try {
     e.preventDefault()
  
   const formData = {
        id: uuidv4(),
        trigger:trigger,
        reaction:reaction,
        identificationdate:identificationdate,
        type: state,
      } 
    
    
      
    handleData(formData)

      setIsFormVisible(false)
      settrigger('')
      setreaction('')
      setidentificationdate('')

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  
  };



  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Allergies<button class=" btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button></p>
    </div>
   

   
    {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Trigger" class="form-label">Trigger</label>
        <input onChange={(e)=>settrigger(e.target.value)} value={trigger} type="text" class="form-control" id="Trigger" placeholder="Trigger" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Reaction" class="form-label">Reaction</label>
        <input onChange={(e)=>setreaction(e.target.value)} value={reaction} type="text" class="form-control" id="Reaction" placeholder="Reaction" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Identification-Date" class="form-label">Identification Date</label>
        <input onChange={(e)=>setidentificationdate(e.target.value)} value={identificationdate} type="date" class="form-control" id="Identification-Date" placeholder="Identification Date" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </>)}


    {dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Trigger</th>
      <th scope="col">Reaction</th>
      <th scope="col">Identification Date</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.trigger}</td>
      <td>{d.reaction}</td>
      <td>{moment(d.identificationdate).format("MMM DD YYYY")}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
  </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
    </div>
    </>
    
  )
}

export default Allergies