import React, { useEffect, useState } from 'react';
import { Activity, Pill, Stethoscope, UserRound, Trash, ArrowRight, Eye } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import {
    getPatientsScreeningSessions,getScreeningResultList,getReferralTeams,createReferal
  } from "../../../store/slices/userSlice";
import NoRecordFound from './NoRecordFound';



const ScreeningResults = ({patientId}) => {
  const dispatch= useDispatch()
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [load, setload] = useState(false);
  const [sessions, setsessions] = useState([]);
  const [sessionId, setsessionId] = useState('');
  const [referralTeams, setreferralTeams] = useState([]);
  const [selectedReferralTeam, setselectedReferralTeam] = useState([]);
  const [screeningresults, setscreeningresults] = useState([]);




  const handleSubmit = async (e,referralTeamId,screeningResultId) => {

    try {

     e.preventDefault()
  
   const formData = {
        patientId: patientId,
        screeningResultId: screeningResultId,
        referalTeamId: referralTeamId
      } 
    
      await dispatch(
        createReferal(formData)
      ).unwrap();

      AllScreeningResultList();
      
      

      

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  
  };


 
const AllScreeningSessions = async () => {
    try {
      setload(true);
   
      setreferralTeams(null);

      const response = await dispatch(
        getPatientsScreeningSessions(patientId)
      ).unwrap();

 

      console.log("response.dataqwqwwq", response.data);
      setsessions(response?.data);
    
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
 
const AllScreeningResultList = async () => {
    try {
      setload(true);
      setscreeningresults(null);
     

      const response = await dispatch(
        getScreeningResultList(sessionId)
      ).unwrap();


      console.log("response.data2222222.000", response.data);
      setscreeningresults(response?.data);
    
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

const AllReferralTeams = async () => {
    try {
      setload(true);
      
      setreferralTeams(null);


      const response1 = await dispatch(
        getReferralTeams()
      ).unwrap();

   
      setreferralTeams(response1?.data);
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
    AllScreeningResultList();
    AllReferralTeams();
    }
    return () => {
      mount = false;
    };
  }, [sessionId]);


  useEffect(() => {
    let mount = true;
    if (mount) {
      AllScreeningSessions();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);

 
  
  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    {isFormVisible&&(<div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Screening Results
    <button className="btn lh-btn lh-blue-btn px-3 add-history" onClick={()=>setIsFormVisible(false)}>{isFormVisible && "Back"}</button>
    </p>
    </div>)}

    


    {!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
    <thead>
                                <tr>
                                    <th scope="col">Screening Name</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">Submission Time</th>                     
                                    <th style={{ width: '100px' }} scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessions?.map((d, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>{d.screeningplans.name}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{d.duration}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{moment(d.startTime, "HH:mm").format("HH:mm")}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{moment(d.submissionTime, "HH:mm").format("HH:mm")}</td>                              
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div> */}
                                                <div onClick={()=>{setsessionId(d?._id);setIsFormVisible(true)}} className={`lh-action-icons l-blue-bg`}><Eye width="18" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}








    {isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
    <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Ranges</th>
                                    <th scope="col">Referral Team</th>
                                    {/* <th style={{ width: '100px' }} scope="col">Actions</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {screeningresults?.map((list, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>{list.blockType=="Medical Test"?list?.blocks[0]?.data?.heading:list?.blocks[0]?.data?.details?.heading}</td>
                                        {list?.finalresult?.map((s)=>(<td style={{ verticalAlign: 'middle' }}> <span className={`${(s?.answerText <= s?.abnormalMinValue ? 'red-color' : (s?.answerText > s?.abnormalMinValue && s?.answerText <= s?.criticalMinValue  ? 'yellow-color' : s?.answerText > s?.criticalMinValue && s?.answerText < s?.criticalMaxValue  ? '': s?.answerText >= s?.criticalMaxValue && s?.answerText < s?.abnormalMaxValue ? 'yellow-color' : s?.answerText >= s?.abnormalMaxValue ? 'red-color'  :''))}`}>{s?.answerText}</span> </td>))}
                                        <td style={{ verticalAlign: 'middle' }}>{list.blockType}</td>
                                        {list?.finalresult?.map((s)=>(<td style={{ verticalAlign: 'middle' }}><span className="red-color">{s?.abnormalMinValue}</span> &lt; <span className="yellow-color">{s?.criticalMinValue}</span> &lt; {"normal"} &lt; <span className="yellow-color">{s?.criticalMaxValue}</span> &lt; <span className="red-color">{s?.abnormalMaxValue}</span></td>))}
                                        <td>{list?.referralName?.length<1?(<div class="dropdown lh-select-dropdown">
                                            <a class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className='w-100 d-flex justify-content-between align-items-center'>{selectedReferralTeam?.name?selectedReferralTeam?.name:"Select Referral Team"}<span className='lh-action-icons l-blue-bg'><ArrowRight width="18" /></span></span>
                                            </a>
                                            <ul class="dropdown-menu">
                                                {referralTeams?.map((data, i) => (
                                                    <li onClick={(e)=>handleSubmit(e,data?._id,list?._id)} key={i}><a class="dropdown-item">{data?.name}</a></li>
                                                ))}
                                            </ul>
                                        </div>):<p>{list?.referralName[0]?.name}</p>}</td>
                                    </tr>
                                ))}
                            </tbody>
    </table>
    </div>):(isFormVisible&&<NoRecordFound />)}


</div>
    </>
  )
}

export default ScreeningResults