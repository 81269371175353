import React from 'react'

const NoRecordFound = () => {
  return (
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3 no-record-found">
    <p className='fw-semibold'>No Data Found</p>
    </div>
  )
}

export default NoRecordFound