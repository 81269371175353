import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import {
  listOfReferals,
} from "../../../store/slices/userSlice";


const Referrals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [filteredData, setfilteredData] = useState();
  const [load, setload] = useState(false);

   


    const AllReferrals = async () => {
      try {
        setload(true);
        setdata(null);
        setfilteredData(null);
  
        const response = await dispatch(
          listOfReferals()
        ).unwrap();
  
        console.log("response.data", response.data);
        setdata(response?.data);
        setfilteredData(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        AllReferrals();
      }
      return () => {
        mount = false;
      };
    }, [dispatch]);

 
  return (
    <>
    <div className="lh-referrals-list-page px-5 pt-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Referrals</li>
        </ol>
    </nav>
    <h2 className="font-size-24 blue-color poppins-font fw-bold">Referrals</h2>
    <div className="lh-radius-20 white-bg lh-shadow p-3">
    <p className="font-size-20 blue-color">Referrals</p>
    <div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Referral Date</th>
      <th scope="col">Patient MR#</th>
      <th scope="col">Patient Name</th>
      <th scope="col">Patient Contact</th>
      <th scope="col">Screening Name</th>
      <th scope="col">Screening Date Time</th>
      <th scope="col">Referred By</th>
      <th scope="col">Referral Team</th>
      <th scope="col">Last Updated</th>
      <th scope="col">Status</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {filteredData?.map((list,i)=> (
    <tr>
      <td>{moment(list?.createdAt).format("MMM DD YYYY")}</td>
      <td>{list?.patient?.roleSpecificData?.MRNumber}</td>
      <td>{list?.patient?.firstName} {list?.patient?.lastName}</td>
      <td>{list?.patient?.phone}</td>
      <td>{list?.screeningplan?.name}</td>
      <td>{moment(list?.screeningsessions?.createdAt).format("MMM DD YYYY")}</td>
      <td>{list?.referralteams?.createdBy?.firstName} {list?.referralteams?.createdBy?.lastName}</td>
      <td>{list?.referralteams?.name}</td>
      <td>{moment(list?.lastUpdated).format("MMM DD YYYY")}</td>
      <td>{list?.isActive==1?"Active":"Closed"}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div className={`lh-action-icons l-blue-bg`} onClick={()=>navigate("/referral-detail",{state:{id: list?._id, patientDetails: list?.patient, isActive: list?.isActive}})}><Pencil width="18" /></div>
            {/* <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>
    </div>
    </div>
    </>
  )
}

export default Referrals