import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';


const PregancyFemale = ({state,patientId,dataa,handleData,removeData}) => {

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [date, setdate] = useState('');
  const [outcome, setoutcome] = useState('');
  const [complications, setcomplications] = useState('');
  const [postpartum, setPostpartum] = useState('');

  


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };

  const handleSubmit = async (e) => {

    try {

     e.preventDefault()
  
   const formData = {
        id: uuidv4(),
        date: date,
        outcome: outcome,
        complications: complications,
        postpartum: postpartum,
        type: state,
      } 
    
    
      
    handleData(formData)

      setIsFormVisible(false)
      setdate('')
      setoutcome('')
      setcomplications('')
      setPostpartum('')

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  
  };




  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    <p className="font-size-20 blue-color">Pregancy (Female only)<button class=" btn lh-btn lh-blue-btn px-3" style={{ "marginLeft": "1200px" }} onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button></p>
    
    
    {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Date" class="form-label">Date</label>
        <input onChange={(e)=>setdate(e.target.value)} value={date} type="date" class="form-control" id="Date" placeholder="Date" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Outcome" class="form-label">Outcome</label>
        <input onChange={(e)=>setoutcome(e.target.value)} value={outcome} type="text" class="form-control" id="Outcome" placeholder="Outcome" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Complications" class="form-label">Complications</label>
        <input onChange={(e)=>setcomplications(e.target.value)} value={complications} type="text" class="form-control" id="Complications" placeholder="Complications" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Postpartum" class="form-label">Postpartum</label>
        <input onChange={(e)=>setPostpartum(e.target.value)} value={postpartum} type="text" class="form-control" id="Postpartum" placeholder="Postpartum" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div></>)}

    {dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Outcome</th>
      <th scope="col">Date</th>
      <th scope="col">Complications</th>
      <th scope="col">Postpartum</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.outcome}</td>
      <td>{d.date}</td>
      <td>{d.complications}</td>
      <td>{d.postpartum}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}

</div>
    </>
  )
}

export default PregancyFemale