import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';
import moment from 'moment'





const ChronicIllnesses = ({state,patientId,dataa,handleData,removeData}) => {




  const [isFormVisible, setIsFormVisible] = useState(false);
  const [illnessName, setIllnessName] = useState('');
  const [dateOfDiagnosis, setDateOfDiagnosis] = useState('');
  const [severityStage, setSeverityStage] = useState('');
  const [initialTreatmentDate, setInitialTreatmentDate] = useState('');
  const [initialTreatmentName, setInitialTreatmentName] = useState('');
  const [followUpAdjustmentsDate, setFollowUpAdjustmentsDate] = useState('');
  const [followUpAdjustmentsName, setFollowUpAdjustmentsName] = useState('');
  const [specialistConsultationsDate, setSpecialistConsultationsDate] = useState('');
  const [specialistConsultationsName, setSpecialistConsultationsName] = useState('');
  const [complicationsDate, setComplicationsDate] = useState('');
  const [complicationsName, setComplicationsName] = useState('');
  const [currentStatusDate, setCurrentStatusDate] = useState('');
  const [currentStatusName, setCurrentStatusName] = useState('');



  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };



  const handleSubmit = async (e) => {

    try {
    e.preventDefault("")
  
    const formData = {
      id: uuidv4(),
      illnessName: illnessName,
      dateOfDiagnosis:dateOfDiagnosis,
      severityStage:severityStage,
      type: state,

      initialTreatmentName: initialTreatmentName,
      initialTreatmentDate: initialTreatmentDate,

  
      followUpAdjustmentsDate: followUpAdjustmentsDate,
      followUpAdjustmentsName: followUpAdjustmentsName,
   
    
      specialistConsultationsDate: specialistConsultationsDate,
      specialistConsultationsName: specialistConsultationsName,
   
   
      complicationsDate: complicationsDate,
      complicationsName: complicationsName,
   
    
      currentStatusDate: currentStatusDate,
      currentStatusName: currentStatusName,

    }
    
    
    
    handleData(formData)

    
     setIsFormVisible(false)
     setIllnessName('');
     setDateOfDiagnosis('');
     setSeverityStage('');
     setInitialTreatmentDate('');
     setInitialTreatmentName('');
     setFollowUpAdjustmentsDate('');
     setFollowUpAdjustmentsName('');
     setSpecialistConsultationsDate('');
     setSpecialistConsultationsName('');
     setComplicationsDate('');
     setComplicationsName('');
     setCurrentStatusDate('');
     setCurrentStatusName('');
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  
  };

 
  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3 ">
      
    <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Chronic Illnesses<button class="btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button>
</p></div>
   
    {isFormVisible&&(<><div className="row gy-3 mb-4">
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Illness-Name" class="form-label">Illness Name</label>
        <input type="text" class="form-control" id="Illness-Name" placeholder="Illness Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date-Of-Diagnosis" class="form-label">Date Of Diagnosis</label>
        <input type="date" class="form-control" id="Date-Of-Diagnosis" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Severity/Stage" class="form-label">Severity/Stage</label>
        <input type="text" class="form-control" id="Severity/Stage" placeholder="2024" />
    </div>
    </div>
    </div>
    <p className="font-size-20 blue-color poppins-font fw-semibold">Initial Treatment</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Initial-Treatment-date" class="form-label">Initial Treatment Date</label>
        <input type="date" class="form-control" id="Initial-Treatment-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Initial-Treatment-Name" class="form-label">Initial Treatment Name</label>
        <input type="text" class="form-control" id="Initial-Treatment-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Follow up Adjustments</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Follow-up-Adjustments-date" class="form-label">Follow up Adjustments Date</label>
        <input type="date" class="form-control" id="Follow-up-Adjustments-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Follow-up-Adjustments-Name" class="form-label">Follow up Adjustments Name</label>
        <input type="text" class="form-control" id="Follow-up-Adjustments-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Specialist Consultations</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Specialist-Consultations-date" class="form-label">Specialist Consultations Date</label>
        <input type="date" class="form-control" id="Specialist-Consultations-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Specialist-Consultations-Name" class="form-label">Specialist Consultations Name</label>
        <input type="text" class="form-control" id="Specialist-Consultations-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Complications</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Complications-date" class="form-label">Complications Date</label>
        <input type="date" class="form-control" id="Complications-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Complications-Name" class="form-label">Complications Name</label>
        <input type="text" class="form-control" id="Complications-Name" placeholder="2024" />
    </div>
    </div>
    </div>

    <p className="font-size-20 blue-color poppins-font fw-semibold">Current Status</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Current-Status-date" class="form-label">Current Status Date</label>
        <input type="date" class="form-control" id="Current-Status-date" placeholder="2024" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Current-Status-Name" class="form-label">Current Status Name</label>
        <input type="text" class="form-control" id="Current-Status-Name" placeholder="2024" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </>)}


    {dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Illness Name</th>
      <th scope="col">Date Of Diagnosis</th>
      <th scope="col">Severity/Stage</th>
      <th scope="col">Initial Treatment</th>
      <th scope="col">Follow up Adjustments</th>
      <th scope="col">Specialist Consultations</th>
      <th scope="col">Complications</th>
      <th scope="col">Current Status</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.illnessName}</td>
      <td>{moment(d.dateOfDiagnosis).format("MMM DD YYYY")}</td>
      <td>{d.severityStage}</td>
      <td>{d.initialTreatmentName}</td>
      <td>{d.followUpAdjustmentsName}</td>
      <td>{d.specialistConsultationsName}</td>
      <td>{d.complicationsName}</td>
      <td>{d.currentStatusName}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}
</div>
    </>
  )
}

export default ChronicIllnesses