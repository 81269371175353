import { Activity, Pill, Stethoscope, UserRound, Trash } from 'lucide-react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import NoRecordFound from './NoRecordFound';

const InfectiousDisease = ({state,patientId,dataa,handleData,removeData}) => {

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [diseaseName, setdiseaseName] = useState('');
  const [dateDiagnosed, setdateDiagnosed] = useState('');
  const [treatment, settreatment] = useState('');
  const [recovery, setrecovery] = useState('');
  const [additionalNotes, setadditionalNotes] = useState('');

  


  const toggleFormVisibility = () => {
    setIsFormVisible(prevState => !prevState);
  };

  const handleSubmit = async (e) => {

    try {

     e.preventDefault()
  
   const formData = {
        id: uuidv4(),
        diseaseName: diseaseName,
        dateDiagnosed: dateDiagnosed,
        treatment: treatment,
        recovery: recovery,
        additionalNotes: additionalNotes,
        type: state,
      } 
    
    
      
    handleData(formData)

      setIsFormVisible(false)
      setdiseaseName('')
      setdateDiagnosed('')
      settreatment('')
      setrecovery('')
      setadditionalNotes('')
  
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
  }
  };


 
  return (
    <>
    <div className="lh-radius-20 white-bg lh-shadow p-3 mb-3">
    <div style={{ marginBottom: '40px' }}><p className="font-size-20 blue-color">Infectious Disease<button class=" btn lh-btn lh-blue-btn px-3 add-history" onClick={toggleFormVisibility}>{isFormVisible?"Close":"Add"}</button></p>
    </div>
   


    {isFormVisible&&(<><div className="row gy-3 mb-4">
<div className="col-md-4">
    <div class="lh-input2">
        <label for="Disease-Name" class="form-label">Disease Name</label>
        <input onChange={(e)=>setdiseaseName(e.target.value)} value={diseaseName} type="text" class="form-control" id="Disease-Name" placeholder="Disease Name" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Date-Diagnosed" class="form-label">Date Diagnosed</label>
        <input onChange={(e)=>setdateDiagnosed(e.target.value)} value={dateDiagnosed} type="date" class="form-control" id="Date-Diagnosed" placeholder="Date Diagnosed" />
    </div>
    </div>
    <div className="col-md-4">
    <div class="lh-input2">
        <label for="Treatment" class="form-label">Treatment</label>
        <input onChange={(e)=>settreatment(e.target.value)} value={treatment} type="text" class="form-control" id="Treatment" placeholder="Treatment" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Recovery" class="form-label">Recovery</label>
        <input onChange={(e)=>setrecovery(e.target.value)} value={recovery} type="text" class="form-control" id="Recovery" placeholder="Recovery" />
    </div>
    </div>
    <div className="col-md-6">
    <div class="lh-input2">
        <label for="Additional-Notes" class="form-label">Additional Notes</label>
        <input onChange={(e)=>setadditionalNotes(e.target.value)} value={additionalNotes} type="text" class="form-control" id="Additional-Notes" placeholder="Additional Notes" />
    </div>
    </div>
    </div>
    <div className="text-center">
    <button onClick={(e)=>handleSubmit(e)} class="btn lh-btn lh-blue-btn px-4">Save</button>
    </div>
    </>)}

    {dataa&&dataa?.data?.filter(item => item.type == state).length>0&&!isFormVisible?(<div class="table-responsive">
    <table class="table lh-table table-borderless">
  <thead>
    <tr>
      <th scope="col">Disease Name</th>
      <th scope="col">Date Diagnosed</th>
      <th scope="col">Treatment</th>
      <th scope="col">Recovery</th>
      <th scope="col">Additional Notes</th>
      <th style={{width:'100px'}} scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
  {dataa&&dataa?.data?.filter(item => item.type == state)?.map((d,i)=> (
    <tr>
      <td>{d.diseaseName}</td>
      <td>{moment(d.dateDiagnosed).format("MMM DD YYYY")}</td>
      <td>{d.treatment}</td>
      <td>{d.recovery}</td>
      <td>{d.additionalNotes}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            {/* <div className={`lh-action-icons l-blue-bg`}><UserRound width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Activity width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
            <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
            <div onClick={()=>removeData(d.id)} className={`lh-action-icons l-blue-bg`}><Trash width="18" /></div>
        </div>
      </td>
    </tr>
  ))}
  </tbody>
    </table>
    </div>):(!isFormVisible&&<NoRecordFound />)}

</div>
    </>
  )
}

export default InfectiousDisease