import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Activity, Pill, Stethoscope, UserRound, Pencil } from 'lucide-react'
import { useDispatch } from 'react-redux';
import { getAllScreeingPlans } from '../../../store/slices/userSlice';
import moment from 'moment';

const ScreeningPlans = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    const [screeingPlans, setscreeingPlans] = useState([]);
    const [load, setload] = useState(false);



    const listOfScreenings = async () => {
        try {
            setload(true);
            setscreeingPlans(null);


            const response = await dispatch(getAllScreeingPlans()).unwrap();


            setscreeingPlans(response?.data);
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


 
    useEffect(() => {
        let mount = true;
        if (mount) {
            listOfScreenings();
        }
        return () => {
            mount = false;
        };
    }, [dispatch]);


   
    return (
        <>
            <div className="lh-clinic-surgery-list-page px-md-5 pt-3 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb lh-breadcrumb">
                        <li class="breadcrumb-item"><a>Menu</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Screening Plans</li>
                    </ol>
                </nav>
                <div className="d-md-flex align-items-center justify-content-between mb-2">
                    <h2 className="font-size-24 blue-color poppins-font fw-bold">Screening Plans</h2>
                    <button class="btn lh-btn lh-blue-btn px-3" onClick={() => navigate("/add-screening-plan")}>Add Screening Plan</button>
                </div>
                <div className="lh-radius-20 white-bg lh-shadow p-3">
                    <p className="font-size-20 blue-color">Screening Plans</p>
                    <div class="table-responsive">
                        <table class="table lh-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Total Blocks</th>
                                    <th scope="col">Created Date</th>
                                    <th scope="col">Status</th>
                                    <th style={{ width: '100px' }} scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {screeingPlans?.map((list, i) => (
                                    <tr key={i}>
                                        <td>{list?.name}</td>
                                        <td>{list?.screeningBlocks?.length}</td>
                                        <td>{moment(list?.createdAt).format("MMMM Do YYYY")}</td>
                                        <td>{list?.isSave ? "Published" : "Draft"}</td>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className={`lh-action-icons l-blue-bg`} onClick={() => navigate("/add-screening-plan", { state: list?._id })}><Pencil width="18" /></div>
                                                {/* <div className={`lh-action-icons l-blue-bg`} onClick={() => navigate("/screening-detail", { state: list?._id })}><Activity width="18" /></div> */}
                                                {/* <div className={`lh-action-icons l-blue-bg`}><Pill width="18" /></div>
                                                <div className={`lh-action-icons l-blue-bg`}><Stethoscope width="18" /></div> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScreeningPlans