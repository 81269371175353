import { Activity, Calendar, FileText, FlaskConical, HeartPulse, MapPinned, Microscope, NotebookText, Tablets, Thermometer } from 'lucide-react';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment'
import MedicalHistoryTabs from '../MedicalHistoryForms/MedicalHistoryTabs';

const PatientDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState(location?.state?.data);




  return (
    <>
      <div className="lh-patient-list-page px-md-5 pt-3 px-3 pb-5">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb lh-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/patients")}>Patient</a></li>
            <li class="breadcrumb-item active" aria-current="page">Patient Details</li>
          </ol>
        </nav>

        <h2 className="font-size-24 blue-color poppins-font fw-bold">Patient Details</h2>
        <div className="lh-radius-20 white-bg lh-shadow px-4 pt-3 pb-4 mb-4">

          <div className="row gy-4">

            <div className="col-md-3 d-flex gap-4">
              <img className="lh-img-100 rounded-circle mt-2" src={data?.imageName ? data?.imageName : "https://via.placeholder.com/100x100"} />
              <div className="flex-shrink-1 ">
                <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Patient Details</p>
                <p className="font-size-16 blue-color mb-0"><b>Name:</b> {data?.firstName} {data?.lastName}</p>
                <p className="font-size-16 blue-color mb-0"><b>Date Of Birth:</b> {moment(data?.dateOfBirth).format("MMM DD YYYY")}</p>
                <p className="font-size-16 blue-color mb-0"><b>Email:</b> {data?.email}</p>
                <p className="font-size-16 blue-color mb-0"><b>Gender:</b> {data?.gender}</p>
                <p className="font-size-16 blue-color mb-0"><b>Phone:</b> {data?.phone}</p>
              </div>

            </div>
            <div style={{ paddingLeft: "70px" }} className="col-md-3">
              <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Contact Details</p>
              <p className="font-size-16 blue-color mb-0"><b>City:</b> {data?.address.city}</p>
              <p className="font-size-16 blue-color mb-0"><b>Country:</b> {data?.address.country}</p>
              <p className="font-size-16 blue-color mb-0"><b>Address 1:</b> {data?.address.line1}</p>
              <p className="font-size-16 blue-color mb-0"><b>Address 2:</b> {data?.address.line2}</p>
              <p className="font-size-16 blue-color mb-0"><b>Postal Code:</b> {data?.address.postalCode}</p>

            </div>

            <div className="col-md-3">
              <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Insurance Details</p>
              <p className="font-size-16 blue-color mb-0"><b>MR Number:</b> {data?.roleSpecificData?.MRNumber}</p>
              <p className="font-size-16 blue-color mb-0"><b>Name:</b> {data?.roleSpecificData?.insurance.name}</p>
              <p className="font-size-16 blue-color mb-0"><b>Number:</b> {data?.roleSpecificData?.insurance.number}</p>
              <p className="font-size-16 blue-color mb-0"><b>Start Date:</b> {moment(data?.roleSpecificData?.insurance.startDate).format("MMM DD YYYY")}</p>
              <p className="font-size-16 blue-color mb-0"><b>End Date:</b> {moment(data?.roleSpecificData?.insurance.endDate).format("MMM DD YYYY")}</p>


            </div>

            <div className="col-md-3">
              <p className="font-size-22 blue-color poppins-font fw-semibold mb-1">Medical Details</p>

              <p className="font-size-16 blue-color mb-0"><b>Blood Group:</b> {data?.roleSpecificData?.bloodGroup}</p>
              <p className="font-size-16 blue-color mb-0"><b>Smoking Status:</b> {data?.roleSpecificData?.smokingStatus}</p>
              <p className="font-size-16 blue-color mb-0"><b>Alcohol Consumption:</b> {data?.roleSpecificData?.alcoholConsumption}</p>
              <p className="font-size-16 blue-color mb-0"><b>Exercise Habits:</b> {data?.roleSpecificData?.exerciseHabits}</p>
              <p className="font-size-16 blue-color mb-0"><b>Dietary Habits:</b> {data?.roleSpecificData?.dietaryHabits}</p>

            </div>


          </div>
        </div>

        <MedicalHistoryTabs patientId={data?._id} />

      </div>
    </>
  )
}

export default PatientDetail