import React, { useEffect, useState } from 'react';
import ConsentLegalForms from '../PatientModule/ConsentLegalForms';
import FamilyMedicalHistory from '../PatientModule/FamilyMedicalHistory';
import ChronicIllnesses from './ChronicIllnesses';
import Hospitalizations from './Hospitalizations';
import Surgery from './Surgery';
import Medications from './Medications';
import Allergies from './Allergies';
import MenstrualFemale from './MenstrualFemale';
import PregancyFemale from './PregancyFemale';
import InfectiousDisease from './InfectiousDisease';
import ImmunizationRecords from './ImmunizationRecords';
import LabResults from './LabResults';
import AccidentsInjuries from './AccidentsInjuries';
import ScreeningResults from './ScreeningResults';
import {
  getMedicalHistory,addMedicalHistory,getProfile
} from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from 'react-redux';

const MedicalHistoryTabs = ({patientId}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabContents,settabContents]=useState([
  { id: 1, description: 'Consent & Legal Forms', component: ConsentLegalForms },
  { id: 2, description: 'Family Medical History', component: FamilyMedicalHistory },
  { id: 3, description: 'Chronic Illnesses', component: ChronicIllnesses },
  { id: 4, description: 'Hospitalizations', component: Hospitalizations },
  { id: 5, description: 'Surgery', component: Surgery },
  { id: 6, description: 'Medications', component: Medications },
  { id: 7, description: 'Allergies', component: Allergies },
  { id: 8, description: 'Menstrual (Female only)', component: MenstrualFemale },
  { id: 9, description: 'Pregnancy (Female only)', component: PregancyFemale },
  { id: 10, description: 'Infectious Disease', component: InfectiousDisease },
  { id: 11, description: 'Immunization Records', component: ImmunizationRecords },
  { id: 12, description: 'Lab Results', component: LabResults },
  { id: 13, description: 'Accidents Injuries', component: AccidentsInjuries },
  { id: 14, description: 'Screening Results', component: ScreeningResults }
])
  const [data, setdata] = useState([]);
  const [load, setload] = useState(false);
  const [state, setState] = useState('');
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);


  const handleData = async (newObject) => {
    const currentData = data?.data || [];
    const dataToSend={...data,data: [...currentData, newObject]}
    setdata(dataToSend);
    await dispatch(addMedicalHistory({ userId: patientId, reqType: "add", data: JSON.stringify([...currentData, newObject])})).unwrap();
  };

  const removeData = async (idToRemove) => {
    const currentData = data?.data || [];
  
    const updatedData = currentData.filter(obj => obj.id !== idToRemove);
  
    const dataToSend = { ...data, data: updatedData };
  
    setdata(dataToSend);
  
    await dispatch(addMedicalHistory({ userId: patientId, reqType: "delete", data: JSON.stringify(updatedData) })).unwrap();
  };
  

  
  const AllMedicalHistory = async () => {
    try {
      setload(true);
      setdata(null);

      const response = await dispatch(
        getMedicalHistory({id: patientId})
      ).unwrap();

      console.log("response.data", response.data);
      setdata(response?.data);
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      AllMedicalHistory();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);


  

  const handleTabClick = (tab) => {
    setActiveTab(tab.id);
    setState(tab?.description);
  };

  const renderTabContent = () => {
    const activeTabData = tabContents.find(tab => tab.id === activeTab);
  
    if (activeTabData) {
      const Component = activeTabData.component;
      return <Component state={state} patientId={patientId} dataa={data} handleData={handleData} removeData={removeData}/>;
    } else {
      return <div>No tab selected or invalid tab id</div>;
    }
  };

  useEffect(() => {
    // Set the initial state when the component mounts
    const defaultTab = tabContents[0];
    setActiveTab(defaultTab.id);
    setState(defaultTab.description);
  }, []);


  const removeItemsByIds = (idsToRemove) => {
    if (profile && profile.gender === "male") {
        console.log('"yessssss"');
        settabContents(prevMenu =>
            prevMenu.filter(item => !idsToRemove.includes(item.id))
        );
    }
};

useEffect(() => {
    removeItemsByIds([8,9]);
}, [profile]);


  


console.log('datasssssss', data)
  return (
    <>
      <div>
        <h2 className="font-size-24 blue-color poppins-font fw-bold">Additional Info</h2>
        <div className="lh-tabs d-flex flex-wrap gap-2 mb-3">
          {tabContents.slice(0, 2).map((tab) => (
            <div key={tab.id} className={`lh-tab-btn ${activeTab === tab.id ? 'active' : ''}`} onClick={() => handleTabClick(tab)}>
              {tab.description}
            </div>
          ))}
        </div>
        <h2 className="font-size-24 blue-color poppins-font fw-bold">Medical History</h2>
        <div className="lh-tabs d-flex flex-wrap gap-2 mb-3">
          {tabContents.slice(2, 16).map((tab) => (
            <div key={tab.id} className={`lh-tab-btn ${activeTab === tab.id ? 'active' : ''}`} onClick={() => handleTabClick(tab)}>
              {tab.description}
            </div>
          ))}
        </div>

        <div className="lh-tab-content">
          {renderTabContent()}
        </div>
      </div>
    </>
  );
};

export default MedicalHistoryTabs;
